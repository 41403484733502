import React from 'react';
import { useHistory } from 'react-router-dom';
import './AccessCodesPackages.scss';

const AccessCodesPackages = (props) => {

  const packages = [
    {
      id: 1,
      title: 'Octomber 2022 Information Technology with long text included into its title',
      grade: 'A. LEVE',
      year: '202',
      createdBy: 'Hasith Deminda',
      image:
        'https://s3-ap-south-1.amazonaws.com/blogmindler/bloglive/wp-content/uploads/2022/06/08154006/Blog-4-770x385.png',
    },
    {
      id: 2,
      title: 'Octomber 2022 Information Technology',
      grade: 'A. LEVEL',
      year: '2023',
      createdBy: 'Hasith Deminda',
      image:
        'https://s3-ap-south-1.amazonaws.com/blogmindler/bloglive/wp-content/uploads/2022/06/08154006/Blog-4-770x385.png',
    },
    {
      id: 3,
      title: 'Octomber 2022 Information Technology',
      grade: 'A. LEVEL',
      year: '2023',
      createdBy: 'Hasith Deminda',
      image:
        'https://s3-ap-south-1.amazonaws.com/blogmindler/bloglive/wp-content/uploads/2022/06/08154006/Blog-4-770x385.png',
    },
    {
      id: 4,
      title: 'Octomber 2022 Information Technology',
      grade: 'A. LEVEL',
      year: '2023',
      createdBy: 'Hasith Deminda',
      image:
        'https://s3-ap-south-1.amazonaws.com/blogmindler/bloglive/wp-content/uploads/2022/06/08154006/Blog-4-770x385.png',
    },

    {
      id: 5,
      title: 'Octomber 2022 Information Technology',
      grade: 'A. LEVEL',
      year: '2023',
      createdBy: 'Hasith Deminda',
      image:
        'https://s3-ap-south-1.amazonaws.com/blogmindler/bloglive/wp-content/uploads/2022/06/08154006/Blog-4-770x385.png',
    },
    {
      id: 6,
      title: 'Octomber 2022 Information Technology',
      grade: 'A. LEVEL',
      year: '2023',
      createdBy: 'Hasith Deminda',
      image:
        'https://s3-ap-south-1.amazonaws.com/blogmindler/bloglive/wp-content/uploads/2022/06/08154006/Blog-4-770x385.png',
    },
  ];

  const history = useHistory();

  return (
    <div className="accessCodePackages-container">
      {packages.map((packages) => (
        <div
          className="accessCodePackages"
          onClick={() => {
            // history.push(`/settings/bulk/${packages.id}`);
            history.push({
              pathname: `/settings/bulk/${packages.id}`,
              state: {
                grade: packages.grade,
                year: packages.year,
                sub:'test subject',
                package:'test package'
              }

            });
          }}
          key={packages.id}
        >
          <div className="accessCodePackages__image">
            <img
              src="https://s3-ap-south-1.amazonaws.com/blogmindler/bloglive/wp-content/uploads/2022/06/08154006/Blog-4-770x385.png"
              alt=""
            />
          </div>
          <div className="accessCodePackages__grade">{packages.grade}</div>

          <div className="accessCodePackages__content">
            <span className="accessCodePackages__content-title">{packages.title}</span>
            <span className="accessCodePackages__content-createdBy">
              By <span className="ml-1">Hasith Deminda</span>
            </span>

            <span className="accessCodePackages__content-year"> {packages.year} </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AccessCodesPackages;
