import React, { FC, Fragment, useState } from 'react';
import { FaExpandArrowsAlt } from 'react-icons/fa';
import BoxIcon from '../../../../components/BoxIcon';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';
import './ContentTopicListItem.scss';
import { useHistory } from 'react-router-dom';
import RoutingPaths from '../../routes/RoutingPaths';
import { useAppContent } from '../../context/ContentContext';
import AlertPopup from '../../../../components/AlertPopup';
import { useFunctions } from 'reactfire';
import AdminAndInstructorContentServices from '../../services/AdminAndInstructorContentServices';

interface Props {
  text: string;
  subjectId: string;
  lessonId: string;
  droppableId: string;
  contentId: string;
  unitId?: string;
  id?: string;
  className?: string;
}

const ContentTopicListItem: FC<Props> = (props) => {
  // console.log("TEXT ", props.text)

  const appContent = useAppContent();
  const history = useHistory();
  const [deleteModel, setDeleteModel] = useState('');

  const createContentRef = useFunctions().httpsCallable('createContent');

  const onFinishHander = () => {
    appContent.setLoading(false);
    // history.push(new RoutingPaths(props.subjectId, props.lessonId, props.unitId).baseURL);
  };

  const onProgressHandler = () => {
    appContent.setLoading(true);
  };

  return (
    <Fragment>
      <div
        className={`content-topic-list-item ${props.className}`}
        onClick={() => {
          appContent.setDroppableId(props.droppableId);
          // history.push(
          //   new RoutingPaths(
          //     props.subjectId,
          //     props.lessonId,
          //     props.unitId,
          //     props.droppableId
          //   ).updateContent()
          // );
        }}
      >
        <div className="content-topic-list-item__title">
          <FaExpandArrowsAlt className="content-topic-list-item__icon" />
          <h6 className="mb-0">{props.text}</h6>
        </div>
        <div className="content-topic-list-item__actions">
          <BoxIcon
            icon={FaEdit}
            color="#fff"
            style={{ width: 30, height: 30, backgroundColor: '#404462', marginLeft: '12%' }}
            size={13}
            onClick={() => {
              appContent.setDroppableId(props.droppableId);
              history.push(
                new RoutingPaths(
                  props.subjectId,
                  props.lessonId,
                  props.unitId,
                  props.droppableId
                ).updateContent()
              );
            }}
          />
          <BoxIcon
            icon={FaTrashAlt}
            color="#fff"
            size={13}
            style={{ width: 30, height: 30, backgroundColor: '#F05A5A', marginLeft: '12%' }}
            onClick={() => {
              setDeleteModel('Are you sure you want to delete this chapter?');
            }}
          />
        </div>
      </div>
      <AlertPopup
        message={deleteModel}
        header="Attention"
        isShow={!!deleteModel}
        onOk={() => {
          if (appContent.contentData.length === 1) {
            const data = {
              content: {
                contentData: [],
                subjectId: props.subjectId,
                lessonId: props.lessonId,
                unitId: props.unitId || 'noUnit',
              },
              id: props.contentId,
            };
            new AdminAndInstructorContentServices(createContentRef).createContent(
              data,
              onProgressHandler,
              onFinishHander
            );
          }
          appContent.removeChapter(props.droppableId);
          setDeleteModel('');
        }}
        onClose={() => {
          setDeleteModel('');
        }}
      />
    </Fragment>
  );
};

export default ContentTopicListItem;
