import { useEffect, useState } from 'react';
import { BiMove } from 'react-icons/bi';
import { RiDeleteBinFill } from 'react-icons/ri';
import { FileIcon } from '../../../components/CreateNotice';
import randomString from 'randomstring';
import { FaPlus } from 'react-icons/fa';
import './MaterialContentCopy.scss';
import { useStorage } from 'reactfire';
import firebase from 'firebase/app';
import Notification from '../../../components/Notification';
import CustomProgressBar from '../../../components/CustomProgressBar';
import { ContentItemLink } from '../../../screens/admin/AdminUnitScreenContent';
import { CONTENT_MEDIA_TYPE } from '../utils';
import { Item } from '../interfaces/LocalContentState';
import { useAppContent } from '../context/ContentContext';
import truncate from 'truncate';

interface IProps {
  contentItemDroppableId: string;
  droppableId: string;
}

interface SelectedFile {
  id: string;
  file?: File;
  url: string;
  old?: boolean;
}

const MaterialContentCopy = (props: IProps) => {
  const appContent = useAppContent();

  const storage = useStorage().ref();
  const [uploadElement, setUploadElement] = useState(null as any);
  const [selectedFiles, setSelectedFiles] = useState<SelectedFile[]>([]);
  const state = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const isValid = true;

  const contentItems = appContent.contentData.find(
    (val) => val.droppableId === props.droppableId
  )?.contentItems;

  const materialContent = contentItems?.find(
    (val) => val.contentItemDroppableId === props.contentItemDroppableId
  ) as {
    materialUrls: Item[];
    mediaType: CONTENT_MEDIA_TYPE;
    contentItemDroppableId: string;
    component: any;
    error?: string;
  };

  const uploadFiles = () => {
    if (selectedFiles.length > 0) {
      const downloadUrlTasks: { file: File; task: Promise<string> }[] = [];
      const tasks = selectedFiles.map((f) => {
        if (f.file) {
          const task = storage.child(`unitresources/mns${f.file?.name}mne`).put(f.file);
          task.on(
            firebase.storage.TaskEvent.STATE_CHANGED,
            (snapshot: any) => {
              const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
              if (snapshot.state === firebase.storage.TaskState.RUNNING) {
                console.log(`Progress: ${progress}% - ${f.file?.name}`);
                setUploadProgress(progress);
              }
            },
            (error: any) => console.log(error.message),
            async () => {
              f.file &&
                downloadUrlTasks.push({
                  file: f.file,
                  task: task.snapshot.ref.getDownloadURL(),
                });
            }
          );

          return task;
        }
        return null;
      });

      const links: ContentItemLink[] = [];
      Promise.all(tasks)
        .then(async () => {
          for (let utask of downloadUrlTasks) {
            const downloadURL = await utask.task;
            console.log(downloadURL);
            links.push({
              value: downloadURL,
              text: utask.file?.name,
              id: randomString.generate(),
            });
            setSelectedFiles([]);
          }

          const linkState = links.map((v) => ({ value: v.value, error: '' }));

          // ON UDPATE
          // if (props.onUpdateItem) {

          // }

          appContent.extraMaterialHandler(
            props.droppableId,
            props.contentItemDroppableId,
            linkState[0]
          );
          setUploadElement(null);
          Notification({
            isSuccess: true,
            message: 'Files Successfully Saved!',
          });
        })
        .catch((err) => {
          // UserNotification({
          //   isSuccess: false,
          //   message: "Notice Failed to Create!",
          // });
        })
        .finally(() => {
          state[1](false);
          setUploadProgress(0);
        });
    }
  };

  useEffect(() => {
    if (selectedFiles.length > 0) {
      uploadFiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFiles]);

  return (
    <div className="content-card-container mt-4">
      <div className="c-icon">
        <BiMove style={{ cursor: 'move' }} />
      </div>
      <div className="c-right">
        <div className="c-title-contaner">
          <div className="c-title">Extra Materials</div>
          <div
            className="c-delete"
            onClick={() => {
              appContent.removeNewExtraMaterialsSlotHandler(
                props.droppableId,
                props.contentItemDroppableId
              );
            }}
          >
            <RiDeleteBinFill />
          </div>
        </div>
        <div className="c-materials">
          <div className="file-list">
            {materialContent.materialUrls.map((m, index) => {
              //trucate the url
              // const text = m?.value.substring(m?.value.indexOf('mns') + 3, m.value.indexOf('mne'));
              const text = truncate(
                m?.value.substring(m?.value.indexOf('mns') + 3, m.value.indexOf('mne')),
                15
              );

              return (
                <FileIcon
                  file={{ id: index + '', fileName: text, url: m.value }}
                  key={index}
                  onClose={() => {
                    appContent.removeExtraMaterialItem(
                      props.droppableId,
                      props.contentItemDroppableId,
                      index
                    );
                  }}
                />
              );
            })}
            {selectedFiles.map((f, index) => (
              <FileIcon
                file={f}
                key={index}
                onClose={() => {
                  setSelectedFiles((ps) => {
                    return Object.assign(
                      [],
                      ps.filter((f2) => f2.id !== f.id)
                    );
                  });
                }}
              />
            ))}
            <div
              className="add-new-m"
              onClick={() => {
                uploadElement.click();
                appContent.resetMaterialHandler(props.droppableId, props.contentItemDroppableId);
              }}
            >
              <div className="an-icon">
                <FaPlus />
              </div>
              <div className="an-text" style={{ fontSize: '14px' }}>
                Add <br />
                Materials
              </div>
            </div>
            {/* {selectedFiles && selectedFiles.length > 0 && (
              <div className="btn-upload">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    uploadFiles();
                  }}
                >
                  Upload
                </button>
              </div>
            )} */}
            <input
              type="file"
              ref={(input) => setUploadElement(input)}
              accept=".xls, .pptx, .ppt, .png, .pdf, .jpg, .jpeg, .gif, .docx, .doc, .xlsx, .txt, .md"
              className="d-none"
              onChange={(e) => {
                const file: any = e.target.files ? e.target.files[0] : null;
                const fileType = file?.name.split('.').pop().toLowerCase();
                const exList = 'xls,pptx,ppt,png,pdf,jpg,jpeg,gif,docx,doc,xlsx,txt,md'.split(',');

                let b1 = file && exList.filter((ext) => ext === fileType).length > 0;
                let b2 = file && file?.size <= 105255200;
                let errorMessage = 'No file was selected';
                if (b1) errorMessage = 'Invalid file format';
                if (b2) errorMessage = ' Invalid file size';
                if (b1 && b2) {
                  if (file) {
                    setSelectedFiles((ps) => {
                      ps.push({
                        file: file,
                        id: randomString.generate(),
                        url: '',
                      });
                      return Object.assign([], ps);
                    });
                  }
                } else {
                  console.log(errorMessage);
                  // UserNotification({
                  //   isSuccess: false,
                  //   message: errorMessage,
                  // });
                }
              }}
            />
          </div>
          {uploadProgress > 0 && (
            <div style={{ marginTop: '10px', width: '100%' }} className="d-flex align-items-center">
              <div className="mr-2" style={{ color: 'rgb(32, 209, 103)' }}>
                {uploadProgress + '%'}
              </div>
              <CustomProgressBar progress={uploadProgress} width={100} color="green" />
            </div>
          )}
          <div>{!isValid && <label className="error-message">invalid data</label>}</div>
          {materialContent.error ? (
            <div className="text-right pr-2 mt-1" style={{ fontSize: 11, color: '#dc3545' }}>
              {materialContent.error}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default MaterialContentCopy;
