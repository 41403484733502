import ExcelJS from 'exceljs';
import moment from 'moment';
import { PaperTypes } from '../enums/paperTypes';
import { Exam } from '../models/Exam';

// First report
export interface ExamWiseReport {
  lectureName: string;
  subject: string;
  grade: string;
  year: string;
  ExamName: string;
  pepersIncluded: string[];
  examDate: string;
  data: ExamWiseReportData[];
  ismcq: boolean;
  isesy: boolean;
  isstr: boolean;
  istf: boolean;
  mcqTitle: string;
  esyTitle: string;
  strTitle: string;
  tfTitle: string;
  selectedExam: Exam | null;
}

export interface ExamWiseReportData {
  studentId: string;
  studentName: string;
  mcq: string;
  esy: string;
  str: string;
  tf: string;
  rank: string;
  totalMarks: string;
  sudentContact: string;
}
// End First Report

// Lecture Report
export interface LectureReport {
  assistantName: string;
  subject: string;
  gradeAndYear: string;
  timeFrame: string;
  data: LectureReportData[];
}

export interface LectureReportData {
  date: string;
  examName: string;
  examType: string;
  papers: string;
  price: string;
  noOfStudents: string;
}
// End Lecture Report

// Papper Marking Report

export interface PapperMarkingReport {
  assistantName: string;
  subject: string;
  gradeAndYear: string;
  timeFrame: string;
  data: PapperMarkingReportData[];
}

export interface PapperMarkingReportData {
  date: string;
  asignedExam: string;
  examType: string;
  papers: string;
  noOfStudents: string;
  approvedPappers: string;
  totalPayments: string;
}

// End Papper Marking Report

export default class ExcelHelper {
  static getLater(n: number) {
    switch (n) {
      case 1:
        return 'G';
      case 2:
        return 'H';
      case 3:
        return 'I';
      default:
        return 'J';
    }
  }

  static getColor(marks: string) {
    const x = +marks.replace('%', '');
    console.log('marks:', marks, x);
    if (x >= 75) {
      return '75E075';
    } else if (x >= 50) {
      return 'FFFF7D';
    } else if (x >= 25) {
      return 'FFC56F';
    } else {
      return 'FF7676';
    }
  }

  public static async getReport(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    format: 'xlsx' | 'csv',
    data?: ExamWiseReport
  ) {
    e.preventDefault();
    let papperCount = 0;
    const letterList = 'ABCDEFGHIJ';
    const workbook = new ExcelJS.Workbook();
    workbook.addWorksheet('sheet1');
    const worksheet = workbook.getWorksheet('sheet1');
    const emptyRow = {
      studentId: '',
      studentName: '',
      mcq: '',
      esy: '',
      str: '',
      tf: '',
      rank: '',
      totalMarks: '',
      sudentContact: '',
    };

    const columns = [
      { key: 'studentId', width: 35 },
      { key: 'studentName', width: 40 },
    ];

    let examTypesOrder = data?.selectedExam?.papers.map((paper) => {
      return paper.paperType;
    });

    examTypesOrder?.forEach((exmType) => {
      if (exmType === PaperTypes.MCQ && data?.ismcq) {
        columns.push({ key: 'mcq', width: 30 });
        papperCount++;
      }
      if (exmType === PaperTypes.TRUEFALSE && data?.istf) {
        columns.push({ key: 'tf', width: 30 });
        papperCount++;
      }
      if (exmType === PaperTypes.STRUCTURED && data?.isstr) {
        columns.push({ key: 'str', width: 30 });
        papperCount++;
      }

      if (exmType === PaperTypes.ESSAY && data?.isesy) {
        columns.push({ key: 'esy', width: 30 });
        papperCount++;
      }
    });

    columns.push(
      { key: 'totalMarks', width: 20 },
      { key: 'rank', width: 20 },
      { key: 'sudentContact', width: 40 }
    );

    worksheet.columns = columns;
    const [mcqTitle, mcqMarks] = data?.mcqTitle?.split('&') || [];
    const [esyTitle, esyMarks] = data?.esyTitle?.split('&') || [];
    const [srtTitle, strMarks] = data?.strTitle?.split('&') || [];
    const [tfTitle, tfMarks] = data?.tfTitle?.split('&') || [];
    worksheet.addRows([
      emptyRow,
      emptyRow,
      emptyRow,
      emptyRow,
      {
        studentId: 'Student ID',
        studentName: 'Student Name',
        mcq: {
          richText: [
            { text: mcqTitle },
            { font: { italic: true, size: 10 }, text: mcqMarks === 'false' ? '' : '\n' + mcqMarks },
          ],
        },
        esy: {
          richText: [
            { text: esyTitle },
            { font: { italic: true, size: 10 }, text: esyMarks === 'false' ? '' : '\n' + esyMarks },
          ],
        },
        str: {
          richText: [
            { text: srtTitle },
            { font: { italic: true, size: 10 }, text: strMarks === 'false' ? '' : '\n' + strMarks },
          ],
        },
        tf: {
          richText: [
            { text: tfTitle },
            { font: { italic: true, size: 10 }, text: tfMarks === 'false' ? '' : '\n' + tfMarks },
          ],
        },
        rank: 'Rank',
        totalMarks: 'Total Marks',
        sudentContact: 'Student Contact Number',
      },
    ]);

    data?.data.forEach((data, index) => {
      worksheet.addRow({
        studentId: data.studentId,
        studentName: data.studentName,
        mcq: data.mcq,
        esy: data.esy,
        str: data.str,
        tf: data.tf,
        rank: data.rank,
        totalMarks: data.totalMarks,
        sudentContact: data.sudentContact,
      });
      letterList
        .substring(0, 'ABCDEFGHIJ'.indexOf(this.getLater(papperCount)))
        .split('')
        .forEach((x) => {
          worksheet.getCell(`${x}${6 + index}`).style = {
            border: {
              bottom: { style: 'hair' },
              left: { style: 'hair' },
              right: { style: 'hair' },
              top: { style: 'hair' },
            },
          };
        });
      worksheet.getCell(`${letterList.at(2 + papperCount)}${6 + index}`).style = {
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: this.getColor(data.totalMarks) },
        },
        border: {
          bottom: { style: 'hair' },
          left: { style: 'hair' },
          right: { style: 'hair' },
          top: { style: 'hair' },
        },
      };
    });

    worksheet.getCell('A1').value = `${data?.ExamName} : Grade Sheet`;
    worksheet.mergeCells(`A1:${letterList.at(4 + papperCount)}1`);
    worksheet.getRow(1).height = 30;
    const cell = worksheet.getCell('A1');
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { bold: true, size: 12 };
    worksheet.mergeCells('A2:B2');
    worksheet.mergeCells(`C2:${letterList.at(1 + papperCount)}2`);
    worksheet.mergeCells(`${letterList.at(2 + papperCount)}2:${letterList.at(3 + papperCount)}2`);

    worksheet.mergeCells('A3:B3');
    worksheet.mergeCells(`C3:${letterList.at(1 + papperCount)}3`);
    worksheet.mergeCells(`${letterList.at(2 + papperCount)}3:${letterList.at(3 + papperCount)}3`);

    worksheet.getCell('A3').font = { bold: true };
    worksheet.getCell('C3').font = { bold: true };
    worksheet.getCell(`${letterList.at(2 + papperCount)}3`).font = { bold: true };
    worksheet.getCell(`${letterList.at(4 + papperCount)}3`).font = { bold: true };

    worksheet.getCell('A2').value = 'Exam created by';
    worksheet.getCell('C2').value = 'Subject';
    worksheet.getCell(`${letterList.at(2 + papperCount)}2`).value = 'Grade & Year';
    worksheet.getCell(`${letterList.at(4 + papperCount)}2`).value = 'Exam Date & Time ';

    worksheet.getCell('A3').value = data?.lectureName;
    worksheet.getCell('C3').value = data?.subject;
    worksheet.getCell(`${letterList.at(2 + papperCount)}3`).value =
      data?.grade + (data?.year ? `(${data?.year})` : '');
    worksheet.getCell(`${letterList.at(4 + papperCount)}3`).value = data?.examDate;

    worksheet.getCell('A2').border = {
      top: { style: 'hair' },
      right: { style: 'hair' },
      left: { style: 'hair' },
    };
    worksheet.getCell('C2').border = {
      top: { style: 'hair' },
      right: { style: 'hair' },
      left: { style: 'hair' },
    };
    worksheet.getCell(`${letterList.at(2 + papperCount)}2`).border = {
      top: { style: 'hair' },
      right: { style: 'hair' },
      left: { style: 'hair' },
    };
    worksheet.getCell(`${letterList.at(4 + papperCount)}2`).border = {
      top: { style: 'hair' },
      right: { style: 'hair' },
      left: { style: 'hair' },
    };

    worksheet.getCell('A3').border = {
      bottom: { style: 'hair' },
      right: { style: 'hair' },
      left: { style: 'hair' },
    };
    worksheet.getCell('C3').border = {
      bottom: { style: 'hair' },
      right: { style: 'hair' },
      left: { style: 'hair' },
    };
    worksheet.getCell(`${letterList.at(2 + papperCount)}3`).border = {
      bottom: { style: 'hair' },
      right: { style: 'hair' },
      left: { style: 'hair' },
    };
    worksheet.getCell(`${letterList.at(4 + papperCount)}3`).border = {
      bottom: { style: 'hair' },
      right: { style: 'hair' },
      left: { style: 'hair' },
    };
    worksheet.getRow(5).height = 40;
    letterList
      .substring(0, letterList.indexOf(this.getLater(papperCount)))
      .split('')
      .forEach((x) => {
        worksheet.getCell(`${x}5`).style = {
          fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'F5F5F5' },
          },
          border: {
            bottom: { style: 'hair' },
            left: { style: 'hair' },
            right: { style: 'hair' },
            top: { style: 'hair' },
          },
        };
      });

    worksheet.getRow(5).alignment = { wrapText: true, vertical: 'middle' };

    worksheet.mergeCells(
      `A${10 + (data?.data?.length || 0)}:${this.getLater(papperCount)}${
        10 + (data?.data?.length || 0)
      }`
    );
    worksheet.mergeCells(
      `A${11 + (data?.data?.length || 0)}:${this.getLater(papperCount)}${
        11 + (data?.data?.length || 0)
      }`
    );
    const timeCell = worksheet.getRow(10 + (data?.data?.length || 0)).getCell(1);
    timeCell.value = moment(new Date()).format('MMMM/Do/YYYY h:mm:ss a');
    timeCell.alignment = { horizontal: 'center', vertical: 'middle' };
    const logoCell = worksheet.getRow(11 + (data?.data?.length || 0)).getCell(1);
    logoCell.value = 'Scrapbook';
    // worksheet.addImage(imageId1, {
    //   tl: {col: 3, row: 10 + (data?.data?.length || 0)},
    //   ext: {width: 80, height: 20},
    //   editAs: 'absolute',
    // });

    logoCell.alignment = { horizontal: 'center', vertical: 'middle' };
    logoCell.font = { bold: true };

    const uint8Array =
      format === 'xlsx' ? await workbook.xlsx.writeBuffer() : await workbook.csv.writeBuffer();
    const blob = new Blob([uint8Array], { type: 'application/octet-binary' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = (data?.ExamName || 'report') + '.' + format;
    a.click();
    a.remove();
  }

  public static async getLectureReport(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    format: 'xlsx' | 'csv',
    title: 'All Data Report' | 'Monthly Report' | 'Custom Data Report',
    data?: LectureReport
  ) {
    e.preventDefault();
    const workbook = new ExcelJS.Workbook();
    const LETTERS = 'ABCDEF';
    workbook.addWorksheet('sheet1');
    const worksheet = workbook.getWorksheet('sheet1');
    const emptyRow = {
      date: '',
      examName: '',
      examType: '',
      pappers: '',
      price: '',
      noOfStudents: '',
    };

    const columns = [
      { key: 'date', width: 30 },
      { key: 'examName', width: 20 },
      { key: 'examType', width: 20 },
      { key: 'pappers', width: 40 },
      { key: 'price', width: 20 },
      { key: 'noOfStudents', width: 20 },
    ];
    worksheet.columns = columns;
    worksheet.addRows([
      emptyRow,
      emptyRow,
      emptyRow,
      emptyRow,
      {
        date: 'Date',
        examName: 'Exam Name',
        examType: 'Exam type',
        pappers: 'Papers',
        price: 'Price',
        noOfStudents: 'No. of Students Submitted',
      },
    ]);

    worksheet.getCell('A1').value = title;
    worksheet.mergeCells(`A1:F1`);
    const cell = worksheet.getCell('A1');
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { bold: true, size: 12 };

    worksheet.getCell('A2').value = 'Instructor Name';
    worksheet.getCell('A2').style = {
      border: {
        left: { style: 'hair' },
        right: { style: 'hair' },
        top: { style: 'hair' },
      },
    };

    worksheet.getCell('B2').value = 'Subject';
    worksheet.getCell('B2').style = {
      border: {
        left: { style: 'hair' },
        right: { style: 'hair' },
        top: { style: 'hair' },
      },
    };
    worksheet.mergeCells('B2:C2');

    worksheet.getCell('D2').value = 'Grade & Year';
    worksheet.getCell('D2').style = {
      border: {
        left: { style: 'hair' },
        right: { style: 'hair' },
        top: { style: 'hair' },
      },
    };

    worksheet.getCell('E2').value = 'Timeframe';
    worksheet.getCell('E2').style = {
      border: {
        left: { style: 'hair' },
        right: { style: 'hair' },
        top: { style: 'hair' },
      },
    };
    worksheet.mergeCells('E2:F2');

    worksheet.getCell('A3').value = data?.assistantName;
    worksheet.getCell('A3').style = {
      border: {
        bottom: { style: 'hair' },
        left: { style: 'hair' },
        right: { style: 'hair' },
      },
    };
    worksheet.getCell('A3').font = { bold: true, size: 12 };

    worksheet.getCell('B3').value = data?.subject;
    worksheet.getCell('B3').style = {
      border: {
        bottom: { style: 'hair' },
        left: { style: 'hair' },
        right: { style: 'hair' },
      },
    };
    worksheet.getCell('B3').font = { bold: true, size: 12 };
    worksheet.mergeCells('B3:C3');

    worksheet.getCell('D3').value = data?.gradeAndYear;
    worksheet.getCell('D3').style = {
      border: {
        bottom: { style: 'hair' },
        left: { style: 'hair' },
        right: { style: 'hair' },
      },
    };
    worksheet.getCell('D3').font = { bold: true, size: 12 };

    worksheet.getCell('E3').value = data?.timeFrame;
    worksheet.getCell('E3').style = {
      border: {
        bottom: { style: 'hair' },
        left: { style: 'hair' },
        right: { style: 'hair' },
      },
    };
    worksheet.getCell('E3').font = { bold: true, size: 12 };
    worksheet.mergeCells('E3:F3');

    worksheet.getRow(5).height = 40;
    for (let i = 0; i < 6; i++) {
      const headerCell = worksheet.getCell(`${LETTERS.charAt(i)}5`);
      headerCell.style = {
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'F5F5F5' },
        },
        border: {
          bottom: { style: 'hair' },
          left: { style: 'hair' },
          right: { style: 'hair' },
          top: { style: 'hair' },
        },
      };
      headerCell.alignment = { vertical: 'middle', wrapText: true };
    }

    //add data
    data?.data.forEach((d) => {
      worksheet.addRow({
        date: d.date,
        examName: d.examName,
        examType: d.examType,
        pappers: d.papers,
        price: d.price,
        noOfStudents: d.noOfStudents,
      });
    });
    //end add data
    for (let i = 1; i <= (data?.data.length || 0); i++) {
      for (let j = 0; j < LETTERS.length; j++) {
        worksheet.getCell(`${LETTERS.charAt(j)}${5 + i}`).style = {
          border: {
            bottom: { style: 'hair' },
            left: { style: 'hair' },
            right: { style: 'hair' },
            top: { style: 'hair' },
          },
        };
      }
    }

    const timeCell = worksheet.getRow(10 + (data?.data?.length || 0)).getCell(1);
    worksheet.mergeCells(`A${10 + (data?.data?.length || 0)}:F${10 + (data?.data?.length || 0)}`);
    worksheet.mergeCells(`A${11 + (data?.data?.length || 0)}:F${11 + (data?.data?.length || 0)}`);
    timeCell.value = moment(new Date()).format('MMMM/Do/YYYY h:mm:ss a');
    timeCell.alignment = { horizontal: 'center', vertical: 'middle' };
    const logoCell = worksheet.getRow(11 + (data?.data?.length || 0)).getCell(1);
    logoCell.value = 'Scrapbook';
    // worksheet.addImage(imageId1, {
    //   tl: {col: 3, row: 10 + (data?.data?.length || 0)},
    //   ext: {width: 80, height: 20},
    //   editAs: 'absolute',
    // });

    logoCell.alignment = { horizontal: 'center', vertical: 'middle' };
    logoCell.font = { bold: true };

    const uint8Array =
      format === 'xlsx' ? await workbook.xlsx.writeBuffer() : await workbook.csv.writeBuffer();
    const blob = new Blob([uint8Array], { type: 'application/octet-binary' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'lecture_report.' + new Date().toString() + '.' + format;
    a.click();
    a.remove();
  }

  public static async getPaperMarkingReport(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    format: 'xlsx' | 'csv',
    title: 'All Data Report' | 'Monthly Report' | 'Custom Data Report',
    data?: PapperMarkingReport
  ) {
    e.preventDefault();
    const workbook = new ExcelJS.Workbook();
    const LETTERS = 'ABCDEFG';
    workbook.addWorksheet('sheet1');
    const worksheet = workbook.getWorksheet('sheet1');
    const emptyRow = {
      date: '',
      examName: '',
      examType: '',
      pappers: '',
      price: '',
      noOfStudents: '',
    };

    const columns = [
      { key: 'date', width: 30 },
      { key: 'asignedExam', width: 20 },
      { key: 'examType', width: 20 },
      { key: 'pappers', width: 40 },
      { key: 'noOfStudents', width: 20 },
      { key: 'approvedPappers', width: 20 },
      { key: 'totalPayments', width: 20 },
    ];
    worksheet.columns = columns;
    worksheet.addRows([
      emptyRow,
      emptyRow,
      emptyRow,
      emptyRow,
      {
        date: 'Date',
        asignedExam: 'Assigned Exam',
        examType: 'Exam type',
        pappers: 'Assigned Papers',
        noOfStudents: `No of Students' papers Assigned`,
        approvedPappers: 'Approved Papers',
        totalPayments: 'Total Payment',
      },
    ]);

    worksheet.getCell('A1').value = title;
    worksheet.mergeCells(`A1:G1`);
    const cell = worksheet.getCell('A1');
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { bold: true, size: 12 };

    worksheet.getCell('A2').value = 'Teaching Assistant Name';
    worksheet.getCell('A2').style = {
      border: {
        left: { style: 'hair' },
        right: { style: 'hair' },
        top: { style: 'hair' },
      },
    };

    worksheet.getCell('B2').value = 'Subject';
    worksheet.getCell('B2').style = {
      border: {
        left: { style: 'hair' },
        right: { style: 'hair' },
        top: { style: 'hair' },
      },
    };
    worksheet.mergeCells('B2:C2');

    worksheet.getCell('D2').value = 'Grade & Year';
    worksheet.getCell('D2').style = {
      border: {
        left: { style: 'hair' },
        right: { style: 'hair' },
        top: { style: 'hair' },
      },
    };
    worksheet.mergeCells('D2:E2');

    worksheet.getCell('F2').value = 'Timeframe';
    worksheet.getCell('F2').style = {
      border: {
        left: { style: 'hair' },
        right: { style: 'hair' },
        top: { style: 'hair' },
      },
    };
    worksheet.mergeCells('F2:G2');

    worksheet.getCell('A3').value = data?.assistantName;
    worksheet.getCell('A3').style = {
      border: {
        bottom: { style: 'hair' },
        left: { style: 'hair' },
        right: { style: 'hair' },
      },
    };
    worksheet.getCell('A3').font = { bold: true, size: 12 };

    worksheet.getCell('B3').value = data?.subject;
    worksheet.getCell('B3').style = {
      border: {
        bottom: { style: 'hair' },
        left: { style: 'hair' },
        right: { style: 'hair' },
      },
    };
    worksheet.getCell('B3').font = { bold: true, size: 12 };
    worksheet.mergeCells('B3:C3');

    worksheet.getCell('D3').value = data?.gradeAndYear;
    worksheet.getCell('D3').style = {
      border: {
        bottom: { style: 'hair' },
        left: { style: 'hair' },
        right: { style: 'hair' },
      },
    };
    worksheet.getCell('D3').font = { bold: true, size: 12 };
    worksheet.mergeCells('D3:E3');

    worksheet.getCell('F3').value = data?.timeFrame;
    worksheet.getCell('F3').style = {
      border: {
        bottom: { style: 'hair' },
        left: { style: 'hair' },
        right: { style: 'hair' },
      },
    };
    worksheet.getCell('F3').font = { bold: true, size: 12 };
    worksheet.mergeCells('F3:G3');

    worksheet.getRow(5).height = 40;
    for (let i = 0; i < 7; i++) {
      const headerCell = worksheet.getCell(`${LETTERS.charAt(i)}5`);
      headerCell.style = {
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'F5F5F5' },
        },
        border: {
          bottom: { style: 'hair' },
          left: { style: 'hair' },
          right: { style: 'hair' },
          top: { style: 'hair' },
        },
      };
      headerCell.alignment = { vertical: 'middle', wrapText: true };
    }

    //add data
    data?.data.forEach((d) => {
      worksheet.addRow({
        date: d.date,
        asignedExam: d.asignedExam,
        examType: d.examType,
        pappers: d.papers,
        noOfStudents: d.noOfStudents,
        approvedPappers: d.approvedPappers,
        totalPayments: `Rs. ${d.totalPayments}`,
      });
    });
    //end add data
    for (let i = 1; i <= (data?.data.length || 0); i++) {
      for (let j = 0; j < LETTERS.length; j++) {
        worksheet.getCell(`${LETTERS.charAt(j)}${5 + i}`).style = {
          border: {
            bottom: { style: 'hair' },
            left: { style: 'hair' },
            right: { style: 'hair' },
            top: { style: 'hair' },
          },
        };
      }
    }

    const timeCell = worksheet.getRow(10 + (data?.data?.length || 0)).getCell(1);
    worksheet.mergeCells(`A${10 + (data?.data?.length || 0)}:G${10 + (data?.data?.length || 0)}`);
    worksheet.mergeCells(`A${11 + (data?.data?.length || 0)}:G${11 + (data?.data?.length || 0)}`);
    timeCell.value = moment(new Date()).format('MMMM/Do/YYYY h:mm:ss a');
    timeCell.alignment = { horizontal: 'center', vertical: 'middle' };
    const logoCell = worksheet.getRow(11 + (data?.data?.length || 0)).getCell(1);
    logoCell.value = 'Scrapbook';
    // worksheet.addImage(imageId1, {
    //   tl: {col: 3, row: 10 + (data?.data?.length || 0)},
    //   ext: {width: 80, height: 20},
    //   editAs: 'absolute',
    // });

    logoCell.alignment = { horizontal: 'center', vertical: 'middle' };
    logoCell.font = { bold: true };

    const uint8Array =
      format === 'xlsx' ? await workbook.xlsx.writeBuffer() : await workbook.csv.writeBuffer();
    const blob = new Blob([uint8Array], { type: 'application/octet-binary' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'paper-marking-report.' + new Date().toString() + '.' + format;
    a.click();
    a.remove();
  }
}
