import React, { FC, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { FaFilePdf, FaLink } from 'react-icons/fa';
import renderHTML from 'react-render-html';
import { useParams } from 'react-router-dom';
import { useFirestore, useFunctions } from 'reactfire';
import {
  Player,
  ControlBar,
  PlayToggle,
  BigPlayButton,
  VolumeMenuButton,
  ForwardControl,
  ReplayControl,
  LoadingSpinner,
} from 'video-react';
import InitialView from '../../../components/InitialView';
import { useAppUser } from '../../../context/UserContext';
import { getStudentContentCompletedPercentage, isAllRead } from '../helpers';
import { useAppContent } from '../context/ContentContext';
import { Item, Links, LocalContentState } from '../interfaces/LocalContentState';
import TextContext from '../screen/adminAndInstructor/TextContext';
import { CONTENT_MEDIA_TYPE } from '../utils';
import LinksContent from './LinksContent';
import MaterialContentCopy from './MaterialContentCopy';
import './StudentContentBody.scss';
import VideoContent from './VideoContent';
import truncate from 'truncate';

const StudentContentBody: FC = () => {
  const appContent = useAppContent();

  const CONTENTS = appContent.contentData.find(
    (data) => data.droppableId === appContent.mainDroppableId
  )?.contentItems;

  const appUser = useAppUser();

  const saveStudentContentProgressRef = useFunctions().httpsCallable('saveStudentContentProgress');

  const getMaterialNameFromUrl = (url: string) => {
    return truncate(url.substring(url.indexOf('mns') + 3, url.indexOf('mne')), 20);
  };

  const isAtLastChapter = () => {
    const idxOfCurrent = appContent.contentData
      .map((v) => v.droppableId)
      .indexOf(appContent.mainDroppableId);
    const maxIndex = appContent.contentData.length - 1;
    return maxIndex === idxOfCurrent;
  };

  const isAbleToComplete = () => {
    return isAtLastChapter()
      ? isAllRead(appContent.contentData, appUser.firestoreUser?.uid || '')
      : true;
  };

  const getCurrentChapter = () => {
    return appContent.contentData.find((data) => data.droppableId === appContent.mainDroppableId);
  };

  const isAppUserPresentInCurrentChapter = () => {
    return getCurrentChapter()?.chapterReadCheckUids?.includes(appUser.firestoreUser?.uid || '');
  };

  const studentProgressShouldBeSaved = () => {
    if (appContent.chapterMarkedAsReadLoading) {
      return false;
    }
    if (isAppUserPresentInCurrentChapter()) {
      return false;
    }
    return true;
  };

  const showCompleteButton = () => {
    return isAppUserPresentInCurrentChapter() && isAtLastChapter();
  };

  const getMaxIndexOfChapters = () => {
    return appContent.contentData.length - 1;
  };

  const getIndexOfCurrentCurrentChapter = () => {
    return appContent.contentData.map((v) => v.droppableId).indexOf(appContent.mainDroppableId);
  };

  const getNextDroppableId = () => {
    const idxOfCurrent = getIndexOfCurrentCurrentChapter();
    return appContent.contentData[idxOfCurrent + 1].droppableId;
  };

  const isNextChapterAvailable = () => {
    const maxIndex = getMaxIndexOfChapters();
    const idxOfCurrent = getIndexOfCurrentCurrentChapter();
    return maxIndex >= idxOfCurrent + 1;
  };

  const moveToNextChapter = () => {
    if (isNextChapterAvailable()) {
      const nextDroppableId = getNextDroppableId();
      appContent.setMainDroppableId(nextDroppableId);
    }
  };

  const store = useFirestore();

  const params = useParams() as {
    subjectId: string;
    lessonId: string;
    unitId: string;
    droppableId: string;
  };

  const [contentId, setContentId] = useState<string>('');

  useEffect(() => {
    appContent.setLoading(true);
    store
      .collection('content')
      .where('subjectId', '==', params.subjectId || '')
      .where('lessonId', '==', params.lessonId || '')
      .where('unitId', '==', params.unitId || 'noUnit')
      .get()
      .then((res) => {
        const resData = { ...res.docs[0].data() } as {
          contentData: LocalContentState[];
          subjectId: string;
          lessonId: string;
          unitId: string | undefined;
        };

        setContentId(res.docs[0].id || '');
        appContent.setLoading(false);
        setIndexHandler(resData.contentData);

        appContent.setMainDroppableId(resData.contentData[0].droppableId);
      })
      .catch(() => {
        appContent.setLoading(false);
        setIndexHandler([]);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.subjectId, params.lessonId, params.unitId]);

  const setIndexHandler = (localContentStateArray: LocalContentState[]) => {
    const resultLocalContentState: LocalContentState[] = [...localContentStateArray];

    for (let i = 0; i < localContentStateArray.length; i++) {
      const droppableId = localContentStateArray[i].droppableId;

      const ha = localContentStateArray[i].contentItems;

      for (let j = 0; j < ha.length; j++) {
        let contentItemDroppableId = ha[j].contentItemDroppableId;

        if (ha[j].mediaType === CONTENT_MEDIA_TYPE.LINKS) {
          resultLocalContentState[i].contentItems[j].component = (
            <LinksContent
              contentItemDroppableId={contentItemDroppableId}
              droppableId={droppableId}
            />
          );
        } else if (ha[j].mediaType === CONTENT_MEDIA_TYPE.MATERIALS) {
          resultLocalContentState[i].contentItems[j].component = (
            <MaterialContentCopy
              contentItemDroppableId={contentItemDroppableId}
              droppableId={droppableId}
            />
          );
        } else if (ha[j].mediaType === CONTENT_MEDIA_TYPE.TEXT) {
          resultLocalContentState[i].contentItems[j].component = (
            <TextContext
              contentItemDroppableId={contentItemDroppableId}
              droppableId={droppableId}
            />
          );
        } else if (ha[j].mediaType === CONTENT_MEDIA_TYPE.VIDEO) {
          resultLocalContentState[i].contentItems[j].component = (
            <VideoContent
              contentItemDroppableId={contentItemDroppableId}
              droppableId={droppableId}
            />
          );
        } else if (ha[j].mediaType === CONTENT_MEDIA_TYPE.QUIZ) {
        }
      }
    }

    appContent.setList && appContent.setList([...resultLocalContentState]);
  };

  return appContent.contentData.length > 0 ? (
    <Container className="student-content-body" fluid>
      <section className="student-content-body__main">
        {CONTENTS?.map((val, idx) => {
          if (val.mediaType === CONTENT_MEDIA_TYPE.LINKS) {
            const item = val as {
              links: Links;
              mediaType: CONTENT_MEDIA_TYPE;
              contentItemDroppableId: string;
              component: any;
            };

            return item.links.linkGroups.map((v, index) => {
              return (
                <a
                  className={`content-main-preview__links d-flex mb-1 ${
                    item.links.linkGroups.length - 1 === index ? 'mb-3' : ''
                  }`}
                  href={v.url.value}
                  key={index}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLink style={{ color: 'rgb(151, 151, 151)' }} className="mr-2" /> {v.ref.value}
                </a>
              );
            });
          } else if (val.mediaType === CONTENT_MEDIA_TYPE.MATERIALS) {
            const item = val as {
              materialUrls: Item[];
              mediaType: CONTENT_MEDIA_TYPE;
              contentItemDroppableId: string;
              component: any;
            };

            const i = item.materialUrls.map((val, i) => {
              return (
                <div
                  className="d-flex mb-3 align-items-center"
                  style={{
                    marginRight: '2rem',
                  }}
                  key={i}
                >
                  {/* <FaFileAlt className="mr-2 " size={40} /> */}
                  <FileIcon fileName={''} url={val.value} />
                  <div className="content-main-preview__filename">
                    {getMaterialNameFromUrl(decodeURI(val.value))}
                  </div>
                </div>
              );
            });

            return (
              <section className="pb-4 " style={{ marginTop: '4rem' }}>
                <h4 className="content-main-preview-title__tertiary">Attachments:</h4>
                <div
                  className="d-flex"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    width: '100%',
                  }}
                >
                  {i}
                </div>
              </section>
            );
          } else if (val.mediaType === CONTENT_MEDIA_TYPE.TEXT) {
            const item = val as {
              textContent: Item;
              mediaType: CONTENT_MEDIA_TYPE;
              contentItemDroppableId: string;
              component: any;
            };
            return (
              <section className="mb-3  content-text" key={idx}>
                {renderHTML(item.textContent.value || '')}
              </section>
            );
          } else if (val.mediaType === CONTENT_MEDIA_TYPE.VIDEO) {
            const item = val as {
              videoUrl: Item;
              mediaType: CONTENT_MEDIA_TYPE;
              contentItemDroppableId: string;
              component: any;
            };

            return (
              <div className="mb-3 content-main-preview__m-0">
                <Player src={item.videoUrl.value}>
                  <LoadingSpinner />
                  <BigPlayButton position="center" />
                  <ControlBar autoHide={false}>
                    <PlayToggle />
                    <ReplayControl seconds={10} />
                    <ForwardControl seconds={10} />
                    <VolumeMenuButton vertical />
                  </ControlBar>
                </Player>
              </div>
            );
          } else if (val.mediaType === CONTENT_MEDIA_TYPE.QUIZ) {
            return <></>;
          } else {
            return <></>;
          }
        })}
      </section>

      <footer className="text-right">
        <button
          style={
            appContent.chapterMarkedAsReadLoading
              ? { backgroundColor: 'grey', cursor: 'default' }
              : {}
          }
          disabled={!!appContent.chapterMarkedAsReadLoading}
          className={`content-main-preview__next-btn ${showCompleteButton() && 'd-none'} ${
            !isAbleToComplete() && 'content-main-preview__next-btn--disabled'
          }`}
          onClick={() => {
            if (studentProgressShouldBeSaved() && isAbleToComplete()) {
              appContent.chapterMarkedAsReadLoadingHandler(appContent.mainDroppableId);
              saveStudentContentProgressRef({
                contentId: contentId,
                droppableId: appContent.mainDroppableId,
                percentage: getStudentContentCompletedPercentage(
                  appContent.contentData,
                  appUser.firestoreUser?.uid || ''
                ),
              })
                .then((val) => {
                  appContent.chapterReadCheckUidsHandler(
                    appContent.mainDroppableId,
                    appUser.firestoreUser?.uid || ''
                  );
                  appContent.chapterMarkedAsReadLoadingHandler('');
                  moveToNextChapter();
                })
                .catch((e) => {
                  appContent.chapterMarkedAsReadLoadingHandler('');
                });
            } else {
              moveToNextChapter();
            }
          }}
        >
          {isAtLastChapter() ? 'COMPLETE' : 'Next'}
        </button>
      </footer>
    </Container>
  ) : (
    <InitialView
      title={`This ${params.unitId ? 'unit' : 'lesson'} is empty`}
      description={`This ${
        params.unitId ? 'unit' : 'lesson'
      } does not contain any content. Click the below button to add new content to the ${
        params.unitId ? 'unit' : 'lesson'
      }`}
      btnTitle={''}
      btnOnClick={() => {}}
      removeBtn={true}
    />
  );
};

export default StudentContentBody;

const FileIcon = (props: { fileName: string; url: string }) => {
  return (
    <div
      className="fi-wrapper"
      onClick={() => {
        const link = document.createElement('a');
        link.href = props.url;
        link.setAttribute('download', props.fileName);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        link?.parentNode?.removeChild(link);
      }}
    >
      <div className="file-icon">
        <div style={{ width: '2.9rem', height: '2.9rem' }}>
          <FaFilePdf style={{ fontSize: '1.5rem' }} className="micon" />
        </div>
      </div>
      <div className="fifn" style={{ wordBreak: 'break-all' }}>
        {props.fileName}
      </div>
    </div>
  );
};
