import React, { useEffect, useMemo, useState } from 'react';
import './BulkAccessCodesOfPackage.scss';
import { FaFileDownload, FaCircle } from 'react-icons/fa';
import Pagination from './Pagination';
import { splitArrayIntoChunksOfLen } from '../utils';
import { useHistory, useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import CreateBulkAccessCodeModal from './CreateBulkAccessCodeModal';

export interface State {
  page: number;
  totalPages: number;
  codes: any[];
  loading: boolean;
  filtered: boolean;
  cid: number;
  package: any;
  show: boolean;
}

const BulkAccessCodesOfPackage = () => {

  const location = useLocation();

  const codes = useMemo(() => {
    return [
      {
        id: 1,
        c: 'ITAL|22|1051|100%',
        expire: 'Exp: 29/07/2023',
        availablity: 'Available',
        amount: '1200/2000',
      },
      {
        id: 2,
        c: 'ITAL|22|1051|100%',
        expire: 'Exp: 29/07/2023',
        availablity: 'Available',
        amount: '1200/2000',
      },
      {
        id: 3,
        c: 'ITAL|22|1051|100%',
        expire: 'Exp: 29/07/2023',
        availablity: 'Available',
        amount: '1200/2000',
      },
      {
        id: 4,
        c: 'ITAL|22|1051|100%',
        expire: 'Exp: 29/07/2023',
        availablity: 'Available',
        amount: '1200/2000',
      },
      {
        id: 5,
        c: 'ITAL|22|1051|100%',
        expire: 'Exp: 29/07/2023',
        availablity: 'Available',
        amount: '1200/2000',
      },
      {
        id: 6,
        c: 'ITAL|22|1051|100%',
        expire: 'Exp: 29/07/2023',
        availablity: 'Available',
        amount: '1200/2000',
      },
      {
        id: 7,
        c: 'ITAL|22|1051|100%',
        expire: 'Exp: 29/07/2023',
        availablity: 'Available',
        amount: '1200/2000',
      },
      {
        id: 8,
        c: 'ITAL|22|1051|100%',
        expire: 'Exp: 29/07/2023',
        availablity: 'Available',
        amount: '1200/2000',
      },
      {
        id: 9,
        c: 'ITAL|22|1051|100%',
        expire: 'Exp: 29/07/2023',
        availablity: 'Available',
        amount: '1200/2000',
      },
      {
        id: 10,
        c: 'ITAL|22|1051|100%',
        expire: 'Exp: 29/07/2023',
        availablity: 'Available',
        amount: '1200/2000',
      },
      {
        id: 11,
        c: 'ITAL|22|1051|100%',
        expire: 'Exp: 29/07/2023',
        availablity: 'Available',
        amount: '1200/2000',
      },
      {
        id: 12,
        c: 'ITAL|22|1051|100%',
        expire: 'Exp: 29/07/2023',
        availablity: 'Available',
        amount: '1200/2000',
      },
      {
        id: 13,
        c: 'ITAL|22|1051|100%',
        expire: 'Exp: 29/07/2023',
        availablity: 'Available',
        amount: '1200/2000',
      },
    ];
  }, []);

  const history = useHistory();

  const [state, setState] = useState<State>({
    page: 0,
    totalPages: 0,
    codes: codes,
    loading: false,
    filtered: false,
    cid: useParams(),
    package: location.state,
    show: false
  });

  useEffect(() => {
    setState((ps) => ({
      ...ps,
      codes: codes,
    }));
  }, [codes]);

  const fetchDataCall = (filter?: boolean) => {
    if (typeof state.totalPages === 'number') {
      setState((ps) => {
        return { ...ps, loading: true };
      });

      const pageToSend = filter ? 0 : state.page - 1 < 0 ? 0 : state.page - 1;

      let filteredList = [...codes];

      let duplicatesRemovedArray: any[];

      duplicatesRemovedArray = filteredList;

      let pagesList: any[] = [];

      if (duplicatesRemovedArray.length) {
        pagesList = splitArrayIntoChunksOfLen(duplicatesRemovedArray, 12);
      }

      const totalPages = pagesList.length;

      setState((ps) => {
        return {
          ...ps,
          loading: false,
          codes: pagesList[pageToSend] || [],
          totalPages: totalPages,
          filtered: filter ? true : false,
        };
      });
    }
  };

  const setCurrentPage = (page: number) => {
    setState((ps) => {
      return { ...ps, page: page };
    });
  };

  useEffect(() => {
    if (codes.length) {
      fetchDataCall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.page, codes]);

  useEffect(() => {
    setState((ps) => {
      return { ...ps, filtered: false };
    });
  }, [state.filtered]);

  const openModalHandler = () => {
    setState((ps) => {
      return { ...ps, show: true };
    });
  };

  const closeModalHandler = (resetModalState?: () => void) => {
    setState((ps) => {
      return { ...ps, show: false, enrollSearchText: '' };
    });
  };

  return (
    <div className="text-white">
      <div className="PackageAccessCodes d-flex" onClick={() => { }}>
        <div className="PackageAccessCodes__image">
          <img
            src="https://s3-ap-south-1.amazonaws.com/blogmindler/bloglive/wp-content/uploads/2022/06/08154006/Blog-4-770x385.png"
            alt=""
          />
        </div>
        <div className="PackageAccessCodes__grade">{state.package.grade}</div>

        <div className="PackageAccessCodes__content">
          <span className="PackageAccessCodes__content-title">
            {' '}
            October 2022 Information Technology{' '}
          </span>
          <span className="PackageAccessCodes__content-createdBy">By Hasith Deminda</span>

          <span className="PackageAccessCodes__content-year"> {state.package.year} </span>
        </div>
        <div className="PackageAccessCodes__button">
          <button onClick={openModalHandler} className="PackageAccessCodes__button-create btn btn-primary ">
            Create Bulk Code
          </button>
        </div>
      </div>

      <div className="bulkCode">
        {state.codes?.map((code: any) => (
          <div className="bulkCode__codebox d-flex"
            onClick={() => {
              history.push(`/settings/bulk/${state.cid}/${code.id}`);
            }}
            key={code.id}>
            <div style={{ flex: 4, marginRight: '75px' }}>
              <div className="bulkCode__codebox-code">
                {code.c} <FaCircle size={6} color={'#00FF00'} style={{ justifyItems: 'center' }} />
              </div>
              <div className="bulkCode__codebox-expire">{code.expire}</div>
            </div>
            <div style={{ flex: 4 }}>
              <div className="bulkCode__codebox-availability">{code.availablity}</div>
              <div className="bulkCode__codebox-amount">{code.amount}</div>
            </div>
            <div className="bulkCode__codebox-downicon" style={{ flex: 1 }}>
              <FaFileDownload size={25} />
            </div>
          </div>
        ))}
      </div>
      <div>
        {!state.filtered && state.totalPages > 1 ? (
          <Pagination pages={state.totalPages} setCurrentPage={setCurrentPage} />
        ) : (
          <></>
        )}
      </div>
      <CreateBulkAccessCodeModal show={state.show} closeHandler={closeModalHandler} grade={state.package.grade} year={state.package.year} sub={state.package.sub} package={state.package.package} />
    </div>
  );
};

export default BulkAccessCodesOfPackage;
