import React, { useState } from 'react';
import './AccessCodes.scss';
import SearchComponent from './SearchWithFilter';
import AccessCodesPackages from './AccessCodesPackages';
import AccessCodeList from './AccessCodeList';
import CreateCommonAccessCodeModal from '../../src/components/CreateCommonAccessCodeModal';
import CreateBulkAccessCodeModal from './CreateBulkAccessCodeModal';
import { Route, Switch } from 'react-router-dom';
import BulkAccessCodesOfPackage from './BulkAccessCodesOfPackage';
import CodesSubTabs from './CodesSubTabs';
import BulkAccessCodeUsage from './BulkAccessCodeUsage';

export interface AccessCodeState {
  searchText: string;
  show: boolean;
}

const CommonAccessCodes = () => {
  const initialState: AccessCodeState = {
    searchText: '',
    show: false,
  };

  const [state, setState] = useState<AccessCodeState>(initialState);

  const openModalHandler = () => {
    setState((ps) => {
      return { ...ps, show: true };
    });
  };

  const closeModalHandler = (resetModalState?: () => void) => {
    setState((ps) => {
      return {
        ...ps, show: false
      };
    });
  };

  return (
    <div className="text-white">
      <div className="d-flex align-items-center justify-content-between mb-5">
        <SearchComponent
          onSearch={(text) => {
            setState((ps) => {
              return { ...ps, searchText: text };
            });
          }}
          placeHolderText="Search Code"
          onFilterClick={() => { }}
          onClickLink={() => { }}
        />

        <button onClick={openModalHandler} className="btn btn-primary accessCodeCreation">
          {' '}
          Create Common Code{' '}
        </button>
      </div>
      <div>
        <AccessCodeList sText={state.searchText} />
      </div>
      <CreateCommonAccessCodeModal show={state.show} closeHandler={closeModalHandler} />
    </div>
  );
};

export interface BulkAccessCodeState {
  show: boolean;
}

const BulkAccessCodesSection = () => {
  const initialState: BulkAccessCodeState = {
    show: false,
  };

  const [state, setState] = useState<BulkAccessCodeState>(initialState);

  const openModalHandler = () => {
    setState((ps) => {
      return { ...ps, show: true };
    });
  };

  const closeModalHandler = (resetModalState?: () => void) => {
    setState((ps) => {
      return { ...ps, show: false, enrollSearchText: '' };
    });
  };

  return (
    <div className="text-white">
      <div className="d-flex align-items-center justify-content-between mb-5">
        <SearchComponent
          onSearch={() => { }}
          placeHolderText="Search Package "
          onFilterClick={() => { }}
          onClickLink={() => { }}
        />

        <button onClick={openModalHandler} className="btn btn-primary accessCodeCreation">
          Create Bulk Code
        </button>
      </div>
      <div>
        <AccessCodesPackages />
      </div>
      <CreateBulkAccessCodeModal show={state.show} closeHandler={closeModalHandler} grade={''} year={''} sub={''} package={''} />
    </div>
  );
};

const AccessCodes = () => {
  return (
    <Switch>
      <Route path="/settings/bulk/:id/:cid">
        <CodesSubTabs activeTab="bulk" />
        <BulkAccessCodeUsage />
      </Route>
      <Route path="/settings/bulk/:id">
        <CodesSubTabs activeTab="bulk" />
        <BulkAccessCodesOfPackage />
      </Route>
      <Route path="/settings/bulk">
        <CodesSubTabs activeTab="bulk" />
        <BulkAccessCodesSection />
      </Route>
      <Route path="/settings">
        <CodesSubTabs activeTab="common" />
        <CommonAccessCodes />
      </Route>
    </Switch>
  );
};
export default AccessCodes;
