import { FC, useState } from 'react';
import { FaWindowClose } from 'react-icons/fa';
import Modal from 'react-modal';
import DropDown from './DropDown';
import LoadingIndicator from './LoadingIndicator';
import TextInput from './TextInput';
import { DISCOUNT_TYPES, GRADES, YEARS } from '../constants';
import { validateString, validateStringSpaces } from '../validation/Validation';
import './CreateCommonAccessCodeModal.scss';
import DatePickerComp from './DatePickerComp';
import FilterCheckboxLeft from './FilterCheckboxLeft';

interface Props {
  closeHandler: (resetFileState: () => void) => void;
  show: boolean;
}

const CreateCommonAccessCodeModal: FC<Props> = (props) => {
  interface State {
    loading: boolean;
    accessCode: {
      value: string;
      error: string;
      validations: Function[];
      label: string;
      max: number;
    };
    activatedDate: Date;
    expireDate: Date;
    discountedAmount: number;
    discountType: string;
    noOfAccessCodes: number;
    grade: number;
    year: number;
    subject: string;
    package: string;
  }

  const initialState: State = {
    loading: false,
    discountedAmount: 0,
    discountType: '',
    noOfAccessCodes: 0,
    grade: 0,
    year: 0,
    subject: '',
    package: '',
    activatedDate: new Date(),
    expireDate: new Date(),
    accessCode: {
      value: '',
      error: '',
      validations: [validateString, validateStringSpaces],
      label: 'Access Code',
      max: 20,
    },
  };

  const [state, setState] = useState<State>(initialState);
  const [active, setActive] = useState(false);

  const resetModalState = () => {
    setState(initialState);
    setState((ps) => {
      return {
        ...ps, accessCode: {
          value: '',
          error: '',
          validations: [validateString, validateStringSpaces],
          label: 'Access Code',
          max: 20,
        }
      };
    });
  };

  const createCAccessCodeHandler = async () => { };

  const choiceHandler = async () => {
    if (active === false) {
      setActive(true);
    } else {
      setActive(false);
    }
  };

  const genetrateCode = () => {

    const sChar = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const iChar = '0123456789';
    const sCharactersLength = sChar.length;
    const iCharactersLength = iChar.length;
    let code = '';
    for (let i = 0; i < 3; ++i) {
      code += sChar[Math.floor(Math.random() * sCharactersLength)];
    }
    for (let j = 0; j < 5; ++j) {
      code += iChar[Math.floor(Math.random() * iCharactersLength)];
    }

    setState((ps) => {
      return {
        ...ps, accessCode: {
          value: String(code),
          error: '',
          validations: [validateString, validateStringSpaces],
          label: 'Access Code',
          max: 20,
        }
      };
    });
  }

  return (
    <Modal
      isOpen={props.show}
      contentLabel="Example Modal"
      className="create-common-access-code-modal"
      overlayClassName="r-overlay"
      ariaHideApp={false}
      closeTimeoutMS={500}
    >
      <div className="create-common-access-code-modal__header">
        {'Create Common Acess Code'}
        <FaWindowClose
          color="#FF4C6C"
          size={20}
          style={!state.loading ? { cursor: 'pointer' } : { cursor: 'default' }}
          onClick={() => {
            if (!state.loading) {
              props.closeHandler(resetModalState);
            }
          }}
        />
      </div>
      {state.loading && <LoadingIndicator />}
      <div className="create-common-access-code-modal__main">
        <div className="create-common-access-code-modal-inputs">
          <div >
            <div onClick={genetrateCode} className='create-common-access-code-modal-autoGBtn'>
              Auto Generate
            </div>
            <TextInput
              stateName="accessCode"
              state={state}
              setState={setState}
              placeHolder="Access Code"
              stateValue={state.accessCode.value}
              error=""
            />
          </div>

          <div style={{ marginTop: 15 }}>
            <DatePickerComp
              name="Activated Date"
              className="account-margin-dob"
              stateName="dob"
              state={state}
              setState={setState}
              error=""
              stateValue={state.activatedDate}
            />
          </div>

          <div style={{ marginTop: 25 }}>
            <DatePickerComp
              name="Expire Date"
              className="account-margin-dob"
              stateName="dob"
              state={state}
              setState={setState}
              error=""
              stateValue={state.expireDate}
            />
          </div>

          <div className="d-flex code-discount" style={{ marginTop: 13 }}>
            <div>
              <TextInput
                stateName="discountedAmount"
                state={state}
                setState={setState}
                placeHolder="Discounted Amount"
                stateValue={''}
                error=""
              />
            </div>
            <div>
              <DropDown
                name="Discount Type"
                noValueText="Select discount type"
                stateName="discountType"
                state={state}
                setState={setState}
                optionsArray={DISCOUNT_TYPES}
                style={{ marginTop: 8 }}
                stateValue={{
                  displayValue: '',
                  id: '',
                }}
                error=""
              />
            </div>
          </div>

          <TextInput
            style={{ marginTop: 10 }}
            stateName="noOfAccessCodes"
            state={state}
            setState={setState}
            placeHolder="Number of Access Codes"
            stateValue={''}
            error=""
          />

          <div className="d-flex pkg-choice flex-container" style={{ marginTop: 18 }}>
            <div> Applicable of all packages ?</div>
            <div style={{ alignItems: 'center' }}> Yes </div>
            <div>
              <FilterCheckboxLeft
                onClick={choiceHandler}
                item={{
                  label: '',
                  name: '',
                }}
                isChecked={active}
              />
            </div>
            <div> No </div>
            <div>
              <FilterCheckboxLeft
                onClick={choiceHandler}
                item={{
                  label: '',
                  name: '',
                }}
                isChecked={!active}
              />
            </div>
          </div>

          {!active ? (
            <div className="d-flex c-details" style={{ marginTop: 15 }}>
              <div>
                <DropDown
                  name="Grade"
                  noValueText="Select"
                  stateName="grade"
                  state={state}
                  setState={setState}
                  optionsArray={GRADES}
                  style={{ marginTop: 8 }}
                  error=""
                  stateValue={{
                    displayValue: '',
                    id: '',
                  }}
                />
              </div>
              <div>
                <DropDown
                  name="Year"
                  noValueText="Select"
                  stateName="year"
                  state={state}
                  setState={setState}
                  error=""
                  optionsArray={YEARS}
                  style={{ marginTop: 8 }}
                  stateValue={{
                    displayValue: '',
                    id: '',
                  }}
                />
              </div>
              <div>
                <DropDown
                  name="Subject"
                  noValueText="Select"
                  stateName="subject"
                  state={state}
                  setState={setState}
                  optionsArray={DISCOUNT_TYPES}
                  error=""
                  style={{ marginTop: 8 }}
                  stateValue={{
                    displayValue: '',
                    id: '',
                  }}
                />
              </div>
            </div>
          ) : (
            ''
          )}

          <DropDown
            name="Package"
            noValueText="Select the package"
            stateName="package"
            state={state}
            setState={setState}
            error=""
            optionsArray={DISCOUNT_TYPES}
            style={{ marginTop: 15 }}
            stateValue={{
              displayValue: '',
              id: '',
            }}
          />
        </div>
      </div>
      <button
        className={`create-common-access-code-modal__btn ${state.loading ? 'btn-disable-add-sub' : ''
          }`}
        onClick={() => {
          if (!state.loading) {
            createCAccessCodeHandler();
          }
        }}
        style={{ width: '100%' }}
      >
        {'Create Access Code'}
      </button>
    </Modal>
  );
};

export default CreateCommonAccessCodeModal;
