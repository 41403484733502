import React, {FC} from 'react';
import {FaPlusCircle} from 'react-icons/fa';
import {useMobileNav} from '../context/MobNavContext';
import {useAppUser} from '../context/UserContext';
import AddSubjectBtn from './AddSubjectBtn';
import BreadCrum from './BreadCrum';
import HamburgerIcon from './HamburgerIcon';

interface Props {
  currentTab: string;
  openModal: () => void;
}

const SubjectsHeader: FC<Props> = (props) => {
  const user = useAppUser();
  const mobileNav = useMobileNav();

  return (
    <div className="d-md-flex justify-content-between align-items-center">
      <div className="d-flex d-md-none justify-content-between">
        <div style={{color: '#fff'}}>{props.currentTab}</div>
        <HamburgerIcon
          onClick={() => {
            mobileNav.setOpenMobileNav(!mobileNav.openMobileNav);
          }}
        />
      </div>

      <BreadCrum className="d-none d-md-flex" />
      <div className="text-center">
        {user.firestoreUser?.userRole?.isStudent ? (
          <AddSubjectBtn
            text="Enroll to New Subject"
            icon={FaPlusCircle}
            onClick={props.openModal}
          />
        ) : user.firestoreUser?.userRole?.isAdmin || user.firestoreUser?.userRole?.isInstructor ? (
          <AddSubjectBtn text="Add Subject" icon={FaPlusCircle} onClick={props.openModal} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default SubjectsHeader;
