import React, {FC, ForwardedRef, forwardRef} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {FaCalendarAlt} from 'react-icons/fa';
import {Col, Row} from 'react-bootstrap';

import './DatePickerComp.scss';

interface Props {
  name: string;
  className?: string;
  stateName: string;
  stateValue: Date;
  state: any;
  setState: Function;
  error: string;
}

const DatePickerComp: FC<Props> = (props) => {
  let borderColor =
    props.error === 'NO-ERROR'
      ? {borderBottom: '1px solid #20D167'}
      : props.error === ''
      ? {}
      : {borderBottom: '1px solid #dc3545'};

  const inputChangeHandler = (state: any, name: string, value: Date | null) => {
    props.setState({
      ...state,
      [name]: {...state[name], value: value},
    });
  };

  const CustomInput = forwardRef((props: any, ref: ForwardedRef<any>) => (
    <>
      <Row>
        <Col>
          <input
            {...props}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            ref={ref}
          />
        </Col>
        <Col className="col-auto">
          <FaCalendarAlt color={'#246BFD'} onClick={props.onClick} />
        </Col>
      </Row>
    </>
  ));

  return (
    <div className={`datepicker-wrapper ${props.className}`} style={borderColor}>
      <div className="datepicker-name">{props.name}</div>

      <DatePicker
        className="datepicker-comp"
        selected={props.stateValue}
        onChange={(date: Date) => {
          inputChangeHandler(props.state, props.stateName, date);
        }}
        dateFormat="dd / MM / yyyy"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        maxDate={new Date()}
        customInput={<CustomInput />}
      />
    </div>
  );
};

export default DatePickerComp;
