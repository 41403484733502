import React, { FC, Fragment, useEffect, useRef } from 'react';
import { FaRegFilePdf } from 'react-icons/fa';
import './PDFUploaderComp.scss';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import TextInput from './TextInput';
import { IoMdClose } from 'react-icons/io';

interface Props {
  style?: React.CSSProperties;
  stateValue: string;
  paperId: string;
  file: null | File;
  progress: number;
  error: string;
  placeHolder: 'File Name';
  fileLoading: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  removeFromStorage: (url: string, paperId: string) => void;
  url: string;
  pageNo?: number;
}

const PDFUploaderCompExam: FC<Props> = (props) => {
  const ref = useRef<any>(null);

  useEffect(() => {
    if (!props.url) {
      ref.current = null;
    }
  }, [props.url, props.pageNo]);

  return (
    <>
      <div className="add-subject-modal-inputs">
        <div className="cover-imageST" style={{ ...props.style }}>
          <TextInput
            stateName=""
            stateValue={props.stateValue}
            state=""
            setState={() => {}}
            error=""
            placeHolder={props.placeHolder}
          />

          <div className="add-cover-image-btn-wrap position-relative">
            <input
              type="file"
              className="position-absolute"
              style={{ opacity: 0 }}
              onChange={(e) => {
                if (!props.fileLoading && !props.stateValue) {
                  props.onChange(e);
                }
              }}
              ref={ref}
              value=""
            />
            <button className="add-cover-image-btn">Upload PDF</button>
          </div>
        </div>
      </div>
      {props.url ? (
        <div
          className="add-subject-modal-image position-absolute d-flex"
          style={{ marginTop: '24px' }}
        >
          {props.stateValue && (
            <div className="mr-2">
              {/* <a
                href={props.url}
                rel="noopener noreferrer"
                target="_blank"
                style={{ textDecoration: 'none', color: '#73847F' }}
              >
                <div className="d-inline-block material-container">
                  <FaRegFilePdf />
                </div>
              </a>

              <div className="text-white" onClick={(e) => {
                props.removeFromStorage(props.url, props.paperId);
                e.stopPropagation();
              }} style={{ fontSize: 18, cursor: "pointer" }}>&times;</div> */}
              <a
                className="material-container"
                href={props.url}
                rel="noopener noreferrer"
                target="_blank"
                style={{
                  width: '70px',
                  height: '85px',
                  borderRadius: '8px',
                  backgroundColor: '#2c2e41',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    color: '#474A66',
                  }}
                >
                  <FaRegFilePdf
                    className="pdf-icon"
                    style={{
                      height: '30px',
                      width: '30px',
                      transform: 'translate(0px, 0px)',
                      textAlign: 'center',
                    }}
                  />
                </div>
              </a>
              <IoMdClose
                className="close-icon"
                onClick={(e) => {
                  props.removeFromStorage(props.url, props.paperId);
                  e.stopPropagation();
                }}
                style={{
                  position: 'absolute',
                  top: 40,
                  left: 18,
                  fontSize: 20,
                  color: '#F05A5A',
                  cursor: 'pointer',
                }}
              />
            </div>
          )}

          <span
            className="pdf-title text-white"
            style={{
              fontSize: 14,
              fontWeight: 400,
              maxWidth: '250px',
            }}
          >
            <a
              href={props.url}
              rel="noopener noreferrer"
              target="_blank"
              style={{
                textDecoration: 'none',
                color: '#ffff',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                height: '100%',
              }}
            >
              {props.stateValue}
            </a>
          </span>
        </div>
      ) : props.fileLoading ? (
        <Fragment>
          <div
            className="vh-100 vw-100"
            style={{ top: 0, left: 0, position: 'fixed', zIndex: 1 }}
          ></div>
          <div style={{ height: 50, width: 50 }} className="align-self-center mt-3">
            <CircularProgressbar
              value={props.progress}
              text={props.progress?.toFixed(0) + '%'}
              styles={buildStyles({
                textColor: '#fff',
                pathColor: '#246BFD',
                trailColor: '#fff',
                backgroundColor: '#fff',
                textSize: '1.5rem',
                pathTransition: 'none',
              })}
            />
          </div>
        </Fragment>
      ) : null}
    </>
  );
};

export default PDFUploaderCompExam;
