import React, { createContext, FC, useContext } from 'react';
import useSessionStorage from '../../../hooks/useSessionStorage';
import LinksContent from '../components/LinksContent';
import MaterialContentCopy from '../components/MaterialContentCopy';
import VideoContent from '../components/VideoContent';
import {
  initialLocalContentState,
  Item,
  Links,
  LocalContentState,
} from '../interfaces/LocalContentState';
import TextContext from '../screen/adminAndInstructor/TextContext';
import { CONTENT_MEDIA_TYPE } from '../utils';
import {
  validateContentTitle,
  validateRef,
  validateText,
  validateUrl,
  validateVideoUrl,
} from '../validations/ContentValidation';

export interface ContentContext {
  contentData: LocalContentState[];
  tinyMCEChangedTimeSnap: string;
  loading: boolean;
  droppableId: string;
  mainDroppableId: string;
  previousPathToPreview: string;
  isLastChapter: boolean;
  chapterMarkedAsReadLoading: string;
  setLoading: (val: boolean) => void;
  setState: React.Dispatch<React.SetStateAction<State>> | null;
  setList: (data: LocalContentState[]) => void;
  setInitData: (droppableId: string, cItems: any) => void;
  setInitialState: (data: any) => void;
  setContentData: (droppableId: string, contentItems: any) => void;
  replaceOrAddContentDataByDroppableId: (droppableId: string, cItems: any) => void;
  setTinyMCEChangedTimeSnap: () => void;
  changeContentTitleHandler: (droppableId: string, value: string) => void;
  addNewTextContentSlotHandler: (droppableId: string) => void;
  addNewLinkSlotHandler: (droppableId: string) => void;
  addNewSubLinkSlotHandler: (droppableId: string, contentItemDroppableId: string) => void;
  addNewExtraMaterialsSlotHandler: (droppableId: string) => void;
  addNewVideoSlotHandler: (droppableId: string) => void;
  removeNewTextContentSlotHandler: (droppableId: string, contentItemDroppableId: string) => void;
  removeNewLinkSlotHandler: (droppableId: string, contentItemDroppableId: string) => void;
  removeNewSubLinkSlotHandler: (
    droppableId: string,
    contentItemDroppableId: string,
    index: number
  ) => void;
  removeNewExtraMaterialsSlotHandler: (droppableId: string, contentItemDroppableId: string) => void;
  removeNewVideoSlotHandler: (droppableId: string, contentItemDroppableId: string) => void;
  changeLinkContentHandler: (
    droppableId: string,
    contentItemDroppableId: string,
    index: number,
    txt: string,
    type: 'ref' | 'url'
  ) => void;
  extraMaterialHandler: (droppableId: string, contentItemDroppableId: string, value: Item) => void;
  textContentHandler: (
    droppableId: string,
    contentItemDroppableId: string,
    inputText: string
  ) => void;
  removeExtraMaterialItem: (
    droppableId: string,
    contentItemDroppableId: string,
    index: number
  ) => void;
  cleanLastSlot: () => void;
  removeChapter: (droppableId: string) => void;
  setDroppableId: (droppableId: string) => void;
  setPreviousPathToPreview: (url: string) => void;
  validateData: (contentData: LocalContentState[]) => any;
  resetContentTitleErrorHandler: (droppableId: string) => void;
  resetTextContentErrorHandler: (droppableId: string, contentItemDroppableId: string) => void;
  resetVideoContentErrorHandler: (droppableId: string, contentItemDroppableId: string) => void;
  resetErrorLinkContentHandler: (
    droppableId: string,
    contentItemDroppableId: string,
    index: number,
    type: 'ref' | 'url'
  ) => void;
  resetMaterialHandler: (droppableId: string, contentItemDroppableId: string) => void;
  setMainDroppableId: (droppableId: string) => void;
  inPreviewChapterReadCheckHandler: (droppableId: string) => void;
  setIsLastChapter: (value: boolean) => void;
  chapterReadCheckUidsHandler: (droppableId: string, uid: string) => void;
  chapterMarkedAsReadLoadingHandler: (droppableId: string) => void;
}

interface State {
  contentData: LocalContentState[];
  tinyMCEChangedTimeSnap: string;
  loading: boolean;
  droppableId: string;
  mainDroppableId: string;
  previousPathToPreview: string;
  isLastChapter: boolean;
  chapterMarkedAsReadLoading: string;
}

const initialContext: ContentContext = {
  contentData: initialLocalContentState,
  tinyMCEChangedTimeSnap: +new Date() + '',
  loading: false,
  droppableId: '',
  chapterMarkedAsReadLoading: '',
  mainDroppableId: '',
  previousPathToPreview: '',
  isLastChapter: false,
  setLoading: (val: boolean) => {},
  setState: null,
  setList: (data: LocalContentState[]) => {},
  setInitData: (droppableId: string, cItems: any) => {},
  setInitialState: (data: any) => {},
  setContentData: (droppableId: string, contentItems: any) => {},
  replaceOrAddContentDataByDroppableId: (droppableId: string, cItems: any) => {},
  setTinyMCEChangedTimeSnap: () => {},
  changeContentTitleHandler: (droppableId: string, value: string) => {},
  addNewTextContentSlotHandler: (droppableId: string) => {},
  addNewLinkSlotHandler: (droppableId: string) => {},
  addNewSubLinkSlotHandler: (droppableId: string, contentItemDroppableId: string) => {},
  addNewExtraMaterialsSlotHandler: (droppableId: string) => {},
  addNewVideoSlotHandler: (droppableId: string) => {},
  removeNewTextContentSlotHandler: (droppableId: string, contentItemDroppableId: string) => {},
  removeNewLinkSlotHandler: (droppableId: string, contentItemDroppableId: string) => {},
  removeNewSubLinkSlotHandler: (
    droppableId: string,
    contentItemDroppableId: string,
    index: number
  ) => {},
  removeNewExtraMaterialsSlotHandler: (droppableId: string, contentItemDroppableId: string) => {},
  removeNewVideoSlotHandler: (droppableId: string, contentItemDroppableId: string) => {},
  changeLinkContentHandler: (
    droppableId: string,
    contentItemDroppableId: string,
    index: number,
    txt: string,
    type: 'ref' | 'url'
  ) => {},
  extraMaterialHandler: (droppableId: string, contentItemDroppableId: string, value: Item) => {},
  textContentHandler: (
    droppableId: string,
    contentItemDroppableId: string,
    inputText: string
  ) => {},
  removeExtraMaterialItem: (
    droppableId: string,
    contentItemDroppableId: string,
    index: number
  ) => {},
  cleanLastSlot: () => {},
  removeChapter: (droppableId: string) => {},
  setDroppableId: (droppableId: string) => {},
  setPreviousPathToPreview: (url: string) => {},
  validateData: (contentData: LocalContentState[]) => {},
  resetContentTitleErrorHandler: (droppableId: string) => {},
  resetTextContentErrorHandler: (droppableId: string, contentItemDroppableId: string) => {},
  resetVideoContentErrorHandler: (droppableId: string, contentItemDroppableId: string) => {},
  resetErrorLinkContentHandler: (
    droppableId: string,
    contentItemDroppableId: string,
    index: number,
    type: 'ref' | 'url'
  ) => {},
  resetMaterialHandler: (droppableId: string, contentItemDroppableId: string) => {},
  setMainDroppableId: (droppableId: string) => {},
  inPreviewChapterReadCheckHandler: (droppableId: string) => {},
  setIsLastChapter: (value: boolean) => {},
  chapterReadCheckUidsHandler: (droppableId: string, uid: string) => {},
  chapterMarkedAsReadLoadingHandler: (droppableId: string) => {},
};

export const initialStateContentData: State = {
  contentData: initialLocalContentState,
  tinyMCEChangedTimeSnap: +new Date() + '',
  loading: false,
  droppableId: '',
  mainDroppableId: '',
  previousPathToPreview: '',
  isLastChapter: false,
  chapterMarkedAsReadLoading: '',
};

const Context = createContext<ContentContext>(initialContext);

export const ContentProvider: FC = ({ children }) => {
  // const [state, setState] = useState(initialStateContentData);

  const [state, setState] = useSessionStorage('contentDataKey', initialStateContentData);

  const setInitialState = (data: any) => {
    setState(data);
  };

  const setContentData = (droppableId: string, contentItems: any) => {
    const wholeState = [...state.contentData] as LocalContentState[];

    const mainStateSlice = {
      ...state.contentData.find((v) => v.droppableId === droppableId),
    } as LocalContentState;

    const mainIndex = wholeState?.map((v) => v.droppableId).indexOf(mainStateSlice.droppableId);

    wholeState[mainIndex].contentItems = contentItems;

    setState((ps) => {
      return { ...ps, contentData: wholeState };
    });
  };

  const setInitData = (droppableId: string, cItems: any) => {
    const wholeState = [
      ...state.contentData,
      { contentItems: cItems, contentTitle: { value: '', error: '' }, droppableId: droppableId },
    ] as LocalContentState[];

    setState((ps) => {
      return { ...ps, contentData: wholeState };
    });
  };

  const replaceOrAddContentDataByDroppableId = (droppableId: string, cItems: any) => {
    const isContentWithDIdPresent = [...state.contentData].find(
      (c) => c.droppableId === droppableId
    );

    if (isContentWithDIdPresent) {
      const data = [...state.contentData];

      const i = data.map((v) => v.droppableId).indexOf(droppableId);

      data[i].contentItems = cItems;

      setState((ps) => {
        return { ...ps, contentData: data };
      });
    } else {
      const wholeState = [
        ...state.contentData,
        { contentItems: cItems, contentTitle: { value: '', error: '' }, droppableId: droppableId },
      ] as LocalContentState[];

      setState((ps) => {
        return { ...ps, contentData: wholeState };
      });
    }
  };

  const setList = (data: LocalContentState[]) => {
    setState((ps) => {
      return { ...ps, contentData: data };
    });
  };

  const setTinyMCEChangedTimeSnap = () => {
    setState((ps) => ({
      ...ps,
      tinyMCEChangedTimeSnap: +new Date() + '',
    }));
  };

  const changeContentTitleHandler = (droppableId: string, value: string) => {
    const wholeState = [...state.contentData] as LocalContentState[];

    const mainStateSlice = {
      ...state.contentData.find((v) => v.droppableId === droppableId),
    } as LocalContentState;

    const mainIndex = state.contentData
      ?.map((val) => val.droppableId)
      .indexOf(mainStateSlice.droppableId);

    wholeState[mainIndex].contentTitle.value = value;

    setState((ps) => {
      return { ...ps, contentData: wholeState };
    });
  };

  const inPreviewChapterReadCheckHandler = (droppableId: string) => {
    const wholeState = [...state.contentData] as LocalContentState[];

    const mainStateSlice = {
      ...state.contentData.find((v) => v.droppableId === droppableId),
    } as LocalContentState;

    const mainIndex = state.contentData
      ?.map((val) => val.droppableId)
      .indexOf(mainStateSlice.droppableId);

    if (!wholeState[mainIndex]?.inPreviewChapterReadCheck) {
      wholeState[mainIndex].inPreviewChapterReadCheck = droppableId;
    }

    setState((ps) => {
      return { ...ps, contentData: wholeState };
    });
  };

  const chapterReadCheckUidsHandler = (droppableId: string, uid: string) => {
    const wholeState = [...state.contentData] as LocalContentState[];

    const mainStateSlice = {
      ...state.contentData.find((v) => v.droppableId === droppableId),
    } as LocalContentState;

    const mainIndex = state.contentData
      ?.map((val) => val.droppableId)
      .indexOf(mainStateSlice.droppableId);

    if (wholeState[mainIndex]?.chapterReadCheckUids?.length) {
      if (!wholeState[mainIndex].chapterReadCheckUids?.includes(uid)) {
        wholeState[mainIndex].chapterReadCheckUids = [
          ...wholeState[mainIndex].chapterReadCheckUids!,
          uid,
        ];
      }
    } else {
      wholeState[mainIndex].chapterReadCheckUids = [uid];
    }

    setState((ps) => {
      return { ...ps, contentData: wholeState };
    });
  };

  //  const getMainStateSlice = (droppableId: string) =>{
  //   const wholeState = [...state.contentData] as LocalContentState[];

  //   const mainStateSlice = {
  //     ...state.contentData.find((v) => v.droppableId === droppableId),
  //   } as LocalContentState;

  //   const mainIndex = state.contentData
  //     ?.map((val) => val.droppableId)
  //     .indexOf(mainStateSlice.droppableId);

  //     return {mainStateSlice: wholeState[mainIndex], wholeState }
  //  }

  const resetContentTitleErrorHandler = (droppableId: string) => {
    const wholeState = [...state.contentData] as LocalContentState[];

    const mainStateSlice = {
      ...state.contentData.find((v) => v.droppableId === droppableId),
    } as LocalContentState;

    const mainIndex = state.contentData
      ?.map((val) => val.droppableId)
      .indexOf(mainStateSlice.droppableId);

    wholeState[mainIndex].contentTitle.error = '';

    setState((ps) => {
      return { ...ps, contentData: wholeState };
    });
  };

  const extraMaterialHandler = (
    droppableId: string,
    contentItemDroppableId: string,
    value: Item
  ) => {
    const wholeState = [...state.contentData] as LocalContentState[];

    const mainStateSlice = {
      ...state.contentData.find((v) => v.droppableId === droppableId),
    } as LocalContentState;

    const subStateSlice = {
      ...mainStateSlice?.contentItems.find(
        (val) => val.contentItemDroppableId === contentItemDroppableId
      ),
    } as {
      materialUrls: Item[];
      mediaType: CONTENT_MEDIA_TYPE;
      contentItemDroppableId: string;
      component: any;
    };

    const mainIndex = state.contentData
      ?.map((val) => val.droppableId)
      .indexOf(mainStateSlice.droppableId);

    const subIndex = mainStateSlice?.contentItems
      ?.map((val) => val.contentItemDroppableId)
      .indexOf(subStateSlice.contentItemDroppableId);

    subStateSlice.materialUrls.push(value);

    wholeState[mainIndex].contentItems[subIndex] = subStateSlice;

    setState((ps) => {
      return { ...ps, contentData: wholeState };
    });
  };

  const resetMaterialHandler = (droppableId: string, contentItemDroppableId: string) => {
    const wholeState = [...state.contentData] as LocalContentState[];

    const mainStateSlice = {
      ...state.contentData.find((v) => v.droppableId === droppableId),
    } as LocalContentState;

    const subStateSlice = {
      ...mainStateSlice?.contentItems.find(
        (val) => val.contentItemDroppableId === contentItemDroppableId
      ),
    } as {
      materialUrls: Item[];
      mediaType: CONTENT_MEDIA_TYPE;
      contentItemDroppableId: string;
      error: string;
      component: any;
    };

    const mainIndex = state.contentData
      ?.map((val) => val.droppableId)
      .indexOf(mainStateSlice.droppableId);

    const subIndex = mainStateSlice?.contentItems
      ?.map((val) => val.contentItemDroppableId)
      .indexOf(subStateSlice.contentItemDroppableId);

    subStateSlice.error = '';

    wholeState[mainIndex].contentItems[subIndex] = subStateSlice;

    setState((ps) => {
      return { ...ps, contentData: wholeState };
    });
  };

  const removeExtraMaterialItem = (
    droppableId: string,
    contentItemDroppableId: string,
    index: number
  ) => {
    const wholeState = [...state.contentData] as LocalContentState[];

    const mainStateSlice = {
      ...state.contentData.find((v) => v.droppableId === droppableId),
    } as LocalContentState;

    const subStateSlice = {
      ...mainStateSlice?.contentItems.find(
        (val) => val.contentItemDroppableId === contentItemDroppableId
      ),
    } as {
      materialUrls: Item[];
      mediaType: CONTENT_MEDIA_TYPE;
      contentItemDroppableId: string;
      component: any;
    };

    const mainIndex = state.contentData
      ?.map((val) => val.droppableId)
      .indexOf(mainStateSlice.droppableId);

    const subIndex = mainStateSlice?.contentItems
      ?.map((val) => val.contentItemDroppableId)
      .indexOf(subStateSlice.contentItemDroppableId);

    subStateSlice.materialUrls.splice(index, 1);

    wholeState[mainIndex].contentItems[subIndex] = subStateSlice;

    setState((ps) => {
      return { ...ps, contentData: wholeState };
    });
  };

  const changeLinkContentHandler = (
    droppableId: string,
    contentItemDroppableId: string,
    index: number,
    txt: string,
    type: 'ref' | 'url'
  ) => {
    const wholeState = [...state.contentData] as LocalContentState[];

    const mainStateSlice = {
      ...state.contentData.find((v) => v.droppableId === droppableId),
    } as LocalContentState;

    const subStateSlice = {
      ...mainStateSlice?.contentItems.find(
        (val) => val.contentItemDroppableId === contentItemDroppableId
      ),
    } as {
      links: Links;
      mediaType: CONTENT_MEDIA_TYPE;
      contentItemDroppableId: string;
      component: any;
    };

    type === 'ref'
      ? (subStateSlice.links.linkGroups[index].ref.value = txt)
      : (subStateSlice.links.linkGroups[index].url.value = txt);

    const mainIndex = state.contentData
      ?.map((v) => v.droppableId)
      .indexOf(mainStateSlice.droppableId);

    const subIndex = mainStateSlice?.contentItems
      .map((v) => v.contentItemDroppableId)
      .indexOf(subStateSlice.contentItemDroppableId);

    wholeState[mainIndex].contentItems[subIndex] = subStateSlice;

    setState((ps) => {
      return { ...ps, contentData: wholeState };
    });
  };

  const resetErrorLinkContentHandler = (
    droppableId: string,
    contentItemDroppableId: string,
    index: number,
    type: 'ref' | 'url'
  ) => {
    const wholeState = [...state.contentData] as LocalContentState[];

    const mainStateSlice = {
      ...state.contentData.find((v) => v.droppableId === droppableId),
    } as LocalContentState;

    const subStateSlice = {
      ...mainStateSlice?.contentItems.find(
        (val) => val.contentItemDroppableId === contentItemDroppableId
      ),
    } as {
      links: Links;
      mediaType: CONTENT_MEDIA_TYPE;
      contentItemDroppableId: string;
      component: any;
    };

    type === 'ref'
      ? (subStateSlice.links.linkGroups[index].ref.error = '')
      : (subStateSlice.links.linkGroups[index].url.error = '');

    const mainIndex = state.contentData
      ?.map((v) => v.droppableId)
      .indexOf(mainStateSlice.droppableId);

    const subIndex = mainStateSlice?.contentItems
      .map((v) => v.contentItemDroppableId)
      .indexOf(subStateSlice.contentItemDroppableId);

    wholeState[mainIndex].contentItems[subIndex] = subStateSlice;

    setState((ps) => {
      return { ...ps, contentData: wholeState };
    });
  };

  const addNewTextContentSlotHandler = (droppableId: string) => {
    const wholeState = [...state.contentData] as LocalContentState[];

    const mainStateSlice = {
      ...wholeState.find((v) => v.droppableId === droppableId),
    } as LocalContentState;

    const mainIndex = wholeState?.map((v) => v.droppableId).indexOf(mainStateSlice.droppableId);

    // const contentItemDroppableIdsAsInts = mainStateSlice.contentItems.map((val) =>
    //   parseInt(val.contentItemDroppableId)
    // );

    // const contentItemDroppableId = maxContentItemDroppableId(contentItemDroppableIdsAsInts) + 1;
    const contentItemDroppableId = +new Date() + '';

    mainStateSlice.contentItems.push({
      textContent: { value: '', error: '' },
      mediaType: CONTENT_MEDIA_TYPE.TEXT,
      contentItemDroppableId: contentItemDroppableId,
      component: (
        <TextContext contentItemDroppableId={contentItemDroppableId} droppableId={droppableId} />
      ),
    });

    wholeState[mainIndex] = mainStateSlice;

    setState((ps) => {
      return { ...ps, contentData: wholeState };
    });
  };

  const addNewLinkSlotHandler = (droppableId: string) => {
    const wholeState = [...state.contentData] as LocalContentState[];

    const mainStateSlice = {
      ...state.contentData.find((v) => v.droppableId === droppableId),
    } as LocalContentState;

    const mainIndex = state.contentData
      ?.map((v) => v.droppableId)
      .indexOf(mainStateSlice.droppableId);

    // const contentItemDroppableIdsAsInts = mainStateSlice.contentItems.map((val) =>
    //   parseInt(val.contentItemDroppableId)
    // );

    // const contentItemDroppableId = maxContentItemDroppableId(contentItemDroppableIdsAsInts) + 1;
    const contentItemDroppableId = +new Date() + '';

    mainStateSlice.contentItems.push({
      links: {
        linkGroups: [
          {
            ref: { value: '', error: '' },
            url: { value: '', error: '' },
          },
        ],
      },
      mediaType: CONTENT_MEDIA_TYPE.LINKS,
      contentItemDroppableId: contentItemDroppableId,
      component: (
        <LinksContent contentItemDroppableId={contentItemDroppableId} droppableId={droppableId} />
      ),
    });

    wholeState[mainIndex] = mainStateSlice;

    setState((ps) => {
      return { ...ps, contentData: wholeState };
    });
  };

  const addNewSubLinkSlotHandler = (droppableId: string, contentItemDroppableId: string) => {
    const wholeState = [...state.contentData] as LocalContentState[];

    const mainStateSlice = {
      ...state.contentData.find((v) => v.droppableId === droppableId),
    } as LocalContentState;

    const subStateSlice = {
      ...mainStateSlice?.contentItems.find(
        (val) => val.contentItemDroppableId === contentItemDroppableId
      ),
    } as {
      links: Links;
      mediaType: CONTENT_MEDIA_TYPE;
      contentItemDroppableId: string;
      component: any;
    };

    subStateSlice.links.linkGroups.push({
      ref: { value: '', error: '' },
      url: { value: '', error: '' },
    });

    const mainIndex = state.contentData
      ?.map((v) => v.droppableId)
      .indexOf(mainStateSlice.droppableId);

    const subIndex = mainStateSlice?.contentItems
      .map((v) => v.contentItemDroppableId)
      .indexOf(subStateSlice.contentItemDroppableId);

    wholeState[mainIndex].contentItems[subIndex] = subStateSlice;

    setState((ps) => {
      return { ...ps, contentData: wholeState };
    });
  };

  const addNewExtraMaterialsSlotHandler = (droppableId: string) => {
    const wholeState = [...state.contentData] as LocalContentState[];

    const mainStateSlice = {
      ...wholeState.find((v) => v.droppableId === droppableId),
    } as LocalContentState;

    const mainIndex = wholeState?.map((v) => v.droppableId).indexOf(mainStateSlice.droppableId);

    // const contentItemDroppableIdsAsInts = mainStateSlice.contentItems.map((val) =>
    //   parseInt(val.contentItemDroppableId)
    // );

    // const contentItemDroppableId = maxContentItemDroppableId(contentItemDroppableIdsAsInts) + 1;
    const contentItemDroppableId = +new Date() + '';

    mainStateSlice.contentItems.push({
      materialUrls: [],
      mediaType: CONTENT_MEDIA_TYPE.MATERIALS,
      contentItemDroppableId: contentItemDroppableId,
      component: (
        <MaterialContentCopy
          contentItemDroppableId={contentItemDroppableId}
          droppableId={droppableId}
        />
      ),
    });

    wholeState[mainIndex] = mainStateSlice;

    setState((ps) => {
      return { ...ps, contentData: wholeState };
    });
  };

  const addNewVideoSlotHandler = (droppableId: string) => {
    const wholeState = [...state.contentData] as LocalContentState[];

    const mainStateSlice = {
      ...wholeState.find((v) => v.droppableId === droppableId),
    } as LocalContentState;

    const mainIndex = wholeState?.map((v) => v.droppableId).indexOf(mainStateSlice.droppableId);

    // const contentItemDroppableIdsAsInts = mainStateSlice.contentItems.map((val) =>
    //   parseInt(val.contentItemDroppableId)
    // );

    // const contentItemDroppableId = maxContentItemDroppableId(contentItemDroppableIdsAsInts) + 1;
    const contentItemDroppableId = +new Date() + '';

    mainStateSlice.contentItems.push({
      videoUrl: { value: '', error: '' },
      mediaType: CONTENT_MEDIA_TYPE.VIDEO,
      contentItemDroppableId: contentItemDroppableId,
      component: (
        <VideoContent contentItemDroppableId={contentItemDroppableId} droppableId={droppableId} />
      ),
    });

    wholeState[mainIndex] = mainStateSlice;

    setState((ps) => {
      return { ...ps, contentData: wholeState };
    });
  };

  const removeNewTextContentSlotHandler = (droppableId: string, contentItemDroppableId: string) => {
    const wholeState = [...state.contentData] as LocalContentState[];

    const mainStateSlice = {
      ...wholeState.find((v) => v.droppableId === droppableId),
    } as LocalContentState;

    const subStateSlice = {
      ...mainStateSlice.contentItems.find(
        (v) => v.contentItemDroppableId === contentItemDroppableId
      ),
    } as {
      textContent: Item;
      mediaType: CONTENT_MEDIA_TYPE;
      contentItemDroppableId: string;
      component: any;
    };

    const mainIndex = state.contentData
      ?.map((v) => v.droppableId)
      .indexOf(mainStateSlice.droppableId);

    const subIndex = mainStateSlice?.contentItems
      .map((v) => v.contentItemDroppableId)
      .indexOf(subStateSlice.contentItemDroppableId);

    wholeState[mainIndex].contentItems.splice(subIndex, 1);

    setState((ps) => {
      return { ...ps, contentData: wholeState };
    });
  };

  const removeNewLinkSlotHandler = (droppableId: string, contentItemDroppableId: string) => {
    const wholeState = [...state.contentData] as LocalContentState[];

    const mainStateSlice = {
      ...state.contentData.find((v) => v.droppableId === droppableId),
    } as LocalContentState;

    const subStateSlice = {
      ...mainStateSlice?.contentItems.find(
        (val) => val.contentItemDroppableId === contentItemDroppableId
      ),
    } as {
      links: Links;
      mediaType: CONTENT_MEDIA_TYPE;
      contentItemDroppableId: string;
      component: any;
    };

    const mainIndex = state.contentData
      ?.map((v) => v.droppableId)
      .indexOf(mainStateSlice.droppableId);

    const subIndex = mainStateSlice?.contentItems
      .map((v) => v.contentItemDroppableId)
      .indexOf(subStateSlice.contentItemDroppableId);

    wholeState[mainIndex].contentItems.splice(subIndex, 1);

    setState((ps) => {
      return { ...ps, contentData: wholeState };
    });
  };

  const removeNewSubLinkSlotHandler = (
    droppableId: string,
    contentItemDroppableId: string,
    index: number
  ) => {
    const wholeState = [...state.contentData] as LocalContentState[];

    const mainStateSlice = {
      ...state.contentData.find((v) => v.droppableId === droppableId),
    } as LocalContentState;

    const subStateSlice = {
      ...mainStateSlice?.contentItems.find(
        (val) => val.contentItemDroppableId === contentItemDroppableId
      ),
    } as {
      links: Links;
      mediaType: CONTENT_MEDIA_TYPE;
      contentItemDroppableId: string;
      component: any;
    };

    const mainIndex = state.contentData
      ?.map((val) => val.droppableId)
      .indexOf(mainStateSlice.droppableId);

    const subIndex = mainStateSlice?.contentItems
      ?.map((val) => val.contentItemDroppableId)
      .indexOf(subStateSlice.contentItemDroppableId);

    const v = wholeState[mainIndex].contentItems[subIndex] as {
      links: Links;
      mediaType: CONTENT_MEDIA_TYPE;
      contentItemDroppableId: string;
      component: any;
    };

    v.links.linkGroups.splice(index, 1);

    if (index > 0) {
      setState((ps) => {
        return { ...ps, contentData: wholeState };
      });
    }
  };

  const removeNewExtraMaterialsSlotHandler = (
    droppableId: string,
    contentItemDroppableId: string
  ) => {
    const wholeState = [...state.contentData] as LocalContentState[];

    const mainStateSlice = {
      ...state.contentData.find((v) => v.droppableId === droppableId),
    } as LocalContentState;

    const subStateSlice = {
      ...mainStateSlice?.contentItems.find(
        (val) => val.contentItemDroppableId === contentItemDroppableId
      ),
    } as {
      materialUrls: Item[];
      mediaType: CONTENT_MEDIA_TYPE;
      contentItemDroppableId: string;
      component: any;
    };

    const mainIndex = state.contentData
      ?.map((val) => val.droppableId)
      .indexOf(mainStateSlice.droppableId);

    const subIndex = mainStateSlice?.contentItems
      ?.map((val) => val.contentItemDroppableId)
      .indexOf(subStateSlice.contentItemDroppableId);

    wholeState[mainIndex].contentItems.splice(subIndex, 1);

    setState((ps) => {
      return { ...ps, contentData: wholeState };
    });
  };

  const removeNewVideoSlotHandler = (droppableId: string, contentItemDroppableId: string) => {
    const wholeState = [...state.contentData] as LocalContentState[];

    const mainStateSlice = {
      ...state.contentData.find((v) => v.droppableId === droppableId),
    } as LocalContentState;

    const subStateSlice = {
      ...mainStateSlice?.contentItems.find(
        (val) => val.contentItemDroppableId === contentItemDroppableId
      ),
    } as {
      videoUrl: Item;
      mediaType: CONTENT_MEDIA_TYPE;
      contentItemDroppableId: string;
      component: any;
    };

    const mainIndex = state.contentData
      ?.map((v) => v.droppableId)
      .indexOf(mainStateSlice.droppableId);

    const subIndex = mainStateSlice?.contentItems
      .map((v) => v.contentItemDroppableId)
      .indexOf(subStateSlice.contentItemDroppableId);

    wholeState[mainIndex].contentItems.splice(subIndex, 1);

    setState((ps) => {
      return { ...ps, contentData: wholeState };
    });
  };

  const cleanLastSlot = () => {
    const wholeState = [...state.contentData] as LocalContentState[];

    wholeState.pop();

    setState((ps) => {
      return { ...ps, contentData: wholeState };
    });
  };

  const setLoading = (val: boolean) => {
    setState((ps) => ({
      ...ps,
      loading: val,
    }));
  };

  const removeChapter = (droppableId: string) => {
    const wholeState = [...state.contentData] as LocalContentState[];

    const mainStateSlice = {
      ...state.contentData.find((v) => v.droppableId === droppableId),
    } as LocalContentState;

    const mainIndex = state.contentData
      ?.map((v) => v.droppableId)
      .indexOf(mainStateSlice.droppableId);

    wholeState.splice(mainIndex, 1);

    setState((ps) => {
      return { ...ps, contentData: wholeState };
    });
  };

  const setDroppableId = (droppableId: string) => {
    setState((ps) => {
      return { ...ps, droppableId: droppableId };
    });
  };

  const setPreviousPathToPreview = (url: string) => {
    setState((ps) => {
      return { ...ps, previousPathToPreview: url };
    });
  };

  const textContentHandler = (
    droppableId: string,
    contentItemDroppableId: string,
    inputText: string
  ) => {
    const textData = state.contentData
      .find((val) => val.droppableId === droppableId)
      ?.contentItems.find((val) => val.contentItemDroppableId === contentItemDroppableId) as {
      textContent: Item;
      mediaType: CONTENT_MEDIA_TYPE;
      contentItemDroppableId: string;
      component: any;
    };

    textData.textContent.value = inputText;

    const idx = state.contentData
      .find((val) => val.droppableId === droppableId)
      ?.contentItems.indexOf(textData)!;

    const stateCopy = [
      ...state.contentData.find((val) => val.droppableId === droppableId)?.contentItems!,
    ];

    stateCopy[idx] = textData;

    setState((ps: any) => ({ ...ps, contentItems: stateCopy }));
  };

  const resetTextContentErrorHandler = (droppableId: string, contentItemDroppableId: string) => {
    const textData = state.contentData
      .find((val) => val.droppableId === droppableId)
      ?.contentItems.find((val) => val.contentItemDroppableId === contentItemDroppableId) as {
      textContent: Item;
      mediaType: CONTENT_MEDIA_TYPE;
      contentItemDroppableId: string;
      component: any;
    };

    textData.textContent.error = '';

    const idx = state.contentData
      .find((val) => val.droppableId === droppableId)
      ?.contentItems.indexOf(textData)!;

    const stateCopy = [
      ...state.contentData.find((val) => val.droppableId === droppableId)?.contentItems!,
    ];

    stateCopy[idx] = textData;

    setState((ps: any) => ({ ...ps, contentItems: stateCopy }));
  };

  const resetVideoContentErrorHandler = (droppableId: string, contentItemDroppableId: string) => {
    const contentItems = state.contentData.find(
      (val) => val.droppableId === droppableId
    )?.contentItems;

    const videoContent = contentItems?.find(
      (val) => val.contentItemDroppableId === contentItemDroppableId
    ) as {
      videoUrl: Item;
      mediaType: CONTENT_MEDIA_TYPE;
      contentItemDroppableId: string;
      component: any;
    };

    videoContent.videoUrl.error = '';

    const idx = contentItems
      ?.map((v) => v.contentItemDroppableId)
      ?.indexOf(videoContent.contentItemDroppableId);

    const stateCopy = [...contentItems!];

    if (idx) {
      stateCopy[idx] = videoContent;
    }

    setState((ps: any) => ({ ...ps, contentItems: stateCopy }));
  };

  const validateData = (contentData: LocalContentState[]): any => {
    const cData = [...contentData];

    let isDataValid = true;

    for (let i = 0; i < cData.length; i++) {
      for (let j = 0; j < cData[i].contentItems.length; j++) {
        cData[i].contentItems.forEach((v) => {
          if (v.mediaType === CONTENT_MEDIA_TYPE.LINKS) {
            const item = v as {
              links: Links;
              mediaType: CONTENT_MEDIA_TYPE;
              contentItemDroppableId: string;
              component: any;
            };

            for (let k = 0; k < item.links.linkGroups.length; k++) {
              const refTxt = item.links.linkGroups[k].ref.value;
              const urlTxt = item.links.linkGroups[k].url.value;

              item.links.linkGroups[k].ref.error = validateRef(refTxt);
              item.links.linkGroups[k].url.error = validateUrl(urlTxt);
            }
          } else if (v.mediaType === CONTENT_MEDIA_TYPE.MATERIALS) {
            const item = v as {
              materialUrls: Item[];
              mediaType: CONTENT_MEDIA_TYPE;
              contentItemDroppableId: string;
              component: any;
              error?: string;
            };

            if (!item.materialUrls.length) {
              item.error = 'Materials are empty';
            }

            // for (let k = 0; k < item.materialUrls.length; k++) {
            //   const val = item.materialUrls[k].value;
            //   item.materialUrls[k].error = validateMaterialUrl(val);

            //   if (isDataValid) {
            //     isDataValid = !validateMaterialUrl(val);
            //   }
            // }
          } else if (v.mediaType === CONTENT_MEDIA_TYPE.TEXT) {
            const item = v as {
              textContent: Item;
              mediaType: CONTENT_MEDIA_TYPE;
              contentItemDroppableId: string;
              component: any;
            };

            const val = item.textContent.value.replace(/(<([^>]+)>)/gi, '').replace(/&nbsp;/g, '');

            item.textContent.error = validateText(val);
          } else if (v.mediaType === CONTENT_MEDIA_TYPE.VIDEO) {
            const item = v as {
              videoUrl: Item;
              mediaType: CONTENT_MEDIA_TYPE;
              contentItemDroppableId: string;
              component: any;
            };

            const val = item.videoUrl.value;

            item.videoUrl.error = validateVideoUrl(val);
          } else {
          }
        });
      }

      cData[i].contentTitle.error = validateContentTitle(cData[i].contentTitle);
      if (isDataValid) {
        isDataValid = !validateContentTitle(cData[i].contentTitle);
      }
    }

    let contentArray: number = 0;

    cData.forEach((top) =>
      top.contentItems.forEach((bottom) => {
        if (bottom.mediaType === CONTENT_MEDIA_TYPE.LINKS) {
          const v = bottom as {
            links: Links;
            mediaType: CONTENT_MEDIA_TYPE;
            contentItemDroppableId: string;
            component: any;
          };
          v.links.linkGroups.forEach((i) => {
            if (isDataValid && i.ref.error) {
              isDataValid = false;
            }
            if (isDataValid && i.url.error) {
              isDataValid = false;
            }
          });
        } else if (bottom.mediaType === CONTENT_MEDIA_TYPE.MATERIALS) {
          const v = bottom as {
            materialUrls: Item[];
            mediaType: CONTENT_MEDIA_TYPE;
            contentItemDroppableId: string;
            component: any;
            error?: string;
          };
          if (isDataValid && v.error) {
            isDataValid = false;
          }
        } else if (bottom.mediaType === CONTENT_MEDIA_TYPE.TEXT) {
          const v = bottom as {
            textContent: Item;
            mediaType: CONTENT_MEDIA_TYPE;
            contentItemDroppableId: string;
            component: any;
          };

          if (isDataValid && v.textContent.error) {
            isDataValid = false;
          }
          contentArray++;
        } else if (bottom.mediaType === CONTENT_MEDIA_TYPE.VIDEO) {
          const v = bottom as {
            videoUrl: Item;
            mediaType: CONTENT_MEDIA_TYPE;
            contentItemDroppableId: string;
            component: any;
          };

          if (isDataValid && v.videoUrl.error) {
            isDataValid = false;
          }
          contentArray++;
        } else {
        }
      })
    );

    return {
      cData,
      isDataValid,
      contentError: contentArray === 0 ? 'There should be at least a text or a video content' : '',
    };
  };

  // const resetTextError = (droppableId:string) =>{
  //   const idx = appContent.contentData.find((val) => val.droppableId === props.droppableId)?.contentItems.indexOf(textData)!
  // }

  const setMainDroppableId = (droppableId: string) => {
    setState((ps) => {
      return { ...ps, mainDroppableId: droppableId };
    });
  };

  const setIsLastChapter = (value: boolean) => {
    setState((ps) => {
      return { ...ps, isLastChapter: value };
    });
  };

  const chapterMarkedAsReadLoadingHandler = (droppableId: string) => {
    setState((ps) => {
      return { ...ps, chapterMarkedAsReadLoading: droppableId };
    });
  };

  return (
    <Context.Provider
      value={{
        ...state,
        setList,
        setInitData,
        replaceOrAddContentDataByDroppableId,
        setState,
        setInitialState,
        setTinyMCEChangedTimeSnap,
        changeContentTitleHandler,
        addNewTextContentSlotHandler,
        addNewLinkSlotHandler,
        addNewSubLinkSlotHandler,
        addNewExtraMaterialsSlotHandler,
        addNewVideoSlotHandler,
        removeNewTextContentSlotHandler,
        removeNewLinkSlotHandler,
        removeNewSubLinkSlotHandler,
        removeNewExtraMaterialsSlotHandler,
        removeNewVideoSlotHandler,
        setContentData,
        changeLinkContentHandler,
        textContentHandler,
        extraMaterialHandler,
        removeExtraMaterialItem,
        setLoading,
        cleanLastSlot,
        removeChapter,
        setDroppableId,
        setPreviousPathToPreview,
        validateData,
        resetContentTitleErrorHandler,
        resetTextContentErrorHandler,
        resetVideoContentErrorHandler,
        resetErrorLinkContentHandler,
        resetMaterialHandler,
        setMainDroppableId,
        inPreviewChapterReadCheckHandler,
        setIsLastChapter,
        chapterReadCheckUidsHandler,
        chapterMarkedAsReadLoadingHandler,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useAppContent = (): ContentContext => useContext(Context);
