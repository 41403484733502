import React, { FC, Fragment, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useHistory, useParams } from 'react-router-dom';
import { useFunctions } from 'reactfire';
import AlertPopup from '../../../../components/AlertPopup';
import { useLessons } from '../../../../context/LessonsContext';
import ContentTextBox from '../../components/ContentTextBox';
import { useAppContent } from '../../context/ContentContext';
import { LocalContentState } from '../../interfaces/LocalContentState';
import RoutingPaths from '../../routes/RoutingPaths';
import AdminAndInstructorContentServices from '../../services/AdminAndInstructorContentServices';
import ErrorBoundary from '../../utils/ErrorBoundary';
import Notification from './../../../../components/Notification';
import './UpdateContentScreen.scss';

interface Props {
  subjectId: string;
  lessonId: string;
  unitId?: string;
  droppableId: string;
  contentId?: string;
  setContentIdHandler: (data: string) => void;
}
const UpdateContentScreen: FC<Props> = (props) => {
  const createContentRef = useFunctions().httpsCallable('createContent');

  const params = useParams() as {
    subjectId: string;
    lessonId: string;
    unitId: string;
    droppableId: string;
  };

  const appContent = useAppContent();

  const [error, setError] = useState<string>('');
  const [submitError, setSubmitError] = useState<string>('');

  const history = useHistory();

  const stateSlice = appContent.contentData.find((val) => val.droppableId === params.droppableId)!;

  useEffect(() => {
    if (!stateSlice) {
      history.push(
        `/my-subjects/subject/${params.subjectId}/lesson/${params.lessonId}/unit/${params.unitId}/Content`
      );
    }

    appContent.setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLessonContainsUnits = useLessons()?.lessons.find(
    (val) => val.id === props.lessonId
  )?.isLessonContainsUnits;

  function handleOnDragEnd(result: DropResult) {
    if (!result.destination) return;

    const items = Array.from(stateSlice.contentItems as any);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    appContent.setContentData(params.droppableId, items);
    appContent.setTinyMCEChangedTimeSnap();
  }

  const onFinishHander = (val: any) => {
    if (val.data.status === 400) {
      appContent.setLoading(false);
      return setSubmitError(val.data.data);
    }
    appContent.setLoading(false);
    Notification({
      isSuccess: true,
      message: `Content updated successfully`,
    });
    history.push(new RoutingPaths(props.subjectId, props.lessonId, props.unitId).baseURL);
  };

  const onProgressHandler = () => {
    appContent.setLoading(true);
  };

  return stateSlice ? (
    <Fragment>
      <div className="update-content">
        <ContentTextBox
          style={{ width: '100%' }}
          className="fn-margin"
          stateName="contentTitle"
          stateValue={stateSlice.contentTitle.value || ''}
          error={stateSlice.contentTitle.error}
          placeHolder="Content Title"
          onChangeHandler={(e: any) => {
            appContent.changeContentTitleHandler(params.droppableId, e.target.value);
          }}
          resetErrorHandler={() => {
            appContent.resetContentTitleErrorHandler(params.droppableId);
          }}
          compulsory={true}
        />

        <ErrorBoundary subjectId={props.subjectId}>
          {stateSlice.contentItems.length > 0 ? (
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="publishTopicNames">
                {(provided) => (
                  <ul className="characters" {...provided.droppableProps} ref={provided.innerRef}>
                    {stateSlice.contentItems.map((listItem: any, index: any) => {
                      return (
                        <Draggable
                          key={listItem.contentItemDroppableId}
                          draggableId={listItem.contentItemDroppableId}
                          index={index}
                        >
                          {(provided) => (
                            <li
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              {listItem.component}
                            </li>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <></>
          )}
        </ErrorBoundary>
        <div className="update-content__btns mt-4">
          <button
            className="update-content__save-btn"
            onClick={(e) => {
              const sliceData = appContent.validateData([{ ...stateSlice }]) as {
                cData: LocalContentState[];
                isDataValid: boolean;
                contentError: string;
              };

              const index = appContent.contentData
                .map((v) => v.droppableId)
                .indexOf(stateSlice?.droppableId!);

              const cDataToUpdate = [...appContent.contentData];

              cDataToUpdate[index] = sliceData.cData[0];

              if (sliceData.contentError) {
                return setError(sliceData.contentError);
              }

              if (!sliceData.isDataValid) {
                return (
                  appContent.setState &&
                  appContent.setState((ps) => {
                    return { ...ps, contentData: cDataToUpdate };
                  })
                );
              }

              for (let i = 0; i < appContent.contentData.length; i++) {
                for (let j = 0; j < appContent.contentData[i].contentItems.length; j++) {
                  appContent.contentData[i].contentItems.forEach((v) => {
                    v.component = null;
                  });
                }
              }

              const data = {
                content: {
                  contentData: appContent.contentData,
                  subjectId: props.subjectId,
                  lessonId: props.lessonId,
                  unitId: props.unitId || 'noUnit',
                },
                id: props.contentId,
              };

              new AdminAndInstructorContentServices(createContentRef).createContent(
                data,
                onProgressHandler,
                onFinishHander
              );

              e.preventDefault();
            }}
          >
            Update and Publish
          </button>
          {/* <button
          className="update-content__draft-btn update-content__draft-btn--disabled"
          onClick={() => {}}
        >
          Update Draft
        </button> */}
          <div
            className="update-content__cancel"
            onClick={() => {
              if (isLessonContainsUnits) {
                history.push(
                  `/my-subjects/subject/${props.subjectId}/lesson/${props.lessonId}/unit/${props.unitId}/Content`
                );
              } else {
                history.push(
                  `/my-subjects/subject/${props.subjectId}/lesson/${props.lessonId}/Content`
                );
              }
            }}
          >
            Cancel
          </div>
        </div>
      </div>

      <AlertPopup
        message={error}
        header="Attention"
        isShow={!!error}
        type="NO_BUTTON"
        onClose={() => {
          //Close the popup
          setError('');
        }}
      />
      <AlertPopup
        message={submitError}
        header="Attention"
        isShow={!!submitError}
        onOk={() => {
          setSubmitError('');
        }}
        onClose={() => {
          setSubmitError('');
        }}
      />
    </Fragment>
  ) : (
    <></>
  );
};

export default UpdateContentScreen;
