import React, { FC, Fragment } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Backarrow from '../../../components/Backarrow';
import { CONTENT_SECONDARY_NAV_MAIN_ITEMS } from '../../../constants';
import { useLessons } from '../../../context/LessonsContext';
import { useUnits } from '../../../context/UnitsContext';
import { useAppContent } from '../context/ContentContext';
import './PreviewChapterSecondaryNav.scss';

interface Props {
  subjectId: string;
  lessonId: string;
  unitId?: string;
}

const PreviewChapterSecondaryNav: FC<Props> = (props) => {
  const appContent = useAppContent();

  // appContent.contentData.find((val) => val.droppableId === )

  const { CONTENT } = CONTENT_SECONDARY_NAV_MAIN_ITEMS;
  const navItems = [CONTENT];

  const subNavItems = appContent.contentData;

  const appLessons = useLessons();
  const appUnits = useUnits();
  const history = useHistory();

  const { lessonId, unitId } = useParams() as {
    subjectId: string;
    lessonId: string;
    unitId: string;
  };

  const lastSegment = decodeURI(history.location.pathname.split('/').pop() || '');

  const unitName = appUnits.units.find((u) => u.id === unitId)?.name;
  const lessonName = appLessons.lessons.find((l) => l.id === lessonId)?.name;

  return (
    <aside className="content-snav">
      <header className="content-snav__header">
        <Backarrow
          onClick={() => {
            history.push(appContent.previousPathToPreview);
          }}
        />
        <h6>{unitName || lessonName}</h6>
      </header>
      <ul className="content-snav__items">
        {navItems.map((item, idx) => (
          <Fragment>
            <li
              className={`content-snav__item  ${
                lastSegment === item && 'content-snav__item--active'
              } ${item === 'Content' && lastSegment === 'Create' && 'content-snav__item--active'} ${
                history.location.pathname.includes(item) && 'content-snav__item--active'
              } `}
              onClick={() => {}}
              key={idx}
            >
              {item}
            </li>

            <ul className="content-snav__sublist d-block ">
              {subNavItems?.map((val, index) => {
                return (
                  <li
                    className={`content-snav__sublist-item ${
                      val.droppableId === appContent.droppableId
                        ? 'content-snav__sublist-item--active'
                        : ''
                    }`}
                  >
                    {index + 1 + '. ' + val.contentTitle.value}
                  </li>
                );
              })}
            </ul>
          </Fragment>
        ))}
      </ul>
    </aside>
  );
};

export default PreviewChapterSecondaryNav;
