import { FC, useEffect, useState } from 'react';
import {
  FaEdit,
  FaExclamation,
  FaFilter,
  FaPlus,
  FaSortAmountDown,
  FaSortAmountDownAlt,
  FaTimes,
  FaTrashAlt,
} from 'react-icons/fa';
import { useFirestore, useFunctions } from 'reactfire';
import './AssignSubjects.scss';
import truncate from 'truncate';
import { ManageUsersState } from './SettingsManageUsersBody';
import { useAppUser } from '../context/UserContext';
import { Subject } from '../models/Subject';
import { useLabels } from '../context/LabelContext';
import { UserFirestore } from '../models/UserFirestore';
import { Label } from '../models/Label';
import {
  sortByCreatedAt,
  sortUsersFromUserName,
  sortUsersFromUserNameDesc,
  sortUsersFromUserRole,
  sortUsersFromUserRoleDESC,
  sortUsersFromUserStatus,
} from '../utils';
import SearchBox from './SearchBox';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import BoxIcon from './BoxIcon';
import AssignSubjectsModal from './AssignSubjectsModal';
import AddLabelModal from './AddLabelModal';
import AssignSubjectsCardsList from './AssignSubjectsCardsList';
import UserRoasterFilterPopup from './UserRoasterFilterPopup';
import Notification from './../components/Notification';
import { useSubjects } from '../context/SubjectsContext';
import UserRosterPackagesModal from './UserRosterPackagesModal';
import AddNewUserModal from './AddNewUserModal';
import AssignedSubjectsStudentCard from './AssignedSubjectsStudentCard';
import PaginationNew from './PaginationNew';

interface Props {
  assignedUsers?: string[];
  enrolledStudents?: string[];
  subjectId: string;
  loading: boolean;
  setIsLoading: (value: boolean) => void;
}

const initialPagination = {
  total_pages: 0,
  page_number: 0,
};

const ITEM_COUNT_IN_PAGE: number = 10;

const AssignSubjects: FC<Props> = (props) => {
  const initialState: ManageUsersState = {
    searchText: '',
    loading: false,
    role: null,
    enabled: null,
    page: 0,
    totalPages: 0,
    filtered: false,
    users: [],
  };

  const [stateB, setStateB] = useState<ManageUsersState>(initialState);
  const [pagination, setPagination] = useState(initialPagination);

  console.log('HASITH AssignSubjects setStateB ', setStateB);

  //hooks
  const appUser = useAppUser();

  const appSubjects = useSubjects();

  const firestore = useFirestore();

  const [appSubject, setAppSubject] = useState<Subject | null>(null);

  const appLabels = useLabels();

  const subjectCreatorUID = appSubject?.createdBy;

  const deleteLabelRef = useFunctions().httpsCallable('deleteLabel');

  //state
  const [state, setState] = useState<{
    initAssignedInstructorsAndTAs: UserFirestore[];
    initRemainingInstructorsAndTAs: UserFirestore[];
    initSubjectAssignedStudents: UserFirestore[];
    assignedInstructorsAndTAs: UserFirestore[];
    remainingInstructorsAndTAs: UserFirestore[];
    initAllUsers: UserFirestore[];
    subjectAssignedInstructors: UserFirestore[];
    remainingInstructors: UserFirestore[];
    subjectAssignedTeachingAssistants: UserFirestore[];
    remainingTeachingAssistants: UserFirestore[];
    subjectAssignedStudents: UserFirestore[];
    showInstructorModal: boolean;
    showTeachingAssistantModal: boolean;
    listOfLabels: Label[];
  }>({
    initAssignedInstructorsAndTAs: [],
    initRemainingInstructorsAndTAs: [],
    initSubjectAssignedStudents: [],
    assignedInstructorsAndTAs: [],
    remainingInstructorsAndTAs: [],
    initAllUsers: [],
    subjectAssignedInstructors: [],
    remainingInstructors: [],
    subjectAssignedTeachingAssistants: [],
    remainingTeachingAssistants: [],
    subjectAssignedStudents: [],
    showInstructorModal: false,
    showTeachingAssistantModal: false,
    listOfLabels: [],
  });

  const [allUsers, setAllUsers] = useState<UserFirestore[]>([]);

  const [user, setUser] = useState();
  const [usersNameSorted, setUsersNameSorted] = useState<boolean>(true);
  const [usersRoleSorted, setUsersRoleSorted] = useState<boolean>(true);
  const [isClearSearch, setIsClearSearch] = useState<boolean>(false);
  const [userStatusSorted, setUserStatusSorted] = useState<boolean>(true);
  const [show, setShow] = useState(false);

  const clearSearchHandler = (value: boolean) => {
    setIsClearSearch(value);
  };

  //state maninulating methods
  const openInstructorModal = () => {
    setState((ps) => ({ ...ps, showInstructorModal: true, showTeachingAssistantModal: false }));
  };

  const closeInstructorModal = () => {
    setState((ps) => ({ ...ps, showInstructorModal: false }));
  };

  const openTeachingAssistantModal = () => {
    setState((ps) => ({ ...ps, showInstructorModal: false, showTeachingAssistantModal: true }));
  };

  const closeTeachingAssistantModal = () => {
    setState((ps) => ({ ...ps, showTeachingAssistantModal: false }));
  };

  const setUsers = (
    subjectAssignedInstructors: UserFirestore[],
    remainingInstructors: UserFirestore[],
    subjectAssignedTeachingAssistants: UserFirestore[],
    remainingTeachingAssistants: UserFirestore[],
    subjectAssignedStudents: UserFirestore[]
  ) => {
    setState((ps) => ({
      ...ps,
      subjectAssignedInstructors: subjectAssignedInstructors,
      remainingInstructors: remainingInstructors,
      subjectAssignedTeachingAssistants: subjectAssignedTeachingAssistants,
      remainingTeachingAssistants: remainingTeachingAssistants,
      subjectAssignedStudents: subjectAssignedStudents,
    }));
  };

  const addAssistantToSubjectHandler = (id: string) => {
    let unSortedRemainingTAs = [...state.remainingTeachingAssistants];

    const unSortedSubjectAssignedTAs = [...state.subjectAssignedTeachingAssistants];

    const copiedSubjects = [...appSubjects.allSubjects];

    const idx = copiedSubjects.map((s) => s.id).indexOf(props.subjectId);

    const sub = copiedSubjects.find((s) => s.id === props.subjectId);
    sub?.assignedUsers?.push(id);

    if (sub) {
      copiedSubjects[idx] = sub;
    }

    appSubjects.setSubjects(copiedSubjects);

    const user = unSortedRemainingTAs.find((value) => value.uid === id)!;

    unSortedRemainingTAs = unSortedRemainingTAs.filter((value) => value.uid !== id);

    unSortedSubjectAssignedTAs.push(user);

    setState((ps) => ({
      ...ps,
      subjectAssignedTeachingAssistants: sortUsersFromUserName(unSortedSubjectAssignedTAs),
      remainingTeachingAssistants: sortUsersFromUserName(unSortedRemainingTAs),
      showInstructorModal: false,
      showTeachingAssistantModal: false,
    }));
  };

  const removeAssistantFromSubjectHandler = (id: string) => {
    const unSortedRemainingTAs = [...state.remainingTeachingAssistants];

    let unSortedSubjectAssignedTAs = [...state.subjectAssignedTeachingAssistants];

    const copiedSubjects = [...appSubjects.allSubjects];

    const idx = copiedSubjects.map((s) => s.id).indexOf(props.subjectId);

    const sub = copiedSubjects.find((s) => s.id === props.subjectId);
    if (sub?.assignedUsers) {
      sub.assignedUsers = sub?.assignedUsers?.filter((aU) => aU !== id);
    }

    if (sub) {
      copiedSubjects[idx] = sub;
    }

    appSubjects.setSubjects(copiedSubjects);

    const user = unSortedSubjectAssignedTAs.find((value) => value.uid === id)!;

    unSortedSubjectAssignedTAs = unSortedSubjectAssignedTAs.filter((value) => value.uid !== id);
    unSortedRemainingTAs.push(user);

    const currentUsers = [...allUsers];
    const removedUserArray = currentUsers.filter((user) => user.uid !== id);
    setAllUsers(removedUserArray);

    setState((ps) => ({
      ...ps,
      subjectAssignedTeachingAssistants: sortUsersFromUserName(unSortedSubjectAssignedTAs),
      remainingTeachingAssistants: sortUsersFromUserName(unSortedRemainingTAs),
      showInstructorModal: false,
      showTeachingAssistantModal: false,
    }));
  };

  const addInstructorToSubjectHandler = (id: string) => {
    let unSortedRemainingInstructors = [...state.remainingInstructors];

    const unSortedSubjectAssignedInstructors = [...state.subjectAssignedInstructors];

    const copiedSubjects = [...appSubjects.allSubjects];

    const idx = copiedSubjects.map((s) => s.id).indexOf(props.subjectId);

    const sub = copiedSubjects.find((s) => s.id === props.subjectId);
    sub?.assignedUsers?.push(id);

    if (sub) {
      copiedSubjects[idx] = sub;
    }

    appSubjects.setSubjects(copiedSubjects);

    const user = unSortedRemainingInstructors.find((value) => value.uid === id)!;

    unSortedRemainingInstructors = unSortedRemainingInstructors.filter((value) => value.uid !== id);

    unSortedSubjectAssignedInstructors.push(user);

    setState((ps) => ({
      ...ps,
      subjectAssignedInstructors: sortUsersFromUserName(unSortedSubjectAssignedInstructors),
      remainingInstructors: sortUsersFromUserName(unSortedRemainingInstructors),
      showInstructorModal: false,
      showTeachingAssistantModal: false,
    }));
  };

  const removeInstructorFromSubjectHandler = (id: string) => {
    const unSortedRemainingInstructors = [...state.remainingInstructors];

    let unSortedSubjectAssignedInstructors = [...state.subjectAssignedInstructors];

    const copiedSubjects = [...appSubjects.allSubjects];

    const idx = copiedSubjects.map((s) => s.id).indexOf(props.subjectId);

    const sub = copiedSubjects.find((s) => s.id === props.subjectId);
    if (sub?.assignedUsers) {
      sub.assignedUsers = sub?.assignedUsers?.filter((aU) => aU !== id);
    }

    if (sub) {
      copiedSubjects[idx] = sub;
    }

    appSubjects.setSubjects(copiedSubjects);

    const user = unSortedSubjectAssignedInstructors.find((value) => value.uid === id)!;

    unSortedSubjectAssignedInstructors = unSortedSubjectAssignedInstructors.filter(
      (value) => value.uid !== id
    );
    unSortedRemainingInstructors.push(user);

    setState((ps) => ({
      ...ps,
      subjectAssignedInstructors: sortUsersFromUserName(unSortedSubjectAssignedInstructors),
      remainingInstructors: sortUsersFromUserName(unSortedRemainingInstructors),
      showInstructorModal: false,
      showTeachingAssistantModal: false,
    }));
  };

  const onAddNewUser = (id: string) => {
    let unsortedRemainingTAsAndInstructors = [...state.remainingInstructorsAndTAs];

    const unsortedSubjectAssignedTAsAndInstructors = [...state.assignedInstructorsAndTAs];

    const copiedSubjects = [...appSubjects.allSubjects];

    const idx = copiedSubjects.map((s) => s.id).indexOf(props.subjectId);

    const sub = copiedSubjects.find((s) => s.id === props.subjectId);
    sub?.assignedUsers?.push(id);

    if (sub) {
      copiedSubjects[idx] = sub;
    }

    appSubjects.setSubjects(copiedSubjects);

    const user = unsortedRemainingTAsAndInstructors.find((value) => value.uid === id)!;

    unsortedRemainingTAsAndInstructors = unsortedRemainingTAsAndInstructors.filter(
      (value) => value.uid !== id
    );

    unsortedSubjectAssignedTAsAndInstructors.push(user);

    setState((ps) => {
      return {
        ...ps,
        initAssignedInstructorsAndTAs: [
          ...sortUsersFromUserName(unsortedSubjectAssignedTAsAndInstructors),
        ],
        initRemainingInstructorsAndTAs: [
          ...sortUsersFromUserName(unsortedRemainingTAsAndInstructors),
        ],
        assignedInstructorsAndTAs: [
          ...sortUsersFromUserName(unsortedSubjectAssignedTAsAndInstructors),
        ],
        remainingInstructorsAndTAs: [...sortUsersFromUserName(unsortedRemainingTAsAndInstructors)],
      };
    });
  };

  const removeInstructorAndTAFromSubjectHandlelr = (id: string) => {
    const unsortedRemainingTAsAndInstructors = [...state.remainingInstructorsAndTAs];

    let unsortedSubjectAssignedTAsAndInstructors = [...state.assignedInstructorsAndTAs];

    const copiedSubjects = [...appSubjects.allSubjects];

    const idx = copiedSubjects.map((s) => s.id).indexOf(props.subjectId);

    const sub = copiedSubjects.find((s) => s.id === props.subjectId);
    if (sub?.assignedUsers) {
      sub.assignedUsers = sub?.assignedUsers?.filter((aU) => aU !== id);
    }

    if (sub) {
      copiedSubjects[idx] = sub;
    }

    appSubjects.setSubjects(copiedSubjects);

    const user = unsortedSubjectAssignedTAsAndInstructors.find((value) => value.uid === id)!;

    unsortedSubjectAssignedTAsAndInstructors = unsortedSubjectAssignedTAsAndInstructors.filter(
      (value) => value.uid !== id
    );

    unsortedRemainingTAsAndInstructors.push(user);

    setState((ps) => {
      return {
        ...ps,
        initAssignedInstructorsAndTAs: [
          ...sortUsersFromUserName(unsortedSubjectAssignedTAsAndInstructors),
        ],
        initRemainingInstructorsAndTAs: [
          ...sortUsersFromUserName(unsortedRemainingTAsAndInstructors),
        ],
        assignedInstructorsAndTAs: [
          ...sortUsersFromUserName(unsortedSubjectAssignedTAsAndInstructors),
        ],
        remainingInstructorsAndTAs: [...sortUsersFromUserName(unsortedRemainingTAsAndInstructors)],
      };
    });
  };

  const [open, setOpen] = useState(false);
  const removeAssignSubjectsFromInstructorAndTARef = useFunctions().httpsCallable(
    'removeAssignSubjectsFromInstructorAndTA'
  );

  const userRosterPackagesModalHandler = (value: boolean) => {
    setOpen(value);
  };

  const removeTAAndInstructor = (assignedSubject: string, user: UserFirestore) => {
    removeAssignSubjectsFromInstructorAndTARef({
      assignedSubject: assignedSubject,
      uid: user.uid,
    })
      .then((data) => {
        removeInstructorAndTAFromSubjectHandlelr(data.data.data);
        Notification({
          isSuccess: true,
          message: `Removed ${user.username} from the subject`,
          id: 'tid',
        });
        props.setIsLoading(false);
      })
      .catch(() => {
        props.setIsLoading(false);
        Notification({
          isSuccess: false,
          message: `Cannot remove user from the subject. ${user.username} is assign to the papers`,
          id: 'tid',
        });
      });
  };

  useEffect(() => {
    firestore
      .collection('labels')
      .where('subjectId', '==', props.subjectId)
      .get()
      .then((data) => {
        const label: Label[] = [];

        data.docs.forEach((item) => {
          label.push({ id: item.id, ...item.data() } as Label);
        });

        setState((ps) => ({
          ...ps,
          listOfLabels: label,
        }));
      })
      .catch((error) => {
        console.log('ERROR - ', error);
      });
  }, [props.subjectId, firestore]);

  useEffect(() => {
    props.setIsLoading(true);

    firestore
      .collection('subjects')
      .doc(props.subjectId)
      .get()
      .then((data) => {
        if (data.exists) {
          setAppSubject({ id: data.id, ...data.data() } as Subject);
        } else {
          setAppSubject(null);
        }

        props.setIsLoading(false);
      })
      .catch(() => {
        props.setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.subjectId]);

  const fetchUsers = () => {
    if (appSubject) {
      props.setIsLoading(true);

      firestore
        .collection('users')
        .orderBy('username')
        .get()
        .then((data) => {
          const users: UserFirestore[] = [];

          data.forEach((user) => {
            const storeUser = { id: user.id, ...user.data() } as UserFirestore;
            users.push(storeUser);
          });

          const subjectAssignedInstructors: UserFirestore[] = [];
          const remainingInstructors: UserFirestore[] = [];
          const subjectAssignedTeachingAssistants: UserFirestore[] = [];
          const remainingTeachingAssistants: UserFirestore[] = [];
          const subjectAssignedStudents: UserFirestore[] = [];

          [...users]
            .filter((u) => u.userRole?.isInstructor && u.uid !== subjectCreatorUID)
            .forEach((u) => {
              if (appSubject.assignedUsers?.includes(u.uid || '')) {
                subjectAssignedInstructors.push(u);
              } else {
                remainingInstructors.push(u);
              }
            });

          [...users]
            .filter((u) => u.userRole?.isTeachingAssistant)
            .forEach((u) => {
              if (appSubject.assignedUsers?.includes(u.uid || '')) {
                subjectAssignedTeachingAssistants.push(u);
              } else {
                remainingTeachingAssistants.push(u);
              }
            });

          [...users]
            .filter((u) => u.userRole?.isStudent)
            .forEach((u) => {
              if (appSubject.enrolledStudents?.includes(u.uid || '')) {
                subjectAssignedStudents.push(u);
              }
            });

          const assignedInstructorsAndTAs: UserFirestore[] = [
            ...subjectAssignedInstructors,
            ...subjectAssignedTeachingAssistants,
          ];

          setUsers(
            subjectAssignedInstructors,
            remainingInstructors,
            subjectAssignedTeachingAssistants,
            remainingTeachingAssistants,
            subjectAssignedStudents
          );

          const availableTAsAndInstructors: UserFirestore[] = [
            ...remainingInstructors,
            ...remainingTeachingAssistants,
          ];

          //Merge all users into one array
          const allUsers: UserFirestore[] = [
            ...subjectAssignedInstructors,
            ...subjectAssignedTeachingAssistants,
            ...subjectAssignedStudents,
          ];
          setState((ps) => {
            return {
              ...ps,
              initAssignedInstructorsAndTAs: assignedInstructorsAndTAs,
              initRemainingInstructorsAndTAs: availableTAsAndInstructors,
              assignedInstructorsAndTAs: assignedInstructorsAndTAs,
              remainingInstructorsAndTAs: availableTAsAndInstructors,
              initAllUsers: allUsers,
              initSubjectAssignedStudents: subjectAssignedStudents,
              subjectAssignedStudents: subjectAssignedStudents,
            };
          });
          setAllUsers(allUsers);
          props.setIsLoading(false);
        })
        .catch(() => {
          console.log('ERROR retriving users');
          props.setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appSubject]);

  const [labelState, setLabelState] = useState({ show: false, labelId: '' });

  const lName = appLabels.labelList.find((lab) => lab.id === labelState.labelId)?.labelName || '';

  const [selectedTab, setSelectedTab] = useState<'ASSIGNMENT' | 'LABELS' | 'ROASTER'>('ROASTER');
  const [selectedUserType, setSelectedUserType] = useState<'STUDENTS' | 'INSTRUCTORS-AND-TA'>(
    'STUDENTS'
  );

  const [addUserState, setAddUserState] = useState({ show: false });

  const addNewUserModalHandler = (value: boolean) => {
    setAddUserState((ps) => ({ ...ps, show: value }));
  };

  const onClickSortHandler = () => {
    setUsersNameSorted(!usersNameSorted);
    sortUsersNameHandler();
  };

  const sortUsersNameHandler = async () => {
    if (selectedUserType === 'INSTRUCTORS-AND-TA') {
      if (usersNameSorted) {
        setState((ps) => {
          return {
            ...ps,
            assignedInstructorsAndTAs: sortUsersFromUserName(state.assignedInstructorsAndTAs),
          };
        });
      } else {
        setState((ps) => {
          return {
            ...ps,
            assignedInstructorsAndTAs: sortUsersFromUserNameDesc(state.assignedInstructorsAndTAs),
          };
        });
      }
    } else {
      if (usersNameSorted) {
        setState((ps) => {
          return {
            ...ps,
            subjectAssignedStudents: sortUsersFromUserName(state.subjectAssignedStudents),
          };
        });
      } else {
        setState((ps) => {
          return {
            ...ps,
            subjectAssignedStudents: sortUsersFromUserNameDesc(state.subjectAssignedStudents),
          };
        });
      }
    }
  };

  const onClickUsersRoleSortHandler = () => {
    setUsersRoleSorted(!usersRoleSorted);
    if (usersRoleSorted) {
      setState((ps) => {
        return {
          ...ps,
          assignedInstructorsAndTAs: sortUsersFromUserRole(state.assignedInstructorsAndTAs),
        };
      });
    } else {
      setState((ps) => {
        return {
          ...ps,
          assignedInstructorsAndTAs: sortUsersFromUserRoleDESC(state.assignedInstructorsAndTAs),
        };
      });
    }
  };

  const userStatusHandler = () => {
    setUserStatusSorted(!userStatusSorted);

    setState((ps) => {
      return {
        ...ps,
        subjectAssignedStudents: sortUsersFromUserStatus(
          state.subjectAssignedStudents,
          userStatusSorted
        ),
      };
    });
  };

  const paginationHandler = () => {
    if (selectedUserType === 'STUDENTS') {
      if (state.subjectAssignedStudents.length % ITEM_COUNT_IN_PAGE === 0) {
        const totalPages = state.subjectAssignedStudents.length / ITEM_COUNT_IN_PAGE;
        setPagination((ps) => ({ ...ps, total_pages: totalPages }));
      } else {
        const totalPages =
          Math.floor(state.subjectAssignedStudents.length / ITEM_COUNT_IN_PAGE) + 1;
        setPagination((ps) => ({ ...ps, total_pages: totalPages }));
      }
    } else {
      if (state.assignedInstructorsAndTAs.length % ITEM_COUNT_IN_PAGE === 0) {
        const totalPages = state.assignedInstructorsAndTAs.length / ITEM_COUNT_IN_PAGE;
        setPagination((ps) => ({ ...ps, total_pages: totalPages }));
      } else {
        const totalPages =
          Math.floor(state.assignedInstructorsAndTAs.length / ITEM_COUNT_IN_PAGE) + 1;
        setPagination((ps) => ({ ...ps, total_pages: totalPages }));
      }
    }
  };

  const paginate = (array: any[], page_number: number, page_size: number): any[] =>
    array.slice(page_number * page_size, (page_number + 1) * page_size);

  useEffect(() => {
    sortUsersNameHandler();
    setUsersRoleSorted(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserType, usersNameSorted]);

  useEffect(() => {
    setState((ps) => {
      return {
        ...ps,
        subjectAssignedStudents: state.initSubjectAssignedStudents,
        assignedInstructorsAndTAs: state.initAssignedInstructorsAndTAs,
      };
    });

    setIsClearSearch(true);
    setShow(false);

    setStateB((ps) => {
      return {
        ...ps,
        enabled: null,
        role: null,
      };
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserType]);

  const searchUserHandler = () => {
    let searchResult: UserFirestore[] = [];
    let searchedUsersArray: UserFirestore[] = [];

    if (selectedUserType === 'STUDENTS') {
      searchResult = state.initSubjectAssignedStudents;
    } else {
      searchResult = [...state.initAssignedInstructorsAndTAs];
    }

    if (stateB.enabled === 'Active') {
      searchResult = searchResult.filter((user) => user.enabled === true);
    }

    if (stateB.enabled === 'Inactive') {
      searchResult = searchResult.filter((user) => user.enabled === false);
    }

    if (stateB.role === 'Instructor') {
      searchResult = searchResult.filter((user) => user.userRole?.isInstructor);
    }

    if (stateB.role === 'Teaching Assistant') {
      searchResult = searchResult.filter((user) => user.userRole?.isTeachingAssistant);
    }

    if (stateB.searchText.trim()) {
      searchedUsersArray = searchResult.filter((user) =>
        user.username.toUpperCase().startsWith(stateB.searchText.toUpperCase())
      );

      if (selectedUserType === 'STUDENTS') {
        setState((ps) => {
          return { ...ps, subjectAssignedStudents: sortUsersFromUserName(searchedUsersArray) };
        });
      } else {
        setState((ps) => {
          return { ...ps, assignedInstructorsAndTAs: sortUsersFromUserName(searchedUsersArray) };
        });
      }
    } else {
      if (selectedUserType === 'STUDENTS') {
        setState((ps) => {
          return { ...ps, subjectAssignedStudents: sortUsersFromUserName(searchResult) };
        });
      } else {
        setState((ps) => {
          return { ...ps, assignedInstructorsAndTAs: sortUsersFromUserName(searchResult) };
        });
      }
    }
  };

  //Search handler
  const searchTextHandler = (text: string) => {
    setStateB((ps) => ({
      ...ps,
      searchText: text,
    }));
  };

  useEffect(() => {
    searchUserHandler();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateB.searchText]);

  useEffect(() => {
    paginationHandler();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserType, state.subjectAssignedStudents, state.assignedInstructorsAndTAs]);

  const applyFiltersHandler = () => {
    searchUserHandler();
  };

  const clearFilterHandler = () => {
    setStateB((ps) => {
      return { ...ps, role: null, enabled: null, totalPages: stateB.totalPages };
    });
  };

  const openModalHandelr = () => {
    setShow(true);
  };

  const closeModalHander = () => {
    setShow(false);
  };

  const studentStatusHandler = (assignedStudents: any) => {
    setState((ps) => {
      return {
        ...ps,
        initSubjectAssignedStudents: sortUsersFromUserName(assignedStudents),
        subjectAssignedStudents: sortUsersFromUserName(assignedStudents),
      };
    });
  };

  return (
    <div className="settings">
      <div className="settings__top-tabs">
        <div
          className={`settings__roaster-tab ${
            selectedTab === 'ROASTER' ? 'settings-tab-active' : ''
          }`}
          onClick={() => {
            setSelectedTab('ROASTER');
          }}
        >
          User Roster
        </div>

        {/* <div
          className={`settings__assign-tutor-tab ${
            selectedTab === 'ASSIGNMENT' ? 'settings-tab-active' : ''
          }`}
          onClick={() => {
            setSelectedTab('ASSIGNMENT');
          }}
        >
          Assign Tutor
        </div> */}

        <div
          className={`settings__assign-label-tab ${
            selectedTab === 'LABELS' ? 'settings-tab-active' : ''
          }`}
          onClick={() => {
            setSelectedTab('LABELS');
          }}
        >
          Labels
        </div>
      </div>

      {selectedTab === 'ROASTER' ? (
        <div className="settings__user-tabs">
          <div
            className={`settings__student-tab ${
              selectedUserType === 'STUDENTS' ? `settings__student-tab__tab-active` : ''
            }`}
            onClick={() => {
              setSelectedUserType('STUDENTS');
              setUsersNameSorted(true);
            }}
          >
            Students
          </div>
          <div
            className={`settings__instructor-tab settings__instructor-tab__end-tab
          ${selectedUserType === 'INSTRUCTORS-AND-TA' ? `settings__instructor-tab__tab-active` : ''}
          `}
            onClick={() => {
              setSelectedUserType('INSTRUCTORS-AND-TA');
              setUsersNameSorted(true);
            }}
          >
            Instructors and TA
          </div>
        </div>
      ) : (
        ''
      )}

      <div className="settings__body">
        {selectedTab === 'ROASTER' ? (
          <div className="settings__roaster-body">
            <div className="settings__roaster-body__header">
              <div
                className="mt-5 mx-0"
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
              >
                <div
                  className="col-auto pl-0"
                  style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                >
                  <SearchBox
                    onSearch={(searchTerm) => {
                      searchTextHandler(searchTerm);
                    }}
                    placeHolderText="Search Users"
                    isClearSearch={isClearSearch}
                    clearSearchHandler={clearSearchHandler}
                  />
                  <div className="col-auto" style={{ marginLeft: '-20px' }}>
                    <button className="filter_button" onClick={openModalHandelr}>
                      Filters &nbsp;
                      <FaFilter />
                    </button>
                  </div>
                  <UserRoasterFilterPopup
                    show={show}
                    onCloseModal={closeModalHander}
                    setState={setStateB}
                    state={stateB}
                    initialState={initialState}
                    onApplyfilter={applyFiltersHandler}
                    tab={selectedUserType}
                    clearFilterHandler={clearFilterHandler}
                  />
                </div>

                <div>
                  <span
                    style={{
                      background: 'transparent',
                      display: 'flex',
                      alignItems: 'center',
                      width: '150px',
                      justifyContent: 'center',
                      border: 'none',
                      cursor: 'pointer',
                    }}
                  >
                    <span
                      className="ml-2"
                      style={{ fontSize: '16px', fontWeight: '500', color: '#246BFD' }}
                    >
                      {selectedUserType === 'STUDENTS' ? (
                        <div style={{ display: 'none' }}>Add Student</div>
                      ) : (
                        <div
                          onClick={() => {
                            addNewUserModalHandler(true);
                          }}
                        >
                          Add User
                        </div>
                      )}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div className="settings__roaster-body__content">
              <div>
                <div className="settings__roaster-body__content__header">
                  <div className="settings__roaster-body__content__header__username">
                    Username
                    <span style={{ marginLeft: '10px', cursor: 'pointer' }}>
                      {usersNameSorted ? (
                        <FaSortAmountDownAlt onClick={onClickSortHandler} />
                      ) : (
                        <FaSortAmountDown onClick={onClickSortHandler} />
                      )}
                    </span>
                  </div>
                  {selectedUserType === 'INSTRUCTORS-AND-TA' ? (
                    <div className="settings__roaster-body__content__header__role">
                      Role
                      <span style={{ marginLeft: '10px', cursor: 'pointer' }}>
                        {usersRoleSorted ? (
                          <FaSortAmountDownAlt onClick={onClickUsersRoleSortHandler} />
                        ) : (
                          <FaSortAmountDown onClick={onClickUsersRoleSortHandler} />
                        )}
                      </span>
                    </div>
                  ) : (
                    <div className="settings__roaster-body__content__header__packages">
                      Packages
                    </div>
                  )}

                  <div className="settings__roaster-body__content__header__status">
                    {selectedUserType === 'STUDENTS' ? (
                      <div>
                        Status
                        <span style={{ marginLeft: '10px', cursor: 'pointer' }}>
                          {userStatusSorted ? (
                            <FaSortAmountDownAlt onClick={() => userStatusHandler()} />
                          ) : (
                            <FaSortAmountDown onClick={() => userStatusHandler()} />
                          )}
                        </span>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>

              <div
                className="settings__roaster-body__content__body"
                style={{
                  overflowY: 'auto',
                  height: 'calc(100vh - 410px)',
                }}
              >
                {selectedUserType === 'STUDENTS' ? (
                  <>
                    {state.subjectAssignedStudents.length > 0
                      ? paginate(
                          state.subjectAssignedStudents,
                          pagination.page_number,
                          ITEM_COUNT_IN_PAGE
                        ).map((user: any) => (
                          <AssignedSubjectsStudentCard
                            user={user}
                            setUser={setUser}
                            userRosterPackagesModalHandler={userRosterPackagesModalHandler}
                            setIsLoading={props.setIsLoading}
                            loading={props.loading}
                            initSubjectAssignedStudents={state.initSubjectAssignedStudents}
                            studentStatusHandler={studentStatusHandler}
                          />
                        ))
                      : null}
                  </>
                ) : (
                  <>
                    {state.assignedInstructorsAndTAs.length > 0
                      ? paginate(
                          state.assignedInstructorsAndTAs,
                          pagination.page_number,
                          ITEM_COUNT_IN_PAGE
                        ).map((user: any) => (
                          <div className="settings__roaster-body__content__users" key={user.id}>
                            <div className="settings__roaster-body__content__users__name">
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="verifiedId">{user.username}</Tooltip>}
                              >
                                <div>{truncate(user.username || '', 35)}</div>
                              </OverlayTrigger>
                            </div>

                            <div className="settings__roaster-body__content__users__role">
                              {user.userRole?.isStudent
                                ? 'Student'
                                : user.userRole.isInstructor
                                ? 'Instructor'
                                : 'Teaching Assistant'}
                            </div>

                            <div className="settings__roaster-body__content__users__status">
                              Enrolled:{' '}
                              {user.enrolled || <span className="d-inline-block ml-3"> - </span>}
                              <span
                                style={{
                                  margin: '0 10px',
                                  marginLeft: '50px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  cursor: 'pointer',
                                }}
                              >
                                {user.userRole.isInstructor &&
                                appUser.firestoreUser?.userRole?.isInstructor ? (
                                  <span style={{ cursor: 'default' }}>
                                    <FaTimes style={{ visibility: 'hidden' }} size={17} />
                                  </span>
                                ) : (
                                  <FaTimes
                                    className="settings-roster-body-remove-icon"
                                    size={17}
                                    onClick={() => {
                                      props.setIsLoading(true);
                                      removeTAAndInstructor(props.subjectId, user);
                                    }}
                                  />
                                )}
                              </span>
                            </div>
                          </div>
                        ))
                      : null}
                  </>
                )}
              </div>

              <div>
                <PaginationNew
                  pages={pagination.total_pages}
                  setCurrentPage={(pageNumber) => {
                    setPagination({ ...pagination, page_number: pageNumber - 1 });
                  }}
                  clear={pagination.total_pages}
                />
              </div>
            </div>
          </div>
        ) : selectedTab === 'ASSIGNMENT' ? (
          <div className="assign-subjects d-flex">
            {appUser.firestoreUser?.userRole?.isAdmin ||
            appUser.firestoreUser?.userRole?.isInstructor ? (
              <div className="assign-subjects__instructor">
                <div className="assign-subjects__encap mr-auto">
                  <div className="assign-subjects__header d-flex justify-content-between">
                    <div className="assign-subjects__header-name text-white">
                      Teaching Assistant
                    </div>
                    <div
                      className="assign-subjects__link"
                      onClick={() => {
                        if (!props.loading) {
                          openTeachingAssistantModal();
                        }
                      }}
                    >
                      Add Assistant
                    </div>
                  </div>
                  <AssignSubjectsCardsList
                    users={state.subjectAssignedTeachingAssistants}
                    subjectId={props.subjectId}
                    fetchUsers={fetchUsers}
                    onRemove={removeAssistantFromSubjectHandler}
                    setLoading={props.setIsLoading}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}

            {appUser.firestoreUser?.userRole?.isAdmin ? (
              <div className="assign-subjects__admin text-white">
                <div className="assign-subjects__encap mr-auto">
                  <div className="assign-subjects__header d-flex justify-content-between">
                    <div className="assign-subjects__header-name text-white">Instructor</div>
                    <div
                      className="assign-subjects__link"
                      onClick={() => {
                        if (!props.loading) {
                          openInstructorModal();
                        }
                      }}
                    >
                      Add Instructor
                    </div>
                  </div>
                  <AssignSubjectsCardsList
                    users={state.subjectAssignedInstructors}
                    subjectId={props.subjectId}
                    fetchUsers={fetchUsers}
                    onRemove={removeInstructorFromSubjectHandler}
                    setLoading={props.setIsLoading}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <>
            {appUser.firestoreUser?.userRole?.isAdmin ||
            appUser.firestoreUser?.userRole?.isInstructor ? (
              <section className="label-section">
                <h5 className="text-white mt-1 - ml-2 label-section__header d-flex ">
                  Manage Labels{' '}
                  <button
                    className="label-section__add-btn ml-2"
                    onClick={() => {
                      setLabelState((ps) => ({ ...ps, show: true }));
                    }}
                  >
                    <FaPlus color="white" />
                  </button>
                </h5>

                <div className="label-section__list">
                  {sortByCreatedAt(appLabels.labelList)
                    .filter((val) => {
                      if (val.subjectId === props.subjectId) {
                        return true;
                      }

                      return false;
                    })
                    .map((label) => {
                      return (
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip id="verifiedId">{label.labelName}</Tooltip>}
                        >
                          <div className="label-section__list-item">
                            <div className="label-section__name">{label.labelName}</div>

                            <div className="label-section__actions">
                              <BoxIcon
                                icon={FaEdit}
                                color="#fff"
                                size={13}
                                style={{ width: 30, height: 30, backgroundColor: '#246bfd' }}
                                onClick={() => {
                                  setLabelState((ps) => ({ ...ps, show: true, labelId: label.id }));
                                }}
                              />
                              <BoxIcon
                                icon={FaTrashAlt}
                                color="#fff"
                                size={13}
                                style={{
                                  width: 30,
                                  height: 30,
                                  backgroundColor: '#F05A5A',
                                  marginLeft: 10,
                                }}
                                onClick={() => {
                                  props.setIsLoading(true);

                                  deleteLabelRef({
                                    labelId: label.id,
                                    subjectId: props.subjectId,
                                  })
                                    .then((data) => {
                                      if (data.data.data === 'Questions present with label') {
                                        props.setIsLoading(false);
                                        return Notification({
                                          isSuccess: false,
                                          message: `This label has questions associated with it`,
                                          icon: FaExclamation,
                                        });
                                      }

                                      const list = [...appLabels.labelList].filter(
                                        (la) => la.id !== data.data.data
                                      );

                                      appLabels.setLabelList(list);

                                      props.setIsLoading(false);
                                      Notification({
                                        isSuccess: true,
                                        message: `Deleted label successfully`,
                                      });
                                    })
                                    .catch(() => {
                                      props.setIsLoading(false);
                                    });

                                  // setLabelState((ps) => ({...ps, labelId: label.id}));
                                }}
                              />
                            </div>
                          </div>
                        </OverlayTrigger>
                      );
                    })}
                </div>
              </section>
            ) : (
              <></>
            )}
          </>
        )}
      </div>

      <AssignSubjectsModal
        title="Add Assistant"
        show={state.showTeachingAssistantModal}
        closeModal={closeTeachingAssistantModal}
        users={state.remainingTeachingAssistants}
        subjectId={props.subjectId}
        fetchUsers={fetchUsers}
        onAssign={addAssistantToSubjectHandler}
        type="ASSISTANT"
      />

      <AssignSubjectsModal
        title="Add Instructor"
        show={state.showInstructorModal}
        closeModal={closeInstructorModal}
        users={state.remainingInstructors}
        subjectId={props.subjectId}
        fetchUsers={fetchUsers}
        onAssign={addInstructorToSubjectHandler}
        type="INSTRUCTOR"
      />

      <AddLabelModal
        subjectId={props.subjectId}
        show={labelState.show}
        closeModal={() => {
          setLabelState((ps) => ({ ...ps, show: false, labelId: '' }));
        }}
        setIsLoading={props.setIsLoading}
        updateId={labelState.labelId}
        labelName={lName}
      />

      <UserRosterPackagesModal
        show={open}
        user={user}
        userRosterPackagesModalHandler={userRosterPackagesModalHandler}
      />

      <AddNewUserModal
        show={addUserState.show}
        users={state.remainingInstructorsAndTAs}
        subjectId={props.subjectId}
        addNewUserModalHandler={addNewUserModalHandler}
        onAddNewUser={onAddNewUser}
      />
    </div>
  );
};

export default AssignSubjects;
