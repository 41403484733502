import React, { FC, SetStateAction, useEffect, useState } from 'react';
import { useFirestore } from 'reactfire';
import BreadCrum from '../../components/BreadCrum';
import LoadingIndicator from '../../components/LoadingIndicator';
import PrimaryNav from '../../components/PrimaryNav';
import Reports from '../../components/Reports';
import SettingsManageUsersBody from '../../components/SettingsManageUsersBody';
import SettingsSecondaryNav from '../../components/SettingsSecondaryNav';
import SettingsValidateInstructorsAndTeachingAssistant from '../../components/SettingsValidateInstructors';
import { SCREEN, SETTINGS } from '../../constants';
import { useAppUser } from '../../context/UserContext';
import useSessionStorage from '../../hooks/useSessionStorage';
import LayoutNew from '../../layouts/LayoutNew';
import AccessCodes from '../../components/AccessCodes';
import { Route, Switch } from 'react-router-dom';

const Settings = () => {
  const store = useFirestore();

  const [users, setUsers] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [tabState, setTabState] = useSessionStorage(SCREEN.SETTINGS, {
    currentTab: SETTINGS.MANAGE_USERS,
  });

  useEffect(() => {
    const usersFromDB: any[] = [];
    setLoading(true);
    store
      .collection('users')
      .orderBy('lowercaseFirstName')
      .get()
      .then((res) => {
        res.forEach((doc) => {
          usersFromDB.push({ ...doc.data(), id: doc.id });
        });

        setUsers(usersFromDB);
        setLoading(false);
      })
      .catch(() => {
        console.log('Error occured fetching user from DB');
        setLoading(false);
      });
  }, [store]);

  const changeCurrentTabHandler = (tab: string) => {
    setTabState((ps: any) => {
      return { ...ps, currentTab: tab };
    });
  };

  const primaryNav = <PrimaryNav />;

  const secondaryNav = (
    <SettingsSecondaryNav
      onCurrentTabChange={changeCurrentTabHandler}
      currentTab={tabState.currentTab}
      screen={SCREEN.SETTINGS}
    />
  );

  const header = (
    <>
      {loading && <LoadingIndicator />}
      <div className="d-sm-flex justify-content-between align-items-center">
        <BreadCrum className="d-none d-sm-flex" />
      </div>
    </>
  );

  const header2 = (
    <>
      {loading && <LoadingIndicator />}
      <div className="d-sm-flex justify-content-between align-items-center">
        <BreadCrum className="d-none d-sm-flex" />
      </div>
    </>
  );

  const headerRender = (
    <Switch>
      <Route path="/settings/:id">{header2}</Route>
      <Route path="/settings">{header}</Route>
    </Switch>
  );

  // let body = null;

  const firestoreUserUid = useAppUser().fireUser?.uid;

  const bodyRender = (
    <Switch>
      {/* <Route path="/settings/:id">
        <div className="text-white">
          <BulkAccessCodesOfPackage />
        </div>
      </Route> */}
      <Route path="/settings">
        <Body
          currentTab={tabState.currentTab}
          setUsers={setUsers}
          users={users}
          uid={firestoreUserUid || ''}
        />
      </Route>
    </Switch>
  );

  return (
    <Switch>
      <Route path="/settings">
        <LayoutNew
          header={headerRender}
          primaryNav={primaryNav}
          body={bodyRender}
          secondaryNav={secondaryNav}
        />
      </Route>
    </Switch>
  );
};

const Body: FC<{
  currentTab: any;
  users: any;
  uid: string;
  setUsers: React.Dispatch<SetStateAction<any[]>>;
}> = (props) => {
  if (props.currentTab === SETTINGS.MANAGE_USERS && props.users.length) {
    return (
      <SettingsManageUsersBody
        users={props.users.filter((u: any) => u.uid !== props.uid)}
        setUsers={props.setUsers}
      />
    );
  } else if (props.currentTab === SETTINGS.VALIDATE_INSTRUCTORS) {
    return (
      <SettingsValidateInstructorsAndTeachingAssistant
        users={props.users.filter(
          (u: any) => u.uid !== props.uid && u.userRole.isInstructor && !u.isApproved
        )}
        setUsers={props.setUsers}
      />
    );
  } else if (props.currentTab === SETTINGS.REPORTS && props.users.length) {
    return <Reports />;
  } else if (props.currentTab === SETTINGS.ACCESS_CODES) {
    return <AccessCodes />;
  } else if (props.users.length) {
    return (
      <SettingsValidateInstructorsAndTeachingAssistant
        users={props.users.filter(
          (u: any) => u.uid !== props.uid && u.userRole.isTeachingAssistant && !u.isApproved
        )}
        setUsers={props.setUsers}
      />
    );
  } else {
    return <></>;
  }
};

export default Settings;
