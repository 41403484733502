import React, {useCallback, useEffect, useState} from 'react';
import Modal from 'react-modal';
import {ClipLoader} from 'react-spinners';
import OtpInput from 'react-otp-input';
import './VerifyPhoneModal.scss';
import {FaWindowClose} from 'react-icons/fa';
import {useFunctions} from 'reactfire';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  userId?: string;

}

const VerifyPhoneModal = (props: IProps) => {
  const timeOutSeconds = 60;
  const [isValidOtp, setIsValidOtp] = useState(false);
  const [timer, setTimer] = useState(timeOutSeconds);
  const [isTimeOut, setIsTimeOut] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const resetTimer = () => {
    setIsTimeOut(true);
    setTimer(timeOutSeconds);
  };

  const timeOutCallback = useCallback(() => setTimer(timer - 1), [timer]);

  useEffect(() => {
    if (!isTimeOut) {
      if (timer > 0) {
        setTimeout(timeOutCallback, 1000);
      } else {
        resetTimer();
      }
    }

  }, [isTimeOut, timeOutCallback, timer]);

  const verifyPhoneRef = useFunctions().httpsCallable('verifyPhone');
  const resendOtpRef = useFunctions().httpsCallable('resendOTP');

  const initialState = {
    loading: false,
    otp: ''
  };
  const handleChange = (otp: any) => {
    setState((pS) => ({
      ...pS,
      otp: otp
    }));
    setErrorMessage('');
    if (otp.length === 4) {
      setIsValidOtp(true);
    } else {
      setIsValidOtp(false);
    }
  };
  const onSubmit = () => {
    if (props.userId) {
      setIsLoading(true);
      verifyPhoneRef({userID: props.userId, otp: state.otp}).then(res => {
        console.log('res', res);
        if (res.data.success) {
          props.onClose();
        } else {
          setErrorMessage('Invalid OTP');
        }
        setIsLoading(false);
      }).catch(err => {
        console.log('err', err);
        setErrorMessage('Something went wrong. Please try again later.');
        setIsLoading(false);
      });
    } else {
      setErrorMessage(' Cannot Verify Phone Number please try again');
    }

  };

  const resendOtp = () => {
    if (isTimeOut) {
      setIsTimeOut(false);
      if (props.userId) {
        resendOtpRef({userID: props.userId}).then(res => {
          console.log('res', res);
        }).catch(err => {
          console.log('err', err);
        });
      }
    }

  };
  const [state, setState] = useState(initialState);
  return (
    <div>
      {/*<AlertPopup*/}
      {/*  message='Could not verify your phone number. Please try again.'*/}
      {/*  header='Attention'*/}
      {/*  isShow={isError}*/}
      {/*  onOk={() => {*/}
      {/*    setIsError(false);*/}
      {/*  }}*/}
      {/*  onClose={() => {*/}
      {/*    setIsError(false);*/}
      {/*  }}*/}
      {/*  type='OK'*/}
      {/*/>*/}
      <Modal className='verify-phone-modal' isOpen={props.isOpen}
             overlayClassName='r-overlay'
             ariaHideApp={false}
             closeTimeoutMS={500}>
        <div className='verify-phone-container'>
          <div className='d-flex justify-content-end w-100'>
            <FaWindowClose
              color='#FF4C6C'
              className='m-2'
              size={20}
              style={{cursor: 'pointer'}}
              onClick={props.onClose}
            />
          </div>
          <div className='d-flex justify-content-center'>
            <div className='verify-phone'>

              <div className='verify-phone__title'>Verify Phone Number</div>
              <div className='verify-phone__subtitle'>
                Enter the OTP sent to your phone
              </div>
              <div className='d-flex flex-row mt-5 otp-input-wrapper'>
                <OtpInput
                  value={state.otp}
                  onChange={handleChange}
                  numInputs={4}
                  isInputNum={true}
                  className='otp-input'
                />
              </div>
              {
                errorMessage && errorMessage.trim().length > 0 &&
                <div className='d-flex mt-2'>
                  <span className='error-message'>Error: {errorMessage}</span>
                </div>
              }

              {isLoading ? <div className='d-flex justify-content-center mt-4'>
                  <ClipLoader size={20} color={'#FF4C6C'} />
                </div>
                :
                <>
                  <div className={`verify-phone__btn ${!isValidOtp && 'disabled'}`} onClick={onSubmit}>
                    <span className='d-inline-block mr-2'>Verify</span>{' '}
                    <ClipLoader color={'purple'} loading={false} size={18} />
                  </div>
                  <div className='text-center mt-2'>
                    {isTimeOut ?
                      <span>Didn't receive? <span className='resend-otp' onClick={resendOtp}>resend OTP</span></span> :
                      <span>Resending in {('0' + timer.toString()).slice(-2)} seconds</span>}
                  </div>
                </>
              }
            </div>
          </div>
        </div>

      </Modal>
    </div>
  );
};
export default VerifyPhoneModal;