import { FaSortAmountDown, FaSortAmountDownAlt } from 'react-icons/fa';
import './AccessCodeList.scss';
import { useEffect, useMemo, useState } from 'react';
import { splitArrayIntoChunksOfLen } from '../utils';
import { matchSorter } from 'match-sorter';
import LoadingIndicator from './LoadingIndicator';
import Pagination from './Pagination';

interface State {
  searchText: string;
  page: number;
  totalPages: number;
  codeList: any[];
  loading: boolean;
  filtered: boolean;
}

const AccessCodeList = ({ sText }: { sText: string }) => {
  const codeList = useMemo(() => {
    return [
      {
        id: 1,
        code: 'Tom',
        status: 'Inactive',
        expireDate: '2023/10.06',
        availability: '15/35',
        package: 'All Packages',
        discount: '100%',
      },
      {
        id: 2,
        code: 'cooper50',
        status: 'Active',
        expireDate: '2023/10.06',
        availability: '15/35',
        package: 'All Packages',
        discount: '100%',
      },
      {
        id: 3,
        code: 'cooper50',
        status: 'Bctive',
        expireDate: '2022/10.06',
        availability: '15/35',
        package: 'Bll Packages',
        discount: '100%',
      },
      {
        id: 4,
        code: 'cooper50',
        status: 'Active',
        expireDate: '2023/10.06',
        availability: '15/35',
        package: 'All Packages',
        discount: '100%',
      },
      {
        id: 5,
        code: 'cooper50',
        status: 'Active',
        expireDate: '2023/10.06',
        availability: '15/35',
        package: 'Science for Technology',
        discount: '100%',
      },
      {
        id: 6,
        code: 'cooper50',
        status: 'Active',
        expireDate: '2023/10.06',
        availability: '15/35',
        package: 'Science for Technology',
        discount: '100%',
      },
      {
        id: 7,
        code: 'cooper50',
        status: 'Active',
        expireDate: '2023/10.06',
        availability: '15/35',
        package: 'Science for Technology',
        discount: '90%',
      },
      {
        id: 8,
        code: 'cooper50',
        status: 'Active',
        expireDate: '2023/10.06',
        availability: '15/35',
        package: 'Science for Technology',
        discount: '100%',
      },
      {
        id: 9,
        code: 'cooper50',
        status: 'Active',
        expireDate: '2023/10.06',
        availability: '15/35',
        package: 'Science for Technology',
        discount: '100%',
      },
      {
        id: 10,
        code: 'cooper50',
        status: 'Active',
        expireDate: '2023/10.06',
        availability: '15/35',
        package: 'All Packages',
        discount: '100%',
      },
      {
        id: 11,
        code: 'cooper50',
        status: 'Active',
        expireDate: '2023/10.06',
        availability: '15/35',
        package: 'All Packages',
        discount: '100%',
      },
      {
        id: 12,
        code: 'cooper50',
        status: 'Inactive',
        expireDate: '2023/10.06',
        availability: '15/35',
        package: 'All Packages',
        discount: '100%',
      },
      {
        id: 12,
        code: 'cooper50',
        status: 'Inactive',
        expireDate: '2023/10.06',
        availability: '15/35',
        package: 'All Packages',
        discount: '100%',
      },
    ];
  }, []);

  const [StatusSorted, setStatusSorted] = useState(true);
  const [EDateSorted, setEDateSorted] = useState(true);
  const [PackageSorted, setPackageSorted] = useState(true);
  const [DiscountSorted, setDiscountSorted] = useState(true);

  const [state, setState] = useState<State>({
    searchText: sText,
    page: 0,
    totalPages: 0,
    codeList: codeList,
    loading: false,
    filtered: false,
  });

  useEffect(() => {
    setState((ps) => ({
      ...ps,
      codeList: codeList,
      searchText: sText,
    }));
  }, [codeList, sText]);

  const fetchDataCall = (filter?: boolean) => {
    if (typeof state.totalPages === 'number') {
      setState((ps) => {
        return { ...ps, loading: true };
      });

      const pageToSend = filter ? 0 : state.page - 1 < 0 ? 0 : state.page - 1;

      let filteredList = [...codeList];

      let searchTextResult: any[] = [];
      let duplicatesRemovedArray: any[];

      if (state?.searchText?.trim()) {
        const words = state?.searchText.trim()?.split(' ');

        for (const word in words) {
          searchTextResult = [
            ...searchTextResult,
            ...filteredList.filter(
              (item) =>
                matchSorter(item.code.split(' '), words[word].trim() || '').length ||
                matchSorter(item.status.split(' '), words[word].trim() || '').length
            ),
          ];
        }

        duplicatesRemovedArray = Array.from(new Set(searchTextResult));
      } else {
        duplicatesRemovedArray = filteredList;
      }

      let pagesList: any[] = [];

      if (duplicatesRemovedArray.length) {
        pagesList = splitArrayIntoChunksOfLen(duplicatesRemovedArray, 10);
      }

      const totalPages = pagesList.length;

      setState((ps) => {
        return {
          ...ps,
          loading: false,
          codeList: pagesList[pageToSend] || [],
          totalPages: totalPages,
          filtered: filter ? true : false,
        };
      });
    }
  };

  const setCurrentPage = (page: number) => {
    setState((ps) => {
      return { ...ps, page: page };
    });
  };

  useEffect(() => {
    if (codeList.length) {
      fetchDataCall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.page, codeList]);

  useEffect(() => {
    setState((ps) => {
      return { ...ps, filtered: false };
    });
  }, [state.filtered]);

  useEffect(() => {
    fetchDataCall(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.searchText]);

  const onClickStatusSortHandler = () => {
    setStatusSorted(!StatusSorted);
    if (StatusSorted) {
      const statusAsc = [...state.codeList].sort((a, b) => (a.status > b.status ? 1 : -1));
      state.codeList = statusAsc;
    } else {
      const statusDesc = [...state.codeList].sort((a, b) => (a.status > b.status ? -1 : 1));
      state.codeList = statusDesc;
    }
  };

  const onClickEDateSortHandler = () => {
    setEDateSorted(!EDateSorted);
    if (EDateSorted) {
      const eDateAsc = [...state.codeList].sort((a, b) => (a.expireDate > b.expireDate ? 1 : -1));
      state.codeList = eDateAsc;
    } else {
      const eDateDesc = [...state.codeList].sort((a, b) => (a.expireDate > b.expireDate ? -1 : 1));
      state.codeList = eDateDesc;
    }
  };

  const onClickPackageSortHandler = () => {
    setPackageSorted(!PackageSorted);
    if (PackageSorted) {
      const packageAsc = [...state.codeList].sort((a, b) => (a.package > b.package ? 1 : -1));
      state.codeList = packageAsc;
    } else {
      const packageDesc = [...state.codeList].sort((a, b) => (a.package > b.package ? -1 : 1));
      state.codeList = packageDesc;
    }
  };

  const onClickDiscountSortHandler = () => {
    setDiscountSorted(!DiscountSorted);
    if (DiscountSorted) {
      const discountAsc = [...state.codeList].sort((a, b) => (a.discount > b.discount ? 1 : -1));
      state.codeList = discountAsc;
    } else {
      const discountDesc = [...state.codeList].sort((a, b) => (a.discount > b.discount ? -1 : 1));
      state.codeList = discountDesc;
    }
  };

  return (
    <div>
      {state.loading && <LoadingIndicator className="mt-3" />}
      <div className="code-tbl-titles d-flex" style={{ marginTop: 30, transition: 'none' }}>
        <div>Code</div>
        <div>
          Status
          <span style={{ marginLeft: '10px', cursor: 'pointer' }}>
            {StatusSorted ? (
              <FaSortAmountDownAlt onClick={onClickStatusSortHandler} />
            ) : (
              <FaSortAmountDown onClick={onClickStatusSortHandler} />
            )}
          </span>
        </div>
        <div>
          {' '}
          Expire Date
          <span style={{ marginLeft: '10px', cursor: 'pointer' }}>
            {EDateSorted ? (
              <FaSortAmountDownAlt onClick={onClickEDateSortHandler} />
            ) : (
              <FaSortAmountDown onClick={onClickEDateSortHandler} />
            )}
          </span>
        </div>
        <div>Availability</div>
        <div>
          Package
          <span style={{ marginLeft: '10px', cursor: 'pointer' }}>
            {PackageSorted ? (
              <FaSortAmountDownAlt onClick={onClickPackageSortHandler} />
            ) : (
              <FaSortAmountDown onClick={onClickPackageSortHandler} />
            )}
          </span>
        </div>
        <div>
          Discount
          <span style={{ marginLeft: '10px', cursor: 'pointer' }}>
            {DiscountSorted ? (
              <FaSortAmountDownAlt onClick={onClickDiscountSortHandler} />
            ) : (
              <FaSortAmountDown onClick={onClickDiscountSortHandler} />
            )}
          </span>
        </div>
      </div>

      <div className="cAccess-code-list">
        <div className="common_accessCodePackages-container">
          {state.codeList?.map((codelist: any) => (
            <div className="accessCodes" onClick={() => {}}>
              <div className="accessCodes__code accessCodes__font"> {codelist.code} </div>
              <div className="accessCodes__status accessCodes__font"> {codelist.status} </div>
              <div className="accessCodes__expiredate accessCodes__font">
                {' '}
                {codelist.expireDate}{' '}
              </div>
              <div className="accessCodes__availability accessCodes__font">
                {codelist.availability}{' '}
              </div>
              <div className="accessCodes__package accessCodes__font"> {codelist.package} </div>
              <div className="accessCodes__discount accessCodes__font"> {codelist.discount} </div>
            </div>
          ))}
        </div>
      </div>

      {!state.filtered && state.totalPages > 1 ? (
        <Pagination pages={state.totalPages} setCurrentPage={setCurrentPage} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default AccessCodeList;
