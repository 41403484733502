import React, {FC, useState} from 'react';

import './AccountSecondaryNav.scss';
import truncate from 'truncate';
import {useAppUser} from '../context/UserContext';
import {useMobileNav} from '../context/MobNavContext';
import Avatar from './Avatar';
import {getMenuItems} from '../utils';
import {ACCOUNT_MENU_ITEM} from '../constants';
import Tab from './Tab';

interface Props {
  onCurrentTabChange: (tab: string) => void;
  currentTab: string;
  screen: string;
}

const AccountSecondaryNav: FC<Props> = (props) => {
  const appUser = useAppUser();
  const appMobileNav = useMobileNav();

  let userRoleText = 'Student';

  if (appUser.firestoreUser?.userRole?.isAdmin) {
    userRoleText = 'Admin';
  } else if (appUser.firestoreUser?.userRole?.isTeachingAssistant) {
    userRoleText = 'Teaching Assistant';
  } else if (appUser.firestoreUser?.userRole?.isInstructor) {
    userRoleText = 'Instructor';
  }

  const photoURL = appUser.firestoreUser?.photoURL || appUser.fireUser?.photoURL || '';

  const [loading, setLoading] = useState<boolean>(false);

  const setImageLoading = (val: boolean) => {
    setLoading(val);
  };

  //username truncate issue unpredictable
  return (
    <>
      <div className="text-center">
        <div style={{color: '#fff', fontSize: 25, marginBottom: 35, lineHeight: 1}}>
          My Account
          <div style={{color: '#fff', fontSize: 18, lineHeight: 1}}>{userRoleText}</div>
        </div>
      </div>

      <Avatar
        imageURL={photoURL}
        username={truncate(appUser.firestoreUser?.username || '', 25)}
        setLoading={setImageLoading}
        loading={loading}
      />

      <div
        className="d-flex flex-column justify-content-between"
        style={
          loading
            ? {height: 'calc(100% - 275px)', transition: 'none'}
            : {height: 'calc(100% - 255px)', transition: 'none'}
        }
      >
        <div>
          {getMenuItems(
            ACCOUNT_MENU_ITEM,
            props.currentTab,
            props.onCurrentTabChange,
            props.screen
          ).map((item) => (
            <Tab
              text={item.text}
              onClick={props.onCurrentTabChange}
              key={item.text}
              active={item.active}
            />
          ))}
        </div>
        <div
          className={`layout-btn  mx-auto ${
            appMobileNav.openMobileNav ? 'layout-btn--mobile mt-3' : 'mt-auto'
          }`}
          onClick={appUser.logOut}
        >
          Logout
        </div>
      </div>
    </>
  );
};

export default AccountSecondaryNav;
