import React, { useEffect, useMemo, useState } from 'react';
import './BulkAccessCodeUsage.scss';
import { FaFileDownload, FaCircle, FaSortAmountDownAlt } from 'react-icons/fa';
import Pagination from './Pagination';
import { splitArrayIntoChunksOfLen } from '../utils';
import SearchComponent from './SearchWithFilter';
import SwitchBtn from './SwitchBtn';

interface State {
    page: number;
    totalPages: number;
    users: any[];
    loading: boolean;
    filtered: boolean;
    checked: boolean
}

const BulkAccessCodeUsage = () => {
    const users = useMemo(() => {
        return [
            {
                id: 1,
                code: 'ITALKF556466',
                usedBy: 'John Micheal',
                dateUsed: '29/7/2012',
                active: true,
            },
            {
                id: 2,
                code: 'ITALKF556466',
                usedBy: 'John Micheal',
                dateUsed: '29/7/2012',
                active: true,
            },
            {
                id: 3,
                code: 'ITALKF556466',
                usedBy: 'John Micheal',
                dateUsed: '29/7/2012',
                active: true,
            },
            {
                id: 4,
                code: 'ITALKF556466',
                usedBy: 'John Micheal',
                dateUsed: '29/7/2012',
                active: true,
            },
            {
                id: 5,
                code: 'ITALKF556466',
                usedBy: 'John Micheal',
                dateUsed: '29/7/2012',
                active: true,
            },
            {
                id: 6,
                code: 'ITALKF556466',
                usedBy: 'John Micheal',
                dateUsed: '29/7/2012',
                active: true,
            },
            {
                id: 7,
                code: 'ITALKF556466',
                usedBy: 'John Micheal',
                dateUsed: '29/7/2012',
                active: true,
            },
            {
                id: 8,
                code: 'ITALKF556466',
                usedBy: '-',
                dateUsed: '-',
                active: true,
            },
            {
                id: 9,
                code: 'ITALKF556466',
                usedBy: '-',
                dateUsed: '-',
                active: true,
            },
            {
                id: 10,
                code: 'ITALKF556466',
                usedBy: '-',
                dateUsed: '-',
                active: true,
            },
            {
                id: 11,
                code: 'ITALKF556466',
                usedBy: '-',
                dateUsed: '-',
                active: true,
            },
            {
                id: 12,
                code: 'ITALKF556466',
                usedBy: '-',
                dateUsed: '-',
                active: true,
            },
        ];
    }, []);

    const [state, setState] = useState<State>({
        page: 0,
        totalPages: 0,
        users: users,
        loading: false,
        filtered: false,
        checked: true,
    });

    useEffect(() => {
        setState((ps) => ({
            ...ps,
            users: users,
        }));
    }, [users]);

    const fetchDataCall = (filter?: boolean) => {
        if (typeof state.totalPages === 'number') {
            setState((ps) => {
                return { ...ps, loading: true };
            });

            const pageToSend = filter ? 0 : state.page - 1 < 0 ? 0 : state.page - 1;

            let filteredList = [...users];

            let duplicatesRemovedArray: any[];

            duplicatesRemovedArray = filteredList;

            let pagesList: any[] = [];

            if (duplicatesRemovedArray.length) {
                pagesList = splitArrayIntoChunksOfLen(duplicatesRemovedArray, 10);
            }

            const totalPages = pagesList.length;

            setState((ps) => {
                return {
                    ...ps,
                    loading: false,
                    users: pagesList[pageToSend] || [],
                    totalPages: totalPages,
                    filtered: filter ? true : false,
                };
            });
        }
    };

    const setCurrentPage = (page: number) => {
        setState((ps) => {
            return { ...ps, page: page };
        });
    };

    useEffect(() => {
        if (users.length) {
          fetchDataCall();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [state.page, users]);

    useEffect(() => {
        setState((ps) => {
            return { ...ps, filtered: false };
        });
    }, [state.filtered]);

    const changeHandler = (value: boolean) => {
        if (state.checked === true) {
            state.checked = false;
        } else {
            state.checked = true;
        }
    };

    return (
        <div className="text-white">
            <div className='d-flex'>
                <div className="bCode__selected d-flex" style={{ flex: '3' }}
                    onClick={() => { }}>
                    <div style={{ flex: 4, marginRight: '80px' }}>
                        <div className="bCode__selected-code">
                            ITAL|22|1051|100% <FaCircle size={6} color={'#00FF00'} style={{ justifyItems: 'center' }} />
                        </div>
                        <div className="bCode__selected-expire">Exp: 29/07/2023</div>
                    </div>
                    <div className='d-flex bCode__selected-status' style={{ flex: 4 }} >
                        <div style={{ marginRight: '10px', color: '#979797' }}>
                            Active
                        </div>
                        <div>
                            <SwitchBtn
                                changeHandler={changeHandler}
                                checked={state.checked} />
                        </div>
                    </div>
                    <div style={{ flex: 2 }}>
                        <div className="bCode__selected-availability">Available</div>
                        <div className="bCode__selected-amount">1200/2000</div>
                    </div>
                    <div className="bCode__selected-downicon" style={{ flex: 1 }}>
                        <FaFileDownload size={25} />
                    </div>
                </div>
                <div className='s-box' style={{ flex: '1' }}>
                    <SearchComponent
                        onSearch={(text) => {
                            setState((ps) => {
                                return { ...ps, searchText: text };
                            });
                        }}
                        placeHolderText="Search Code"
                        onClickLink={() => { }}
                    />
                </div>
            </div>

            <div className="bCodeUsage-tbl-titles d-flex" style={{ marginTop: 30, transition: 'none' }}>
                <div>Code
                    <span style={{ marginLeft: '10px', cursor: 'pointer' }}>
                        <FaSortAmountDownAlt />
                    </span>
                </div>
                <div>
                    Used By
                    <span style={{ marginLeft: '10px', cursor: 'pointer' }}>
                        <FaSortAmountDownAlt />
                    </span>
                </div>
                <div>
                    Date Used
                    <span style={{ marginLeft: '10px', cursor: 'pointer' }}>
                        {/* {EDateSorted ? (
                            <FaSortAmountDownAlt onClick={onClickEDateSortHandler} />
                        ) : (
                            <FaSortAmountDown onClick={onClickEDateSortHandler} />
                        )} */}
                    </span>
                </div>
                <div>  </div>
            </div>

            <div className="CodeUsage-user-list">
                <div className="bAccessCodeUsage-container">
                    {state.users?.map((item: any) => (
                        <div className="code_users" onClick={() => { }}>
                            <div className="code_users__code code_users__font"> {item.code} </div>
                            <div className="code_users__usedBy code_users__font"> {item.usedBy} </div>
                            <div className="code_users__dateUsed code_users__font">{item.dateUsed}</div>
                            <div className="code_users__active code_users__font d-flex">
                                <div style={{ marginRight: '10px' }}>  Active</div>
                                <div><SwitchBtn
                                    changeHandler={changeHandler}
                                    checked={state.checked}
                                    id={item.id}
                                /></div>

                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {!state.filtered && state.totalPages > 1 ? (
                <Pagination pages={state.totalPages} setCurrentPage={setCurrentPage} />
            ) : (
                <></>
            )}
        </div>
    );
};

export default BulkAccessCodeUsage;
