import React, {FC} from 'react';
import {FaEdit, FaUserCircle} from 'react-icons/fa';
import {useFirestore, useStorage} from 'reactfire';
import './Avatar.scss';
import LoadingIndicator from './LoadingIndicator';
import Notification from './../components/Notification';
import {ClipLoader} from 'react-spinners';
import {useAppUser} from '../context/UserContext';

interface Props {
  imageURL: string;
  username: string;
  style?: React.CSSProperties;
  loading: boolean;
  setLoading: (val: boolean) => void;
}

const Avatar: FC<Props> = (props) => {
  const appUser = useAppUser();
  const storage = useStorage();
  const store = useFirestore();

  const types = ['image/png', 'image/jpeg'];

  const uploadProfileImage = (e: any) => {
    let selected = e.target.files[0];

    if (selected && types.includes(selected.type)) {
      const size = selected.size / 1024 / 1024;

      if (size > 1) {
        Notification({
          isSuccess: false,
          message: 'File size should be less than 1 MB',
        });
        return;
      }

      props.setLoading(true);
      const storageRef = storage
        .ref()
        .child(`userProfileImages/${appUser.fireUser?.uid}_${selected?.name}`);
      storageRef.put(selected!).on(
        'state_changed',
        (snap) => {
          // let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
          //console.log('upload percentage ', percentage);
        },
        (err) => {
          props.setLoading(false);

          Notification({
            isSuccess: false,
            message: 'Failed to update profile picture.Try again later',
          });
        },
        async () => {
          const url = await storageRef.getDownloadURL();
          store
            .collection('users')
            .where('uid', '==', appUser.fireUser?.uid)
            .get()
            .then((data) => {
              const docId = data.docs[0].id;

              store
                .collection('users')
                .doc(docId)
                .update({photoURL: url})
                .then((res) => {
                  if (appUser.firestoreUser) {
                    appUser.setUserFirestore({...appUser.firestoreUser, photoURL: url});
                  }
                });
            })
            .catch((e) => {
              console.log('Error ', e);
            });

          props.setLoading(false);
        }
      );
    } else {
      Notification({
        isSuccess: false,
        message: 'Please select an image file (png or jpeg)',
      });
    }
  };

  return (
    <div className="avatar" style={props.style}>
      {props.imageURL ? (
        <div className="avatar-img mb-3" style={{backgroundImage: `url(${props.imageURL})`}}>
          <div className="avatar-edit">
            <input
              type="file"
              style={{opacity: 0, width: '100%', height: '100%', zIndex: 2, top: 0, right: 0}}
              className="position-absolute"
              onChange={uploadProfileImage}
            />
            <FaEdit size={13} color={'#fff'} className="avatar-edit-icon" />
          </div>
        </div>
      ) : (
        <div className="avatar-img mb-3">
          <FaUserCircle
            size={100}
            color={'#fff'}
            className="avatar-edit-icon"
            style={{marginBottom: 13.5}}
          />
          <div className="avatar-edit">
            <input
              type="file"
              style={{opacity: 0, width: '100%', height: '100%', zIndex: 2, top: 0, right: 0}}
              className="position-absolute"
              onChange={uploadProfileImage}
            />
            <FaEdit size={13} color={'#fff'} className="avatar-edit-icon" />
          </div>
        </div>
      )}

      {props.loading && <LoadingIndicator />}
      <div className="avatar-username">
        {props.username || <ClipLoader color={'purple'} loading={true} size={15} />}
      </div>
      <div style={{color: '#73847F', fontSize: 14, lineHeight: 1, marginTop: '2px'}}>
        {appUser.firestoreUser?.userIndex}
      </div>
    </div>
  );
};

export default Avatar;
