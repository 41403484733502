import React, {FC} from 'react';
import {FaWindowClose} from 'react-icons/fa';
import Modal from 'react-modal';
import {UserFirestore} from '../models/UserFirestore';
import UserRosterModalPackageCard from './UserRosterModalPackageCard';
import './UserRosterPackagesModal.scss';

interface Props {
  show: boolean;
  userRosterPackagesModalHandler?: (value: boolean) => void;
  user?: UserFirestore;
}

const packageList = [
  {
    id: '001',
    title: 'Information and Communication Technology',
    packageType: ' Past Papers Only',
    remainingTime: '30 days 5 hours',
  },
  {
    id: '002',
    title: 'Information and Communication Technology 2',
    packageType: ' Past Papers Only',
    remainingTime: '34 days 8 hours',
  },
];

const UserRosterPackagesModal: FC<Props> = (props) => {
  return (
    <>
      <Modal
        isOpen={props.show}
        contentLabel="Example Modal"
        className="user-roster-packages-modal"
        overlayClassName="r-overlay"
        ariaHideApp={false}
        closeTimeoutMS={500}
      >
        <div className="packages-roster-header">
          <div className="packages-roster-modal-user-type">
            {props.user?.username}
            <div className="packages-roster-modal-sub-text">
              {props.user?.userRole?.isStudent ? 'Student' : ''}
            </div>
          </div>
          <div className="packages-roster-modal-close">
            <FaWindowClose
              color="#FF4C6C"
              size={20}
              style={{
                cursor: 'pointer',
              }}
              onClick={() => {
                props.userRosterPackagesModalHandler && props.userRosterPackagesModalHandler(false);
              }}
            />
          </div>
        </div>

        <div className="packages-roster-modal-sub-text packages-roster-modal-sub-text__packages-main-text">
          {' '}
          Packages Accessed
        </div>

        {packageList.map((packge) => {
          return (
            <UserRosterModalPackageCard
              id={packge.id}
              title={packge.title}
              packageType={packge.packageType}
              remainingTime={packge.remainingTime}
            />
          );
        })}
      </Modal>
    </>
  );
};

export default UserRosterPackagesModal;
