import firebase from 'firebase';
import React, { useEffect, useRef, useState } from 'react';
import BreadCrum from '../../components/BreadCrum';
import EssayPapersTab from '../../components/EssayPapersTab';
import MCQQuestionTab from '../../components/MCQQuestionTab';
import MCQTrueFalsePapersTab from '../../components/MCQTrueFalsePapersTab';
import PaperHeader from '../../components/PaperHeader';
import PrimaryNav from '../../components/PrimaryNav';
import QuestionBankHeader from '../../components/QuestionBankHeader';
import ResourceSecondaryNav from '../../components/ResourceSecondaryNav';
import StructuredEssayPaperHeader from '../../components/StructuredEssayPaperHeader';
import StructuredPapersTab from '../../components/StructuredPapersTab';
import TRQuestionTab from '../../components/TRQuestionTab';
import {
  RESOURCE_MENU_ITEMS,
  RESOURCE_TAB,
  RESOURCE_TAB_SUB_PAPERS,
  RESOURCE_TAB_SUB_QB,
  SCREEN,
} from '../../constants';
import { useLabels } from '../../context/LabelContext';
import { useLessons } from '../../context/LessonsContext';
import { useQuestions } from '../../context/QuestionsContext';
import { useSubjects } from '../../context/SubjectsContext';
import { useUnits } from '../../context/UnitsContext';
import { useAppUser } from '../../context/UserContext';
import { TFCreateDTO, TFUpdateDTO } from '../../dtos/QuestionDTO';
import { PAPER_BREADCRUM_STEPS, ST_ES_PAPER_BREADCRUM_STEPS } from '../../enums/PaperBreadCrum';
import { QuestionTypes } from '../../enums/questionTypes';
import useSessionStorage from '../../hooks/useSessionStorage';
import { PaperData, QuestionData, ResourcePaperState } from '../../interfaces';
import LayoutNew from '../../layouts/LayoutNew';
import { Lesson } from '../../models/Lesson';
import { Unit } from '../../models/Unit';
import {
  getRole,
  sortLessonsByNameUsingContext,
  sortSubjectsByNameUsingContext,
  sortUnitsByNameUsingContext,
} from '../../utils';
import { validationResult } from '../../utils';
import ResourceUtility from '../../utils/ResourceUtility';
import { validateString } from '../../validation/Validation';
import Notification from './../../components/Notification';

const Resource = () => {
  const appUser = useAppUser();
  const appQuestions = useQuestions();
  const appSubjects = useSubjects();
  const appLessons = useLessons();
  const appUnits = useUnits();
  const appLabels = useLabels();

  const [state, setState] = useSessionStorage(SCREEN.RESOURCE, {
    currentTab: RESOURCE_TAB.QUESTIONBANK,
    currentSubTab: RESOURCE_TAB_SUB_QB.MCQ_QUESTIONS,
  });

  const [tabState, setTabState] = useState<{
    mode: 'CREATE' | 'VIEW' | 'PREVIEW' | 'EDIT';
    questionData: QuestionData | null;
  }>({
    mode: 'VIEW',
    questionData: {
      questionId: '',
      subjectId: '',
      lessonId: '',
      unitId: '',
      grade: '',
      createdBy: '',
      labelIds: [],
    },
  });

  const [paperNaviationData, setPaperNaviationData] = useState<{
    mode: 'CREATE' | 'VIEW' | 'PREVIEW' | 'EDIT';
    step: PAPER_BREADCRUM_STEPS;
    paperData: PaperData | null;
    paperComplexity: { complexityArray: number[]; isComplexityCorrect: boolean };
  }>({
    mode: 'VIEW',
    step: PAPER_BREADCRUM_STEPS.STEP_ONE,
    paperData: {
      paperId: '',
      subjectId: '',
      lessonId: '',
      lessonIds: [],
      unitId: '',
      unitIds: [],
      grade: '',
      createdBy: '',
    },
    paperComplexity: { complexityArray: [], isComplexityCorrect: true },
  });

  //---------------------------------------------//
  const [structuredEssayPaperNaviationData, setStructuredEssayPaperNaviationData] = useState<{
    mode: 'CREATE' | 'VIEW' | 'PREVIEW' | 'EDIT';
    step: ST_ES_PAPER_BREADCRUM_STEPS;
    paperData: PaperData | null;
  }>({
    mode: 'VIEW',
    step: ST_ES_PAPER_BREADCRUM_STEPS.STEP_ONE,
    paperData: {
      paperId: '',
      subjectId: '',
      lessonId: '',
      lessonIds: [],
      unitId: '',
      unitIds: [],
      grade: '',
      createdBy: '',
    },
  });
  //-----------------------------------------//

  const [paperState, setPaperState] = useState<ResourcePaperState>(
    ResourceUtility.INITIAL_STATE_PAPER
  );

  const changeCurrentTabHandler = (tab: string) => {
    changeToDefaultTabHandler('VIEW', null);
    const isItemBelongQB = state.currentTab === RESOURCE_TAB.QUESTIONBANK;
    const isItemBelongPaper = state.currentTab === RESOURCE_TAB.PAPERBANK;

    const isSubItemBelongQB =
      state.currentSubTab === RESOURCE_TAB_SUB_QB.MCQ_QUESTIONS ||
      state.currentSubTab === RESOURCE_TAB_SUB_QB.TRUE_FALSE_QUESTIONS;

    const isSubItemBelongPaper =
      state.currentSubTab === RESOURCE_TAB_SUB_PAPERS.MCQ_PAPERS ||
      state.currentSubTab === RESOURCE_TAB_SUB_PAPERS.TRUE_FALSE_PAPERS ||
      state.currentSubTab === RESOURCE_TAB_SUB_PAPERS.STRUCTURED_PAPERS ||
      state.currentSubTab === RESOURCE_TAB_SUB_PAPERS.ESSAY_PAPERS;

    if (tab === RESOURCE_TAB.QUESTIONBANK) {
      let none = '';
      if (
        RESOURCE_MENU_ITEMS.find((item) => item.text === state.currentTab)?.active &&
        isItemBelongQB
      ) {
        none = '';
      } else {
        none = tab;
      }

      if (!isSubItemBelongQB) {
        return setState({
          ...state,
          currentTab: none,
          currentSubTab: RESOURCE_TAB_SUB_QB.MCQ_QUESTIONS,
        });
      }
      setState({ ...state, currentTab: none });
    }

    if (tab === RESOURCE_TAB.PAPERBANK) {
      let none = '';
      if (
        RESOURCE_MENU_ITEMS.find((item) => item.text === state.currentTab)?.active &&
        isItemBelongPaper
      ) {
        none = '';
      } else {
        none = tab;
      }

      if (!isSubItemBelongPaper) {
        return setState({
          ...state,
          currentTab: none,
          currentSubTab: RESOURCE_TAB_SUB_PAPERS.MCQ_PAPERS,
        });
      }
      setState({ ...state, currentTab: none });
    }
  };

  const changeCurrentSubTabHandler = (subTab: string) => {
    setState({ ...state, currentSubTab: subTab });
    changeToDefaultTabHandler('VIEW', null);
    changepaperNaviationDataHandler({
      mode: 'VIEW',
      step: PAPER_BREADCRUM_STEPS.STEP_ONE,
      paperData: null,
      paperComplexity: { complexityArray: [], isComplexityCorrect: true },
    });
    //-------------------------------------------//
    changeStructuredEssayPaperNaviationDataHandler({
      mode: 'VIEW',
      step: ST_ES_PAPER_BREADCRUM_STEPS.STEP_ONE,
      paperData: null,
    });

    setPaperState(ResourceUtility.INITIAL_STATE_PAPER);
  };

  const changeToDefaultTabHandler = (
    value: 'CREATE' | 'VIEW' | 'PREVIEW' | 'EDIT',
    questionData: QuestionData | null
  ) => {
    if (value === 'CREATE') {
      setResourceState(ResourceUtility.INITIAL_STATE);
    }

    setTabState({ mode: value, questionData: questionData });
  };

  const changepaperNaviationDataHandler = (para: {
    mode: 'CREATE' | 'VIEW' | 'PREVIEW' | 'EDIT';
    step: PAPER_BREADCRUM_STEPS;
    paperData: PaperData | null;
    paperComplexity: { complexityArray: number[]; isComplexityCorrect: boolean };
  }) => {
    // if (para.mode === 'CREATE') {
    //   setPaperState(ResourceUtility.INITIAL_STATE_PAPER);
    // }

    setPaperNaviationData(para);
  };

  //---------------------------------------//
  const changeStructuredEssayPaperNaviationDataHandler = (para: {
    mode: 'CREATE' | 'VIEW' | 'PREVIEW' | 'EDIT';
    step: ST_ES_PAPER_BREADCRUM_STEPS;
    paperData: PaperData | null;
  }) => {
    setStructuredEssayPaperNaviationData(para);
  };
  //-------------------------------------//

  const changeTrueFalseAnswer = (value: boolean) => {
    setResourceState((ps: any) => {
      return { ...ps, trueFalseAnswer: { ...ps.complexity, value: value } };
    });
  };

  const questionData = tabState?.questionData;

  const [subjects, setSubjects] = useState<{ id: string; displayValue: string }[]>([]);
  const [lessons, setLessons] = useState<{ id: string; displayValue: string }[]>([]);
  const [units, setUnits] = useState<{ id: string; displayValue: string }[]>([]);
  const [labels, setLabels] = useState<{ id: string; displayValue: string }[]>([]);

  const [resourceState, setResourceState] = useState(ResourceUtility.INITIAL_STATE);

  useEffect(() => {
    const { isAdmin, isInstructor, isTeachingAssistant } = getRole(appUser.firestoreUser?.userRole);

    const subjectIdsArray = appSubjects.allSubjects
      .filter((sub) => sub.assignedUsers?.includes(appUser.firestoreUser?.uid || ''))
      .map((s) => ({ id: s.id, grade: s.grade, createdBy: s.createdBy }));

    let filteredSubjects = isAdmin
      ? appSubjects.allSubjects.filter((sub) => sub.grade === resourceState.grade.value.id)
      : isInstructor || isTeachingAssistant
      ? appSubjects.allSubjects.filter(
          (sub) =>
            (sub.grade === resourceState.grade.value.id &&
              sub.createdBy === questionData?.createdBy) ||
            (sub.grade === resourceState.grade.value.id &&
              sub.createdBy === appUser.firestoreUser?.uid) ||
            !!subjectIdsArray.filter(
              (value) => value.id === sub.id && value.grade === resourceState.grade.value.id
            ).length
        )
      : [];

    if (isInstructor || isTeachingAssistant) {
    }

    const sortedSubjects = sortSubjectsByNameUsingContext(filteredSubjects);

    const selectedSubject = sortedSubjects.find((item) => item.id === questionData?.subjectId);

    setResourceState((ps: any) => {
      return {
        ...ps,
        subject: {
          ...ps.subject,
          value: {
            id: selectedSubject?.id || '',
            displayValue: selectedSubject?.displayValue || '',
          },
        },
      };
    });
    setSubjects(sortedSubjects);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    questionData?.subjectId,
    questionData?.createdBy,
    appUser?.firestoreUser?.uid,
    resourceState.grade.value.id,
  ]);

  useEffect(() => {
    const { isAdmin, isInstructor, isTeachingAssistant } = getRole(appUser.firestoreUser?.userRole);

    const subjectIdsArray = appSubjects.allSubjects
      .filter((sub) => sub.assignedUsers?.includes(appUser.firestoreUser?.uid || ''))
      .map((s) => ({ id: s.id, grade: s.grade, createdBy: s.createdBy }));

    const filteredSubjects = isAdmin
      ? appSubjects.allSubjects.filter((sub) => sub.grade === paperState.grade.value.id)
      : isInstructor || isTeachingAssistant
      ? appSubjects.allSubjects.filter(
          (sub) =>
            (sub.grade === paperState.grade.value.id &&
              sub.createdBy === paperNaviationData.paperData?.createdBy) ||
            (sub.grade === paperState.grade.value.id &&
              sub.createdBy === structuredEssayPaperNaviationData.paperData?.createdBy) ||
            (sub.grade === paperState.grade.value.id &&
              sub.createdBy === appUser?.firestoreUser?.uid) ||
            !!subjectIdsArray.filter(
              (value) => value.id === sub.id && value.grade === paperState.grade.value.id
            ).length
        )
      : [];

    const sortedSubjects = sortSubjectsByNameUsingContext(filteredSubjects);

    const selectedSubject = sortedSubjects.find(
      (item) =>
        item.id === paperNaviationData.paperData?.subjectId ||
        item.id === structuredEssayPaperNaviationData.paperData?.subjectId
    );

    setPaperState((ps: any) => {
      return {
        ...ps,
        subject: {
          ...ps.subject,
          value: {
            id: selectedSubject?.id || '',
            displayValue: selectedSubject?.displayValue || '',
          },
        },
      };
    });

    setSubjects(sortedSubjects);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appUser?.firestoreUser?.uid,
    paperNaviationData.paperData?.subjectId,
    paperNaviationData.paperData?.createdBy,
    structuredEssayPaperNaviationData.paperData?.subjectId,
    structuredEssayPaperNaviationData.paperData?.createdBy,
    paperState.grade.value.id,
  ]);

  useEffect(() => {
    let filteredLessons: Lesson[] = [];

    filteredLessons = appLessons.lessons.filter(
      (les) => les.subjectId === resourceState.subject.value.id
    );

    const sortedLessons = sortLessonsByNameUsingContext(filteredLessons);

    const selectedLesson = sortedLessons.find((item) => item.id === questionData?.lessonId);

    const filteredUnits = appUnits.units.filter((u) => u.lessonId === selectedLesson?.id);

    const sortedUnits = sortUnitsByNameUsingContext(filteredUnits);

    const selectedLabels = appLabels.labelList
      .filter((item) => questionData?.labelIds.includes(item.id))
      .map((value) => ({ id: value.id, displayValue: value.id }));

    const labels = appLabels.labelList
      .filter((lab) => lab.subjectId === resourceState.subject.value.id)
      .map((lab) => ({ displayValue: lab.labelName, id: lab.id }));

    setResourceState((ps: any) => {
      return {
        ...ps,
        lesson: {
          ...ps.lesson,
          value: {
            id: selectedLesson?.id || '',
            displayValue: selectedLesson?.displayValue || '',
          },
        },
        labels: {
          ...ps.labels,
          value: selectedLabels,
        },
      };
    });

    setLessons(sortedLessons);
    setUnits(sortedUnits);
    setLabels(labels);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    resourceState.subject.value.id,
    questionData?.lessonId,
    questionData?.createdBy,
    appUser?.firestoreUser?.uid,
  ]);

  useEffect(() => {
    let filteredUnits;
    if (resourceState.lesson.value?.id) {
      filteredUnits = appUnits.units.filter((u) => u.lessonId === resourceState.lesson.value?.id);
    } else {
      filteredUnits = appUnits.units.filter((u) => u.lessonId === questionData?.lessonId);
    }

    const sortedUnits = sortUnitsByNameUsingContext(filteredUnits);

    const selectedUnit = sortedUnits.find((val) => val.id === questionData?.unitId);

    setResourceState((ps: any) => {
      return {
        ...ps,
        unit: {
          ...ps.unit,
          value: {
            id: selectedUnit?.id || '',
            displayValue: selectedUnit?.displayValue || '',
          },
          error: '',
        },
      };
    });

    setUnits(sortedUnits);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceState.lesson.value.id, questionData?.lessonId]);

  useEffect(() => {
    let filteredLessons: Lesson[] = [];

    filteredLessons = appLessons.lessons.filter(
      (les) => les.subjectId === paperState.subject.value.id
    );

    const sortedLessons = sortLessonsByNameUsingContext(filteredLessons);

    const selectedLessons = sortedLessons.filter(
      (item) =>
        paperNaviationData.paperData?.lessonIds.includes(item.id) ||
        structuredEssayPaperNaviationData.paperData?.lessonIds.includes(item.id)
    );

    const selectedLabels = appLabels.labelList
      .filter((l) => paperNaviationData.paperData?.labels?.includes(l.id))
      .map((l) => {
        return {
          id: l.id,
          displayValue: l.labelName,
        };
      });

    setPaperState((ps) => {
      return {
        ...ps,
        lessons: { ...ps.lessons, value: selectedLessons },
        labels: {
          ...ps.labels,
          value: selectedLabels,
        },
      };
    });

    setLessons(sortedLessons);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    paperState.subject.value.id,
    paperNaviationData.paperData?.lessonIds,
    paperNaviationData.paperData?.createdBy,
    paperNaviationData.paperData?.paperId,
    structuredEssayPaperNaviationData.paperData?.paperId,
    structuredEssayPaperNaviationData.paperData?.lessonIds,
    structuredEssayPaperNaviationData.paperData?.createdBy,
    appUser?.firestoreUser?.uid,
  ]);

  useEffect(() => {
    let filteredUnits: Unit[] = [];

    if (paperState.lessons.value.map((p) => p.id)) {
      filteredUnits = appUnits.units.filter((u) =>
        paperState.lessons.value.map((p) => p.id).includes(u.lessonId)
      );
    } else if (structuredEssayPaperNaviationData.paperData?.lessonIds) {
      filteredUnits = appUnits.units.filter((u) =>
        structuredEssayPaperNaviationData.paperData?.lessonIds.includes(u.lessonId)
      );
    }

    // const filteredUnits = appUnits.units.filter(
    //   (u) =>
    //     // paperNaviationData?.paperData?.lessonIds.includes(u.lessonId) ||
    //     paperState.lessons.value.map((p) => p.id).includes(u.lessonId) ||
    //     structuredEssayPaperNaviationData.paperData?.lessonIds.includes(u.lessonId)
    // );

    const sortedUnits = sortUnitsByNameUsingContext(filteredUnits);

    const selectedUnits = sortedUnits.filter(
      (val) =>
        paperNaviationData?.paperData?.unitIds.includes(val.id) ||
        paperState.units.value.map((u) => u.id).includes(val.id || '') ||
        structuredEssayPaperNaviationData.paperData?.unitIds.includes(val.id)
    );

    setPaperState((ps: any) => {
      return {
        ...ps,
        units: {
          ...ps.units,
          value: selectedUnits,
          error: '',
        },
      };
    });

    setUnits(sortedUnits);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    paperState.lessons.value,
    paperNaviationData?.paperData?.lessonIds,
    structuredEssayPaperNaviationData.paperData?.lessonIds,
  ]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (tabState?.questionData?.questionId) {
        ResourceUtility.getQuestionByIdAndSetState(
          tabState?.questionData?.questionId,
          setResourceState
        );
      }
    }

    return () => {
      isMounted = false;
    };
  }, [tabState?.questionData?.questionId]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (paperNaviationData.paperData?.paperId) {
        ResourceUtility.getPaperByIdAndSetState(
          paperNaviationData.paperData?.paperId,
          setPaperState
        );
      }
    }

    return () => {
      isMounted = false;
    };
  }, [paperNaviationData.paperData?.paperId]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (structuredEssayPaperNaviationData.paperData?.paperId) {
        ResourceUtility.getStructuredEssayPaperByIdAndSetState(
          structuredEssayPaperNaviationData.paperData?.paperId,
          setPaperState
        );
      }
    }
    return () => {
      isMounted = false;
    };
  }, [structuredEssayPaperNaviationData.paperData?.paperId]);

  const answerTextChangeHandler = (txt: string, idx?: number) => {
    if (idx === 0 || idx) {
      if (resourceState.answers.value) {
        const answerArray = resourceState.answers.value && [...resourceState.answers.value];

        const item = answerArray[idx];
        item.answerText = txt;

        setResourceState((ps) => {
          return { ...ps, answers: { ...ps.answers, value: [...answerArray] } };
        });
      }
    }
  };

  const changeCorrectAnswerHandler = (idx: number) => {
    if (resourceState.answers.value) {
      const answerArray = resourceState.answers.value && [...resourceState.answers.value];

      const item = answerArray[idx];
      item.isCorrect = !item.isCorrect;

      setResourceState((ps) => {
        return { ...ps, answers: { ...ps.answers, value: [...answerArray] } };
      });
    }
  };

  const addNewAnswer = () => {
    if (resourceState.answers.value) {
      const answerArray = resourceState.answers.value && [...resourceState.answers.value];

      answerArray.push({ answerText: '', isCorrect: false, error: '' });
      setResourceState((ps) => {
        return { ...ps, answers: { ...ps.answers, value: [...answerArray] } };
      });
    }
  };

  const removeNewAnswer = (idx: number) => {
    if (resourceState.answers.value) {
      const answerArray: any = resourceState.answers.value && [...resourceState.answers.value];

      answerArray[idx] = null;

      setResourceState((ps) => {
        return { ...ps, answers: { ...ps.answers, value: [...answerArray.filter((i: any) => i)] } };
      });
    }
  };

  const questionTextChangeHandler = (e: string) => {
    setResourceState((ps) => ({ ...ps, question: { ...ps.question, value: e } }));
  };

  const changeComplexity = (complexity: number) => {
    setResourceState((ps: any) => {
      return { ...ps, complexity: { ...ps.complexity, value: complexity } };
    });
  };

  const createTF = firebase.functions().httpsCallable('createTF');
  const updateTF = firebase.functions().httpsCallable('updateTF');

  const scrollToTopRefMCQ = useRef<HTMLDivElement>(null);

  const scrollToTopRefTF = useRef<HTMLDivElement>(null);

  const scrollToTopHandlerMCQ = () => {
    if (scrollToTopRefMCQ.current) {
      scrollToTopRefMCQ.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const scrollToTopHandlerTF = () => {
    if (scrollToTopRefTF.current) {
      scrollToTopRefTF.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const saveHandlerTF = async (mustHaveUnits: boolean, hasUnits: boolean) => {
    setResourceState((ps) => {
      return { ...ps, loading: true };
    });

    const validationOutput = validationResult(
      {
        ...resourceState,
        unit: { ...resourceState.unit, validations: mustHaveUnits ? [validateString] : [] },
        loading: true,
      },
      ['answers']
    );

    setResourceState(validationOutput.state);

    if (!hasUnits && mustHaveUnits) {
      Notification({
        isSuccess: false,
        message:
          'Looks like you have not created units for this lesson. Please create a unit and try again',
        autoClose: 7000,
      });
      setResourceState((pS) => ({
        ...pS,
        loading: false,
      }));
      return;
    }

    if (!!validationOutput.formValidity) {
      setResourceState((pS) => ({
        ...pS,
        loading: false,
      }));
      return;
    }

    scrollToTopHandlerTF();

    if (questionData?.questionId) {
      const tfQuestion: TFUpdateDTO = {
        qid: questionData?.questionId,
        question: resourceState.question.value.replace(/&nbsp;/g, '').trim(),
        sourceOfQuestion: resourceState.sourceOfQuestion.value.id,
        complexity: resourceState.complexity.value,
        grade: resourceState.grade.value.id,
        subjectId: resourceState.subject.value.id,
        lessonId: resourceState.lesson.value.id,
        unitId: resourceState.unit.value.id,
        answer: resourceState.trueFalseAnswer.value,
        questionType: QuestionTypes.TRUEFALSE,
        source: resourceState.source.value,
        resolveGuide: resourceState.resolveGuide.value,
        labels: resourceState.labels.value.map((l) => l.id),
      };

      try {
        const response = await updateTF(tfQuestion);

        appQuestions.fetchQtns();

        changeToDefaultTabHandler('PREVIEW', {
          questionId: response.data.data.id,
          grade: '',
          lessonId: '',
          subjectId: '',
          unitId: '',
          createdBy: '',
          labelIds: [],
        });
        Notification({
          isSuccess: true,
          message: 'T/F question updated successfully',
        });
      } catch (e) {
        changeToDefaultTabHandler('VIEW', null);
        Notification({
          isSuccess: false,
          message: 'Failed to update question',
        });
      } finally {
        ResourceUtility.resetErrorStateToDefault(setResourceState);
      }
    } else {
      const tfQuestion: TFCreateDTO = {
        question: resourceState.question.value.replace(/&nbsp;/g, '').trim(),
        sourceOfQuestion: resourceState.sourceOfQuestion.value.id,
        complexity: resourceState.complexity.value,
        grade: resourceState.grade.value.id,
        subjectId: resourceState.subject.value.id,
        lessonId: resourceState.lesson.value.id,
        unitId: resourceState.unit.value.id,
        answer: resourceState.trueFalseAnswer.value,
        questionType: QuestionTypes.TRUEFALSE,
        source: resourceState.source.value,
        resolveGuide: resourceState.resolveGuide.value,
        authorName: appUser.firestoreUser?.username || '',
        labels: resourceState.labels.value.map((l) => l.id),
      };

      try {
        const response = await createTF(tfQuestion);

        appQuestions.fetchQtns();

        changeToDefaultTabHandler('PREVIEW', {
          questionId: response.data.data.id,
          grade: '',
          lessonId: '',
          subjectId: '',
          unitId: '',
          createdBy: '',
          labelIds: [],
        });
        Notification({
          isSuccess: true,
          message: 'T/F question created successfully',
        });
      } catch (e) {
        changeToDefaultTabHandler('VIEW', null);
        Notification({
          isSuccess: false,
          message: 'Failed to save question',
        });
      } finally {
        ResourceUtility.resetErrorStateToDefault(setResourceState);
      }
    }
  };

  const secondaryNav = (
    <ResourceSecondaryNav
      onCurrentTabChange={changeCurrentTabHandler}
      onCurrentSubTabChange={changeCurrentSubTabHandler}
      currentTab={state.currentTab}
      currentSubTab={state.currentSubTab}
      screen={SCREEN.RESOURCE}
    />
  );

  let body = null;

  if (RESOURCE_TAB_SUB_QB.MCQ_QUESTIONS === state.currentSubTab) {
    body = (
      <MCQQuestionTab
        state={resourceState}
        setState={setResourceState}
        answerTextChangeHandler={answerTextChangeHandler}
        changeCorrectAnswerHandler={changeCorrectAnswerHandler}
        addNewAnswer={addNewAnswer}
        removeNewAnswer={removeNewAnswer}
        questionTextChangeHandler={questionTextChangeHandler}
        onComplexityChange={changeComplexity}
        onChangeDefaultTab={changeToDefaultTabHandler}
        tabState={tabState}
        subjects={subjects}
        lessons={lessons}
        units={units}
        labels={labels}
        scrollToTopRef={scrollToTopRefMCQ}
      />
    );
  } else if (RESOURCE_TAB_SUB_QB.TRUE_FALSE_QUESTIONS === state.currentSubTab) {
    body = (
      <TRQuestionTab
        state={resourceState}
        setState={setResourceState}
        questionTextChangeHandler={questionTextChangeHandler}
        onComplexityChange={changeComplexity}
        onChange={changeTrueFalseAnswer}
        onChangeDefaultTab={changeToDefaultTabHandler}
        tabState={tabState}
        subjects={subjects}
        lessons={lessons}
        units={units}
        labels={labels}
        scrollToTopRef={scrollToTopRefTF}
      />
    );
  } else if (
    RESOURCE_TAB_SUB_PAPERS.MCQ_PAPERS === state.currentSubTab ||
    RESOURCE_TAB_SUB_PAPERS.TRUE_FALSE_PAPERS === state.currentSubTab
  ) {
    body = (
      <MCQTrueFalsePapersTab
        state={paperState}
        setState={setPaperState}
        paperNaviationData={paperNaviationData}
        setPaperNaviationData={changepaperNaviationDataHandler}
        subjects={subjects}
        lessons={lessons}
        units={units}
        currentSubTab={state.currentSubTab}
      />
    );
  } else if (RESOURCE_TAB_SUB_PAPERS.STRUCTURED_PAPERS === state.currentSubTab) {
    body = (
      <StructuredPapersTab
        state={paperState}
        setState={setPaperState}
        structuredEssayPaperNaviationData={structuredEssayPaperNaviationData}
        setStructuredEssayPaperNaviationData={changeStructuredEssayPaperNaviationDataHandler}
        subjects={subjects}
        lessons={lessons}
        units={units}
        currentSubTab={state.currentSubTab}
      />
    );
  } else if (RESOURCE_TAB_SUB_PAPERS.ESSAY_PAPERS === state.currentSubTab) {
    body = (
      <EssayPapersTab
        state={paperState}
        setState={setPaperState}
        structuredEssayPaperNaviationData={structuredEssayPaperNaviationData}
        setStructuredEssayPaperNaviationData={changeStructuredEssayPaperNaviationDataHandler}
        subjects={subjects}
        lessons={lessons}
        units={units}
        currentSubTab={state.currentSubTab}
      />
    );
  }

  const primaryNav = <PrimaryNav />;

  let header = null;
  const breadCrum = (
    <div className="d-sm-flex justify-content-between align-items-center resource-header-wrapper">
      <div className="resource-header d-none d-md-block">
        <BreadCrum className="d-none d-sm-flex" />
      </div>
    </div>
  );

  const mustHaveUnits = appLessons.lessons.find(
    (l) => l.id === resourceState.lesson.value.id
  )?.isLessonContainsUnits;

  const hasUnits = appUnits.units.filter((val) => val.lessonId === resourceState.lesson.value.id);

  if (RESOURCE_TAB_SUB_QB.MCQ_QUESTIONS === state.currentSubTab) {
    if (tabState.mode === 'CREATE' || tabState.mode === 'EDIT') {
      header = (
        <QuestionBankHeader
          onClick={() => {
            ResourceUtility.saveHandlerMCQ(
              setResourceState,
              resourceState,
              questionData,
              appQuestions,
              changeToDefaultTabHandler,
              appUser.firestoreUser?.username || '',
              !!mustHaveUnits,
              !!hasUnits.length,
              scrollToTopHandlerMCQ
            );
          }}
          name={tabState.mode === 'CREATE' ? 'Create MCQ Question' : 'Update MCQ Question'}
          questionIdPresent={!!tabState.questionData?.questionId}
          loading={resourceState.loading}
        />
      );
    } else if (tabState.mode === 'VIEW') {
      header = breadCrum;
    }
  } else if (RESOURCE_TAB_SUB_QB.TRUE_FALSE_QUESTIONS === state.currentSubTab) {
    if (tabState.mode === 'CREATE' || tabState.mode === 'EDIT') {
      header = (
        <QuestionBankHeader
          onClick={() => {
            saveHandlerTF(!!mustHaveUnits, !!hasUnits.length);
          }}
          name={
            tabState.mode === 'CREATE' ? 'Create True/False Question' : 'Update True/False Question'
          }
          questionIdPresent={!!tabState.questionData?.questionId}
          loading={resourceState.loading}
        />
      );
    } else if (tabState.mode === 'VIEW') {
      header = breadCrum;
    }
  } else if (
    (RESOURCE_TAB_SUB_PAPERS.MCQ_PAPERS === state.currentSubTab ||
      RESOURCE_TAB_SUB_PAPERS.TRUE_FALSE_PAPERS === state.currentSubTab) &&
    paperNaviationData.mode === 'CREATE'
  ) {
    if (paperNaviationData.step === PAPER_BREADCRUM_STEPS.STEP_ONE) {
      header = (
        <PaperHeader
          name={
            state.currentSubTab === RESOURCE_TAB_SUB_PAPERS.MCQ_PAPERS
              ? 'Create MCQ Paper'
              : state.currentSubTab === RESOURCE_TAB_SUB_PAPERS.TRUE_FALSE_PAPERS
              ? 'Create True/False Paper'
              : ''
          }
          active={PAPER_BREADCRUM_STEPS.STEP_ONE}
        />
      );
    } else if (paperNaviationData.step === PAPER_BREADCRUM_STEPS.STEP_TWO) {
      header = (
        <PaperHeader
          name={
            state.currentSubTab === RESOURCE_TAB_SUB_PAPERS.MCQ_PAPERS
              ? 'Create MCQ Paper'
              : state.currentSubTab === RESOURCE_TAB_SUB_PAPERS.TRUE_FALSE_PAPERS
              ? 'Create True/False Paper'
              : 'Create Structured Paper'
          }
          active={PAPER_BREADCRUM_STEPS.STEP_TWO}
        />
      );
    } else if (paperNaviationData.step === PAPER_BREADCRUM_STEPS.STEP_THREE) {
      header = (
        <PaperHeader
          name={
            state.currentSubTab === RESOURCE_TAB_SUB_PAPERS.MCQ_PAPERS
              ? 'Create MCQ Paper'
              : state.currentSubTab === RESOURCE_TAB_SUB_PAPERS.TRUE_FALSE_PAPERS
              ? 'Create True/False Paper'
              : ''
          }
          active={PAPER_BREADCRUM_STEPS.STEP_THREE}
        />
      );
    } else {
      header = breadCrum;
    }
  } else if (
    (RESOURCE_TAB_SUB_PAPERS.STRUCTURED_PAPERS === state.currentSubTab ||
      RESOURCE_TAB_SUB_PAPERS.ESSAY_PAPERS === state.currentSubTab) &&
    structuredEssayPaperNaviationData.mode === 'CREATE'
  ) {
    header = (
      <StructuredEssayPaperHeader
        name={
          state.currentSubTab === RESOURCE_TAB_SUB_PAPERS.STRUCTURED_PAPERS
            ? 'Create Structured Paper'
            : 'Create Essay Paper'
        }
        active={structuredEssayPaperNaviationData.step}
      />
    );
  } else if (
    ((RESOURCE_TAB_SUB_PAPERS.MCQ_PAPERS === state.currentSubTab ||
      RESOURCE_TAB_SUB_PAPERS.TRUE_FALSE_PAPERS === state.currentSubTab ||
      RESOURCE_TAB_SUB_PAPERS.STRUCTURED_PAPERS === state.currentSubTab ||
      RESOURCE_TAB_SUB_PAPERS.ESSAY_PAPERS === state.currentSubTab) &&
      paperNaviationData.mode === 'PREVIEW') ||
    structuredEssayPaperNaviationData.mode === 'PREVIEW'
  ) {
    header = null;
  } else if (
    (RESOURCE_TAB_SUB_PAPERS.MCQ_PAPERS === state.currentSubTab ||
      RESOURCE_TAB_SUB_PAPERS.TRUE_FALSE_PAPERS === state.currentSubTab) &&
    paperNaviationData.mode === 'EDIT'
  ) {
    header = (
      <PaperHeader
        name={
          RESOURCE_TAB_SUB_PAPERS.MCQ_PAPERS === state.currentSubTab
            ? 'Update MCQ Paper'
            : 'Update True/False Paper'
        }
        active={paperNaviationData.step}
      />
    );
  } else if (
    (RESOURCE_TAB_SUB_PAPERS.STRUCTURED_PAPERS === state.currentSubTab ||
      RESOURCE_TAB_SUB_PAPERS.ESSAY_PAPERS === state.currentSubTab) &&
    structuredEssayPaperNaviationData.mode === 'EDIT'
  ) {
    header = (
      <StructuredEssayPaperHeader
        name={
          state.currentSubTab === RESOURCE_TAB_SUB_PAPERS.STRUCTURED_PAPERS
            ? 'Update Structured Paper'
            : 'Update Essay Paper'
        }
        active={structuredEssayPaperNaviationData.step}
      />
    );
  } else {
    header = breadCrum;
  }

  return (
    <LayoutNew header={header} primaryNav={primaryNav} body={body} secondaryNav={secondaryNav} />
  );
};

export default Resource;
