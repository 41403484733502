import prodConfig from './production.json';

const config: Config = prodConfig;
export default config;

export interface Config {
  firebase: FirebaseConfig;
}

export interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
}

const prod = {
  baseUrl: 'https://ozon.scrapbooklms.com',
};

export const passwordResetURL = 'https://ozon.scrapbooklms.com';

// const dev = {
//   baseUrl: 'https://ozone-lms-dev.web.app'
// };
// const staging = {
//   baseUrl: 'https://ozon-lms-stage.web.app'
// };

export const AppConfig = prod;
