import React, { createContext, FC, useContext, useState } from 'react';

export interface ThemeContext {
  theme: 'dark' | 'light';
  changeTheme: (value: boolean) => void;
}

interface State {
  theme: 'dark' | 'light';
}

const initialContext: ThemeContext = {
  theme: 'dark',
  changeTheme: (value: boolean) => { },
};

const initialState: State = {
  theme: 'dark',
};

const Context = createContext<ThemeContext>(initialContext);

export const ThemeProvider: FC = ({ children }) => {
  const [state, setState] = useState(initialState);
  const changeTheme = (value: boolean) => {
    setState((pS) => ({
      ...pS,
      theme: value ? 'dark' : 'light',
    }));
  };

  return (
    <Context.Provider
      value={{
        ...state,
        changeTheme,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useAppTheme = (): ThemeContext => useContext(Context);
