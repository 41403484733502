import './PackageLayout.scss';
import Card from './AdminPackageCard';
import CourseContentInfo from './CourseContentInfo';
import MainMenu from './Menu-components/Mainmenu';
import Info from './PackageInfo';
import ExpandAll from './ExpandAll';
import {useEffect, useState} from 'react';
import {useFunctions} from 'reactfire';
import {Package} from '../models/Package';
import {useHistory} from 'react-router-dom';
import {useAppUser} from '../context/UserContext';
import StdPackageCard from './StdPackageCard';
import LoadingIndicator from '../components/LoadingIndicator';

const PackageLayout = (props: {editHandler: () => void; packageHandler: (data: any) => void}) => {
  const appUser = useAppUser();
  const getPackageDataRef = useFunctions().httpsCallable('getPackageData');
  const subscribePackageRef = useFunctions().httpsCallable('subscribePackage');
  const getPackageData = async (packageId: string, subjectId: string) => {
    const res = await getPackageDataRef({packageId, subjectId});
    return res.data;
  };
  const [isLoading, setIsloading] = useState(false);
  const history = useHistory();
  const packageId = history.location.pathname.split('/')[5];
  const subjectId = history.location.pathname.split('/')[3];
  let array: any[] | (() => any[]) = [];
  const [lessons, setLessons] = useState(array);
  const [units, setUnits] = useState(array);
  const [menuState, setMenuState] = useState(false);
  const [packageObject, setPackageObject] = useState<Package[]>([]);
  useEffect(() => {
    setIsloading(true);
    getPackageData(packageId, subjectId)
      .then((res) => {
        setLessons(array);
        setUnits(array);
        console.log(res.data.package);
        setPackageObject([res.data.package]);
        res.data.package.lessons.forEach((value: any) => {
          setLessons((prev) => [...prev, value]);
          setUnits((prev) => [...prev, value.units]);
        });

        setIsloading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const menuHandler = (state: boolean) => {
    setMenuState(state);
  };
  const onSubscribePackage = () => {
    subscribePackageRef({packageId, subjectId}).then((res) => {
      console.log(res.data);
      // setPackageObject([res.data.data.package]);
    }).catch((err) => {
      console.log(err);
    });
  }

  props.packageHandler(packageObject);
  const roleHandler = () => {
    if (appUser.firestoreUser?.userRole?.isStudent) {
      return (
        <StdPackageCard price={packageObject[0]?.price} image={packageObject[0]?.coverImage}  onClickSubscribe={onSubscribePackage}/>
      );
    } else if (
      appUser.firestoreUser?.userRole?.isAdmin ||
      appUser.firestoreUser?.userRole?.isInstructor
    ) {
      return (
        <Card
          packageId={packageId}
          price={packageObject[0]?.price}
          image={packageObject[0]?.coverImage}
          editHandler={props.editHandler}
        />
      );
    }
  };

  let examCount = packageObject[0]?.materials.exam.length;

  packageObject[0]?.lessons.forEach((value: any) => {
    examCount += value.materials.exam.length;
  });

  return (
    <>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <button
            className="backarrow-button package-info-back_btn"
            onClick={() => {
              history.goBack();
            }}
          >
            <svg
              width="11"
              height="14"
              viewBox="0 0 11 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.499999 7.86602C-0.166668 7.48112 -0.166667 6.51888 0.5 6.13397L9.5 0.937821C10.1667 0.552921 11 1.03405 11 1.80385L11 12.1962C11 12.966 10.1667 13.4471 9.5 13.0622L0.499999 7.86602Z"
                fill="#246BFD"
              />
            </svg>
          </button>
          <div className="section_main">
            <Info
              title={packageObject[0]?.title}
              description={packageObject[0]?.description}
              date={new Date(packageObject[0]?.endDate)}
              language={packageObject[0]?.language}
              grade={
                packageObject[0]?.materials?.exam[0]?.grade
                  ? packageObject[0]?.materials?.exam[0]?.grade
                  : 'Grade'
              }
            />
            {roleHandler()}
            {/* <Card price={packageObject[0]?.price} image={packageObject[0]?.coverImage} /> */}
            <CourseContentInfo
              lessonNo={lessons.length}
              unitsNo={units.flat().length}
              examCount={examCount}
            />
            <ExpandAll menuHandler={menuHandler} />
            <MainMenu
              // lessons={lessons}
              lessons={packageObject[0]?.lessons ? packageObject[0]?.lessons : []}
              units={units}
              menuState={menuState}
              globalExams={packageObject[0]?.materials.exam ? packageObject[0]?.materials.exam : []}
            />
          </div>
        </>
      )}
    </>
  );
};
export default PackageLayout;
