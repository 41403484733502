import React, { FC, Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFirestore } from 'reactfire';
import ExamCreationStepOne from '../../components/ExamCreationStepOne';
import ExamCreationStepThreeExtra from '../../components/ExamCreationStepThreeExtra';
import ExamCreationStepThreeLive from '../../components/ExamCreationStepThreeLive';
import ExamCreationStepThreePractise from '../../components/ExamCreationStepThreePractise';
import ExamCreationStepTwo from '../../components/ExamCreationStepTwo';
import LessonSecondaryNav from '../../components/LessonSecondaryNav';
import LoadingIndicator from '../../components/LoadingIndicator';
import PrimaryNav from '../../components/PrimaryNav';
import SubjectSecondaryNav from '../../components/SubjectSecondaryNav';
import UnitSecondaryNav from '../../components/UnitSecondaryNav';
import { useLessons } from '../../context/LessonsContext';
import { useSubjects } from '../../context/SubjectsContext';
import { useUnits } from '../../context/UnitsContext';
import { EXAM_CREATION_STEP } from '../../enums';
import { ExamType } from '../../enums/ExamType';
import { examCreationStateInitialValue, EXAM_CREATION_STATE, PaperPayment } from '../../interfaces';
import LayoutNew from '../../layouts/LayoutNew';
import { Exam } from '../../models/Exam';

interface Props {
  level: 'SUBJECT' | 'LESSON' | 'UNIT';
}

const AdminUpdateExamScreen: FC<Props> = (props) => {
  const store = useFirestore();

  const [examStep, setExamStep] = useState(EXAM_CREATION_STEP.ONE);

  const [examCreationState, setExamCreationState] = useState<EXAM_CREATION_STATE>(
    examCreationStateInitialValue
  );

  const [paperPayments, setPaperPayments] = useState<PaperPayment[]>([]);

  const params = useParams() as { examId: string };

  const appSubjects = useSubjects();
  const appLessons = useLessons();
  const appUnits = useUnits();

  console.log('examCreationState ', examCreationState);

  const [unitsBug, setUnitBug] = useState<
    {
      displayValue: string;
      id: string;
    }[]
  >([]);

  useEffect(() => {
    store
      .collection('exams')
      .doc(params?.examId || '')
      .get()
      .then((response) => {
        const exam = { id: response.id, ...response.data() } as Exam;

        console.log('EEEEECCCXXAAMM ', exam);

        //setting the lesson
        const savedListOfLessons = appLessons.lessons
          .filter((l) => exam.lessonIds?.includes(l.id || ''))
          .map((l) => ({ displayValue: l.name, id: l.id || '' }));

        //setting the units
        const savedListOfUnits = appUnits.units
          .filter((u) => exam.unitIds?.includes(u.id || ''))
          .map((u) => ({ displayValue: u.name, id: u.id || '' }));

        console.log('savedListOfUnits ', savedListOfUnits);

        const subjectName =
          appSubjects.allSubjects.find((sub) => sub.id === exam.subjectId)?.name || '';
        // const lessonName = appLessons.lessons.find((less) => less.id === exam.lessonId)?.name || '';

        setUnitBug(savedListOfUnits);

        setExamCreationState((ps) => {
          return {
            ...ps,
            id: exam.id || '',
            grade: {
              ...ps.grade,
              value: { displayValue: exam.grade, id: exam.grade },
            },
            subject: {
              ...ps.subject,
              value: { displayValue: subjectName, id: exam.subjectId || '' },
            },
            units: { ...ps.units, value: savedListOfUnits },
            lessons: { ...ps.lessons, value: savedListOfLessons },
            examTitle: { ...ps.examTitle, value: exam.examTitle },
            description: { ...ps.description, value: exam.description || '' },
            papers: { ...ps.papers, value: exam.papers || [] },
            price: { ...ps.price, value: exam.price + '' },
            gradingType: {
              ...ps.gradingType,
              value: {
                displayValue: exam.gradingType || 'Percentage',
                id: exam.gradingType || 'Percentage',
              },
            },
            startDate: {
              ...ps.startDate,
              value: exam.startDate?.toDate() || new Date(),
            },
            startTime: {
              ...ps.startTime,
              value: {
                hours: exam.startTime?.split(':')[0] || '00',
                minutes: exam.startTime?.split(':')[1] || '00',
              },
            },
            endDate: {
              ...ps.endDate,
              value: exam.endDate?.toDate() || new Date(),
            },
            endTime: {
              ...ps.endTime,
              value: {
                hours: exam.endTime?.split(':')[0] || '00',
                minutes: exam.endTime?.split(':')[1] || '00',
              },
            },
            examDuration: {
              ...ps.examDuration,
              value: exam.examDuration || '00h 00m',
            },
            overallMark: {
              ...ps.overallMark,
              value: exam.overallMark?.toString() || '0',
            },
            examType: {
              ...ps.examType,
              value: exam.examType,
            },
            beginTime: exam.beginTime ? new Date(exam.beginTime) : new Date(),
            finishTime: exam.finishTime ? new Date(exam.finishTime) : new Date(),
          };
        });

        const pPayments = exam.papers.map((p) => ({
          paperId: p.id,
          payment: { amount: p.taPayment?.amount, isFree: p.taPayment?.isFree },
        }));

        setPaperPayments(pPayments);
      })
      .catch(() => {});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appSubjects.allSubjects, appLessons.lessons, appUnits.units]);

  useEffect(() => {
    setExamCreationState((ps) => {
      return {
        ...ps,
        units: { ...ps.units, value: unitsBug },
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [examCreationState.lessons.value.length]);

  //   const examType = useLocation().pathname.split('/').pop();
  let text = '';
  if (examCreationState.examType.value === ExamType.EXTRA_EXAM) {
    text = 'Update Extra Exam';
  } else if (examCreationState.examType.value === ExamType.LIVE_EXAM) {
    text = 'Update Live Exam';
  } else if (examCreationState.examType.value === ExamType.PRACTICE_EXAM) {
    text = 'Update Practice Exam';
  }

  let primaryNavigation = <PrimaryNav />;
  let secondaryNavigation =
    props.level === 'SUBJECT' ? (
      <SubjectSecondaryNav />
    ) : props.level === 'LESSON' ? (
      <LessonSecondaryNav />
    ) : (
      <UnitSecondaryNav />
    );

  let appHeader = (
    <Fragment>
      <div className="text-white">{text}</div>
      {examCreationState.isLoading.value ? <LoadingIndicator /> : null}
    </Fragment>
  );

  useEffect(() => {
    setExamStep(EXAM_CREATION_STEP.ONE);
  }, []);

  const moveToFirstStepHandler = () => {
    setExamStep(EXAM_CREATION_STEP.ONE);
  };

  const moveToSecondStepHandler = () => {
    setExamStep(EXAM_CREATION_STEP.TWO);
  };

  const moveToThirdStepHandler = () => {
    setExamStep(EXAM_CREATION_STEP.THREE);
  };

  let appBody = (
    <ExamCreationStepOne
      state={examCreationState}
      setState={setExamCreationState}
      onNext={moveToSecondStepHandler}
      hierarchyLevel="NONE"
    />
  );

  if (examStep === EXAM_CREATION_STEP.TWO) {
    appBody = (
      <ExamCreationStepTwo
        state={examCreationState}
        setState={setExamCreationState}
        onNext={moveToThirdStepHandler}
        onBack={moveToFirstStepHandler}
      />
    );
  } else if (
    examStep === EXAM_CREATION_STEP.THREE &&
    examCreationState.examType.value === ExamType.EXTRA_EXAM
  ) {
    appBody = (
      <ExamCreationStepThreeExtra
        update={true}
        state={examCreationState}
        setState={setExamCreationState}
        onBack={moveToSecondStepHandler}
        paperPayments={paperPayments}
      />
    );
  } else if (
    examStep === EXAM_CREATION_STEP.THREE &&
    examCreationState.examType.value === ExamType.LIVE_EXAM
  ) {
    appBody = (
      <ExamCreationStepThreeLive
        update={true}
        state={examCreationState}
        setState={setExamCreationState}
        onBack={moveToSecondStepHandler}
        paperPayments={paperPayments}
      />
    );
  } else if (
    examStep === EXAM_CREATION_STEP.THREE &&
    examCreationState.examType.value === ExamType.PRACTICE_EXAM
  ) {
    appBody = (
      <ExamCreationStepThreePractise
        update={true}
        state={examCreationState}
        setState={setExamCreationState}
        onBack={moveToSecondStepHandler}
        paperPayments={paperPayments}
      />
    );
  }
  return (
    <LayoutNew
      primaryNav={primaryNavigation}
      secondaryNav={secondaryNavigation}
      header={appHeader}
      body={appBody}
    />
  );
};

export default AdminUpdateExamScreen;
