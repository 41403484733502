import React, { FC } from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import BoxIcon from '../../../components/BoxIcon';
import { useAppContent } from '../context/ContentContext';
import { Links } from '../interfaces/LocalContentState';
import AddContentItemShell from '../layout/AddContentItemShell';
import { CONTENT_MEDIA_TYPE } from '../utils';
import ContentTextBox from './ContentTextBox';
import './LinksContent.scss';

interface Props {
  contentItemDroppableId: string;
  droppableId: string;
}

const LinksContent: FC<Props> = (props) => {
  const appContent = useAppContent();

  const linkData = appContent.contentData
    .find((val) => val.droppableId === props.droppableId)
    ?.contentItems.find((val) => val.contentItemDroppableId === props.contentItemDroppableId) as {
    links: Links;
    mediaType: CONTENT_MEDIA_TYPE;
    contentItemDroppableId: string;
    component: any;
  };

  return (
    <AddContentItemShell
      componentName="Links"
      componentType={CONTENT_MEDIA_TYPE.LINKS}
      className="mt-4"
      droppableId={props.droppableId}
      contentItemDroppableId={props.contentItemDroppableId}
    >
      <div className="link-content">
        {linkData.links.linkGroups.map((link, idx) => (
          <div className="link-content__links" key={idx}>
            <ContentTextBox
              className="fn-margin"
              stateName="contentTitle"
              stateValue={link.ref.value}
              error={link.ref.error}
              placeHolder="Link Text"
              onChangeHandler={(e: any) => {
                appContent.changeLinkContentHandler(
                  props.droppableId,
                  props.contentItemDroppableId,
                  idx,
                  e.target.value,
                  'ref'
                );
              }}
              resetErrorHandler={() => {
                appContent.resetErrorLinkContentHandler(
                  props.droppableId,
                  props.contentItemDroppableId,
                  idx,
                  'ref'
                );
              }}
            />
            <ContentTextBox
              className="fn-margin"
              stateName="link"
              stateValue={link.url.value}
              error={link.url.error}
              placeHolder="Link"
              onChangeHandler={(e: any) => {
                appContent.changeLinkContentHandler(
                  props.droppableId,
                  props.contentItemDroppableId,
                  idx,
                  e.target.value,
                  'url'
                );
              }}
              resetErrorHandler={() => {
                appContent.resetErrorLinkContentHandler(
                  props.droppableId,
                  props.contentItemDroppableId,
                  idx,
                  'url'
                );
              }}
            />

            <div>
              <BoxIcon
                icon={FaTrashAlt}
                color="#fff"
                size={10}
                style={{ width: 27, height: 26, backgroundColor: '#F05A5A', cursor: 'pointer' }}
                onClick={() => {
                  if (linkData.links.linkGroups.length > 1) {
                    appContent.removeNewSubLinkSlotHandler(
                      props.droppableId,
                      props.contentItemDroppableId,
                      idx
                    );
                  }
                }}
              />
            </div>
          </div>
        ))}

        <button
          className="link-content__btn"
          onClick={() => {
            appContent.addNewSubLinkSlotHandler(props.droppableId, props.contentItemDroppableId);
          }}
        >
          Add Link
        </button>
      </div>
    </AddContentItemShell>
  );
};

export default LinksContent;
