import React, {createContext, FC, useContext, useState} from 'react';

export interface RestrictionsContext {
  isPhoneNumberPresent: boolean;
  setIsPhoneNumberPresent: (value: boolean) => void;
}

interface State {
  isPhoneNumberPresent: boolean;
}

const initialContext: RestrictionsContext = {
  isPhoneNumberPresent: false,
  setIsPhoneNumberPresent: (value: boolean) => {},
};

const initialState: State = {
  isPhoneNumberPresent: false,
};

const Context = createContext<RestrictionsContext>(initialContext);

export const RestrictionsProvider: FC = ({children}) => {
  const [state, setState] = useState(initialState);
  const setIsPhoneNumberPresent = (value: boolean) => {
    setState((pS) => ({
      ...pS,
      isPhoneNumberPresent: value,
    }));
  };

  return (
    <Context.Provider
      value={{
        ...state,
        setIsPhoneNumberPresent,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useAppRestrictions = (): RestrictionsContext => useContext(Context);
