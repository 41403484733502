import React, { FC, useEffect, useState } from 'react';
import { FaImage, FaWindowClose } from 'react-icons/fa';
import { useFunctions, useStorage } from 'reactfire';
import {
  validateString,
  validateOnlyUnderscoreAndHypenAllowed,
  validateSubjectNameAndLesson,
  validateStringSpaces,
} from '../validation/Validation';
import Modal from 'react-modal';
import './AddSubjectModal.scss';
import ImageField from './ImageField';
import { Subject } from '../models/Subject';
import { useAppUser } from '../context/UserContext';
import { useSubjects } from '../context/SubjectsContext';
import Notification from './../components/Notification';
import { validationResult } from '../utils';
import LoadingIndicator from './LoadingIndicator';
import TextInput from './TextInput';
import truncate from 'truncate';
import DropDown from './DropDown';
import { GRADES, STREAMS_OPTIONS } from '../constants';
import ClipLoader from 'react-spinners/ClipLoader';

interface Props {
  closeHandler: (resetFileState: () => void) => void;
  show: boolean;
  fetchSubjects?: () => void;
  selectedSubject?: Subject | null;
  removeSelectedSubject?: () => void;
  mode: 'EDIT' | 'CREATE';
  setUpdatedSubject?: (subject: Subject) => void;
  addSubject?: (subject: Subject) => void;
}

const AddSubjectModal: FC<Props> = (props) => {
  interface State {
    loading: boolean;
    subjectName: {
      value: string;
      error: string;
      validations: Function[];
      label: string;
      max: number;
    };
    grade: {
      value: { displayValue: string; id: string };
      error: '';
      validations: Function[];
      label: string;
      type: string;
    };
    year: {
      value: { displayValue: string; id: string };
      error: string;
      label: string;
      type: string;
    };
    stream: {
      value: { displayValue: string; id: string };
      error: string;
      label: string;
      type: string;
    };
    coverImage: {
      file: null | File;
      value: string;
      error: string;
      validations: Function[];
      label: string;
      loading: boolean;
    };
  }

  const initialState: State = {
    loading: false,
    subjectName: {
      value: '',
      error: '',
      validations: [
        validateString,
        validateSubjectNameAndLesson,
        validateOnlyUnderscoreAndHypenAllowed,
        validateStringSpaces,
      ],
      label: 'Subject name',
      max: 20,
    },
    grade: {
      value: { displayValue: '', id: '' },
      error: '',
      validations: [validateString],
      label: 'Grade',
      type: 'dropdown',
    },
    year: {
      value: { displayValue: '', id: '' },
      error: '',
      label: 'Year',
      type: 'dropdown',
    },
    stream: { value: { displayValue: '', id: '' }, error: '', label: 'Stream', type: 'dropdown' },
    coverImage: {
      file: null,
      value: '',
      error: '',
      validations: [validateString],
      label: 'Cover image',
      loading: false,
    },
  };

  const [state, setState] = useState(initialState);

  const createSubjectRef = useFunctions().httpsCallable('createSubject');
  const updateSubjectRef = useFunctions().httpsCallable('updateSubject');
  const appUser = useAppUser();
  const appSubjects = useSubjects();

  const storage = useStorage();

  const resetModalState = () => {
    setState(initialState);
  };

  const submitHandler = async () => {
    const validationOutput = validationResult({
      ...state,
      loading: true,
      stream: {
        ...state.stream,
        validations: state.grade.value.id === 'A/L' ? [validateString] : [],
      },
      year: {
        ...state.year,
        validations: state.grade.value.id === 'A/L' ? [validateString] : [],
      },
    });

    setState(validationOutput.state);
    let subjectCreateFormValidty = true;

    //guard 1
    if (!!validationOutput.formValidity) {
      subjectCreateFormValidty = false;
    }

    if (!subjectCreateFormValidty) {
      setState((pS) => ({
        ...pS,
        loading: false,
      }));
      return;
    }

    try {
      const sub = await createSubjectRef({
        name: state.subjectName.value,
        lowerCaseName: state.subjectName.value.toLowerCase(),
        authorName: appUser.firestoreUser?.username,
        coverImage: state.coverImage.value || '',
        grade: state.grade.value.id,
        year: state.year.value.id,
        stream: state.stream.value.id,
      });

      Notification({
        isSuccess: true,
        message: 'Subject created successfully',
      });
      setState(initialState);
      appSubjects.addSubject(sub.data.data);
      props.addSubject && props.addSubject(sub.data.data);
    } catch (e: any) {
      console.log(e.message);

      Notification({
        isSuccess: false,
        message: `${e.message}`,
      });
      setState(initialState);
    } finally {
      setState((pS) => ({
        ...pS,
        loading: false,
      }));
      props.closeHandler(resetModalState);
    }
  };

  const updateSubjectHandler = async () => {
    const validationOutput = validationResult({
      ...state,
      loading: true,
      stream: {
        ...state.stream,
        validations: state.grade.value.id === 'A/L' ? [validateString] : [],
      },
      year: {
        ...state.year,
        validations: state.grade.value.id === 'A/L' ? [validateString] : [],
      },
    });

    setState(validationOutput.state);
    let subjectCreateFormValidty = true;

    //guard 1
    if (!!validationOutput.formValidity) {
      subjectCreateFormValidty = false;
    }

    if (!subjectCreateFormValidty) {
      setState((pS) => ({
        ...pS,
        loading: false,
      }));
      return;
    }

    try {
      const sub = await updateSubjectRef({
        id: props.selectedSubject?.id,
        name: state.subjectName.value,
        lowerCaseName: state.subjectName.value.toLowerCase(),
        coverImage: state.coverImage.value || '',
        grade: state.grade.value.id,
        year: state.year.value.id,
        stream: state.stream.value.id,
      });

      Notification({
        isSuccess: true,
        message: 'Subject updated successfully',
      });
      setState(initialState);
      appSubjects.updateSubject(sub.data.data);
      props.setUpdatedSubject && props.setUpdatedSubject(sub.data.data);
    } catch (e: any) {
      console.log(e.message);

      Notification({
        isSuccess: false,
        message: `${e.message}`,
      });
      setState(initialState);
    } finally {
      setState((pS) => ({
        ...pS,
        loading: false,
      }));
      props.closeHandler(resetModalState);
    }
  };

  const types = ['image/png', 'image/jpeg'];

  const changeHandler = (e: any) => {
    let selected = e.target.files[0];

    if (selected && types.includes(selected.type)) {
      const size = selected.size / 1024 / 1024;
      if (size > 1) {
        Notification({
          isSuccess: false,
          message: 'File size should be less than 1 MB',
        });
        return;
      }

      const storageRef = storage
        .ref()
        .child(`subjectImages/${appUser.fireUser?.uid}_${+new Date()}`);

      let subjectURL: any = null;

      setState((ps) => ({
        ...ps,
        coverImage: { ...ps.coverImage, loading: true },
      }));

      storageRef.put(selected).on(
        'state_changed',
        (snap) => {
          let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
          console.log('upload percentage ', percentage);
        },
        (err) => {
          Notification({
            isSuccess: false,
            message: 'Failed to upload image. ',
          });
          setState((ps) => ({
            ...ps,
            coverImage: {
              ...ps.coverImage,
              file: null,
              error: 'Failed to upload image',
              value: '',
              loading: false,
            },
          }));
        },
        async () => {
          subjectURL = await storageRef.getDownloadURL();

          setState((ps) => ({
            ...ps,
            coverImage: {
              ...ps.coverImage,
              file: selected,
              error: '',
              value: subjectURL || '',
              loading: false,
            },
          }));
        }
      );
    } else {
      setState((ps) => ({
        ...ps,
        coverImage: {
          ...ps.coverImage,
          file: null,
          error: 'Please select an image file (png or jpeg)',
          value: '',
        },
      }));
    }
  };

  useEffect(() => {
    if (state.grade.value.id !== 'A/L') {
      setState((ps) => {
        return {
          ...ps,
          stream: props.selectedSubject
            ? {
                ...ps.stream,
                value: {
                  displayValue: props.selectedSubject.stream,
                  id: props.selectedSubject.stream,
                },
              }
            : { ...ps.stream, value: { displayValue: '', id: '' } },
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.grade.value.id]);

  useEffect(() => {
    if (props.selectedSubject) {
      setState((ps) => {
        return {
          ...ps,
          subjectName: { ...ps.subjectName, value: props.selectedSubject?.name || '' },
          grade: {
            ...ps.grade,
            value: {
              displayValue: props.selectedSubject?.grade || '',
              id: props.selectedSubject?.grade || '',
            },
          },
          year: {
            ...ps.year,
            value: {
              displayValue: props.selectedSubject?.year || '',
              id: props.selectedSubject?.year || '',
            },
          },
          stream: {
            ...ps.stream,
            value: {
              displayValue: props.selectedSubject?.stream || '',
              id: props.selectedSubject?.stream || '',
            },
          },
          coverImage: { ...ps.coverImage, value: props.selectedSubject?.coverImage || '' },
        };
      });
    }

    return () => {
      props.removeSelectedSubject && props.removeSelectedSubject();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedSubject]);

  return (
    <Modal
      isOpen={props.show}
      contentLabel="Example Modal"
      className="add-subject-modal"
      overlayClassName="r-overlay"
      ariaHideApp={false}
      closeTimeoutMS={500}
    >
      <div className="add-subject-modal__header">
        {props.mode === 'EDIT' ? 'Update Subject' : 'Add Subject'}
        <FaWindowClose
          color="#FF4C6C"
          size={20}
          style={
            !(state.loading || state.coverImage.loading)
              ? { cursor: 'pointer' }
              : { cursor: 'default' }
          }
          onClick={() => {
            if (!(state.loading || state.coverImage.loading)) {
              props.closeHandler(resetModalState);
            }
          }}
        />
      </div>
      {state.loading && <LoadingIndicator />}
      <div className="add-subject-modal__main">
        <div className="add-subject-modal-inputs">
          <TextInput
            style={{ marginTop: 7 }}
            stateName="subjectName"
            stateValue={state.subjectName.value}
            state={state}
            setState={setState}
            error={state.subjectName.error}
            placeHolder="Subject name"
          />

          <ImageField
            style={{ marginTop: 8 }}
            stateName="coverImage"
            stateValue={truncate(state.coverImage.file?.name || '', 15)}
            state={state}
            setState={setState}
            error={state.coverImage.error}
            placeHolder="Cover Image"
            fileLoading={state.coverImage.loading}
            formLoading={state.loading}
            onChange={changeHandler}
            disableTextField={{ pointerEvents: 'none' }}
          />

          <DropDown
            name="Year"
            noValueText="Select year"
            stateName="year"
            stateValue={state.year.value}
            state={state}
            setState={setState}
            style={{ marginTop: 8 }}
            optionsArray={[
              {
                displayValue: new Date().getFullYear().toString(),
                id: new Date().getFullYear().toString(),
              },
              {
                displayValue: (new Date().getFullYear() + 1).toString(),
                id: (new Date().getFullYear() + 1).toString(),
              },
              {
                displayValue: (new Date().getFullYear() + 2).toString(),
                id: (new Date().getFullYear() + 2).toString(),
              },
              {
                displayValue: (new Date().getFullYear() + 3).toString(),
                id: (new Date().getFullYear() + 3).toString(),
              },
            ]}
            error={state.year.error}
            disabled={!!props.selectedSubject}
          />

          <DropDown
            name="Grade"
            noValueText="Select grade"
            stateName="grade"
            stateValue={state.grade.value}
            state={state}
            setState={setState}
            optionsArray={GRADES}
            error={state.grade.error}
            style={{ marginTop: 8 }}
            disabled={!!props.selectedSubject}
          />

          {state.grade.value.id === 'A/L' ? (
            <DropDown
              name="Stream"
              noValueText="Select stream"
              stateName="stream"
              stateValue={state.stream.value}
              state={state}
              setState={setState}
              optionsArray={STREAMS_OPTIONS}
              error={state.stream.error}
              style={{ marginTop: 8 }}
              truncate={30}
              disabled={!!props.selectedSubject}
            />
          ) : null}
        </div>
        <div
          className="add-subject-modal-image position-relative"
          style={{ backgroundImage: `url(${state.coverImage.value})` }}
        >
          {!state.coverImage.value && (
            <FaImage
              color="#474A66"
              style={{ left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
              className="position-absolute"
              size={40}
            />
          )}
          {state.coverImage.loading && (
            <div
              style={{ left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
              className="position-absolute"
            >
              <ClipLoader color="#246bfd" loading={true} size={90} speedMultiplier={0.7} />
            </div>
          )}
        </div>
      </div>
      <button
        className={`add-subject-modal__btn ${
          state.loading || state.coverImage.loading ? 'btn-disable-add-sub' : ''
        }`}
        onClick={() => {
          if (!(state.loading || state.coverImage.loading)) {
            if (props.mode === 'EDIT') {
              updateSubjectHandler();
            } else {
              submitHandler();
            }
          }
        }}
      >
        {props.mode === 'EDIT' ? 'Update Subject' : 'Add Subject'}
      </button>
    </Modal>
  );
};

export default AddSubjectModal;
