import SearchBox from './SearchBox';
import {FaFilter} from 'react-icons/fa';
import React from 'react';
import './SearchWithFilter.scss';

export interface SearchProps {
  onSearch: (text: string) => void;
  placeHolderText: string;
  onFilterClick?: () => void;
  onClickLink?: () => void;
  linkText?: string;

}

const SearchComponent = (props: SearchProps) => {
  return (
    <div className='d-flex justify-content-between'>
      <div className='d-flex'>
        <SearchBox
          onSearch={props.onSearch}
          placeHolderText={props.placeHolderText}
        />
        {props.onFilterClick && (
          <>
            <button className='filter_button' onClick={() => {
            }}>
              Filters &nbsp;
              <FaFilter />
            </button>
          </>
        )}
      </div>
      {
        props.linkText && (
          <div className='d-flex align-items-center'>
            <div onClick={props.onClickLink} className='link_text'>
              {props.linkText}
            </div>
          </div>
        )
      }

    </div>
  );
};
export default SearchComponent;