import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import './CodesSubTabs.scss';

interface Props {
  activeTab: 'common' | 'bulk';
}

const CodesSubTabs: FC<Props> = (props) => {
  const history = useHistory();

  return (
    <div className="tabsAC mt-5">
      <div
        className={`tab ${props.activeTab === 'common' && 'tab_active'}  `}
        onClick={() => {
          history.push('/settings');
        }}
        style={{ padding: '0px' }}
      >
        Common Access Codes
      </div>

      <div
        className={`tab ${props.activeTab === 'bulk' && 'tab_active'}    `}
        onClick={() => {
          history.push('/settings/bulk');
        }}
        style={{ padding: '0px' }}
      >
        Bulk Access Codes
      </div>
    </div>
  );
};

export default CodesSubTabs;
