import { LocalContentState } from '../interfaces/LocalContentState';

export const maxContentItemDroppableId = (strArray: number[]) => {
  return Math.max(...strArray) + '';
};

export const isAllRead = (array: LocalContentState[], uid: string) => {
  let flag = true;
  for (let i = 0; i < array.length - 1; i++) {
    if (flag && !array[i].chapterReadCheckUids?.includes(uid)) {
      flag = false;
    }
  }

  return flag;
};

export const getStudentContentCompletedPercentage = (array: LocalContentState[], uid: string) => {
  let counter = 1;
  for (let i = 0; i < array.length; i++) {
    if (array[i].chapterReadCheckUids?.includes(uid)) {
      counter++;
    }
  }

  if (array.length) {
    return (counter / array.length) * 100;
  } else {
    return 0;
  }
};
