

class RoutingPaths {

    private _subjectId: string;
    private _lessonId: string;
    private _unitId: string | undefined;
    private _droppableId: string | undefined;
    private _contentItemDroppableId: string | undefined;


    constructor(subjectId: string, lessonId: string, unitId?: string, droppableId?: string, contentItemDroppableId?: string) {
        this._subjectId = subjectId;
        this._lessonId = lessonId;
        this._unitId = unitId;
        this._droppableId = droppableId
        this._contentItemDroppableId = contentItemDroppableId;
    }





    public get baseURL() {
        if (this._unitId) {
            return `/my-subjects/subject/${this._subjectId}/lesson/${this._lessonId}/unit/${this._unitId || "-"}/Content`;
        }

        return `/my-subjects/subject/${this._subjectId}/lesson/${this._lessonId}/Content`;
    }

    createContent = () => {
        return this.baseURL + "/Create"
    }

    updateContent = () => {
        return this.baseURL + `/Update/${this._droppableId}`
    }

    previewChapterContent = () => {
        return this.baseURL + `/Preview/id/${this._droppableId}`
    }

    previewContent = () =>{
        return this.baseURL + `/MainPreview`
    }



}


export default RoutingPaths;