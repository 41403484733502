import { Item } from '../interfaces/LocalContentState';

export const validateString = (text: string) => {
  if (text.trim()) {
    return true;
  }
  return false;
};

export const validateContentTitle = (contentTitle: Item) => {
  if (contentTitle.value.trim()) {
    if (!onlyLettersAndNumbers(contentTitle.value)) {
      if (contentTitle.value.length > 50) {
        return 'Content Title should not exceed 50 characters';
      } else if (contentTitle.value.length < 5) {
        return 'Content Title should contain at-least 5 characters';
      }

      return '';
    } else {
      return 'Content Title should not contain only special characters';
    }
  } else {
    return 'Content Title should not be empty';
  }
};

export const validateRef = (linkText: string) => {
  return validateString(linkText) ? '' : 'Link text is empty';
};

export const validateUrl = (url: string) => {
  return validateString(url) ? '' : 'Link is empty';
};

export const validateMaterialUrl = (url: string) => {
  return validateString(url) ? '' : 'Materials are empty';
};

export const validateText = (text: string) => {
  return validateString(text) ? '' : 'Text is empty';
};

export const validateVideoUrl = (text: string) => {
  return validateString(text) ? '' : 'No video is uploaded';
};

export const onlyLettersAndNumbers = (text: string) => {
  return /^[^a-zA-Z0-9]+$/.test(text);
};
