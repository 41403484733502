// import React from 'react';
// import { SuspenseWithPerf, preloadFunctions, useFirebaseApp } from 'reactfire';
// import './App.css';
// import Routes from './routes/Routes';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './styles/default.scss';
// import Loader from './components/Loader';
// import { UserProvider } from './context/UserContext';

// function App() {
//   // console.log('ENV', process.env);

//   // preloadFirestore({
//   //   setup: (firestore) => {
//   //     firestore().enablePersistence({synchronizeTabs: true});
//   //   },
//   // });

//   // uncomment for emulatoruse
//   const firebaseApp = useFirebaseApp();

//   // preloadAuth({
//   //   firebaseApp,
//   //   setup: (auth) => {
//   //     auth().useEmulator('http://localhost:9099');
//   //   },
//   // });

//   // preloadFirestore({
//   //   firebaseApp,
//   //   setup: (firestore) => {
//   //     return firestore().settings({host: 'localhost:8080', ssl: false});
//   //   },
//   // });

//   preloadFunctions({
//     firebaseApp,
//     setup: (functions) => {
//       functions().useFunctionsEmulator('http://localhost:5001');
//     },
//   });

//   return (
//     <SuspenseWithPerf fallback={<Loader />} traceId={'load-application'}>
//       <UserProvider>
//         <Routes />
//       </UserProvider>
//     </SuspenseWithPerf>
//   );
// }

// export default App;

import React from 'react';
import { SuspenseWithPerf } from 'reactfire';
import './App.css';
import Routes from './routes/Routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/default.scss';
import Loader from './components/Loader';
import { UserProvider } from './context/UserContext';

function App() {
  return (
    <SuspenseWithPerf fallback={<Loader />} traceId={'load-application'}>
      <UserProvider>
        <Routes />
      </UserProvider>
    </SuspenseWithPerf>
  );
}

export default App;
