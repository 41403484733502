import './StdPackageCard.scss';


interface Props {
  image?: string;
  price?: number;
  onClickSubscribe?: () => void;
}
const Card = (props: Props) => {

  return (
    <div className="content2">
      <img
        className="content2__image"
        src={
          props.image
            ? props.image
            : 'https://www.pngitem.com/pimgs/m/146-1468479_my-profile-icon-blank-profile-picture-circle-hd.png'
        }
        alt="course"
      />
      <div className="content2__info">
        <p className="content2__pricetag">
          Rs {props.price ? props.price : '1500'} <span className="content2__text">Per Month</span>
        </p>
        <button className="content2__btn-view" onClick={props.onClickSubscribe}>Subscribe</button>
        <button className="content2__btn-stdcodes">Apply Access codes</button>
      </div>
      <div className="flex-container">
        <div className="content2__icon-1  flex-child">
          <svg
            width="14"
            height="16"
            viewBox="0 0 14 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.2629 6.70856L2.26256 0.205254C1.36879 -0.322886 0 0.189629 0 1.49592V14.4994C0 15.6713 1.27191 16.3776 2.26256 15.7901L13.2629 9.28988C14.2441 8.71174 14.2473 7.2867 13.2629 6.70856Z"
              fill="#A4A4A4"
            />
          </svg>
          <p className="svg__text-1"> 40 hours of on demand video</p>
        </div>
        <div className="content2__icon-2 flex-child">
          <svg
            width="14"
            height="19"
            viewBox="0 0 14 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.16667 4.95833V0H0.875C0.390104 0 0 0.390104 0 0.875V17.7917C0 18.2766 0.390104 18.6667 0.875 18.6667H13.125C13.6099 18.6667 14 18.2766 14 17.7917V5.83333H9.04167C8.56042 5.83333 8.16667 5.43958 8.16667 4.95833ZM10.5 13.5625C10.5 13.8031 10.3031 14 10.0625 14H3.9375C3.69687 14 3.5 13.8031 3.5 13.5625V13.2708C3.5 13.0302 3.69687 12.8333 3.9375 12.8333H10.0625C10.3031 12.8333 10.5 13.0302 10.5 13.2708V13.5625ZM10.5 11.2292C10.5 11.4698 10.3031 11.6667 10.0625 11.6667H3.9375C3.69687 11.6667 3.5 11.4698 3.5 11.2292V10.9375C3.5 10.6969 3.69687 10.5 3.9375 10.5H10.0625C10.3031 10.5 10.5 10.6969 10.5 10.9375V11.2292ZM10.5 8.60417V8.89583C10.5 9.13646 10.3031 9.33333 10.0625 9.33333H3.9375C3.69687 9.33333 3.5 9.13646 3.5 8.89583V8.60417C3.5 8.36354 3.69687 8.16667 3.9375 8.16667H10.0625C10.3031 8.16667 10.5 8.36354 10.5 8.60417ZM14 4.44427V4.66667H9.33333V0H9.55573C9.78906 0 10.0115 0.0911458 10.1755 0.255208L13.7448 3.82812C13.9089 3.99219 14 4.21458 14 4.44427Z"
              fill="#A4A4A4"
            />
          </svg>

          <p className="svg__text-2"> 25 Model Papers</p>
        </div>
      </div>
    </div>
  );
};

export default Card;
