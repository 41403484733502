import React, { Fragment, useState } from 'react';
import { FaCheck, FaChevronRight } from 'react-icons/fa';
import { useHistory, useParams } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import Backarrow from '../../../components/Backarrow';
import { CONTENT_SECONDARY_NAV_MAIN_ITEMS } from '../../../constants';
import { useLessons } from '../../../context/LessonsContext';
import { useUnits } from '../../../context/UnitsContext';
import { useAppUser } from '../../../context/UserContext';
import { useAppContent } from '../context/ContentContext';
import './StudentContentSecondaryNav.scss';

const StudentContentSecondaryNav = () => {
  const appContent = useAppContent();

  const { CONTENT } = CONTENT_SECONDARY_NAV_MAIN_ITEMS;
  const navItems = [CONTENT];

  const subNavItems = appContent.contentData;

  const [displaysubNavItems, setDisplaysubNavItems] = useState<boolean>(true);

  const appLessons = useLessons();
  const appUnits = useUnits();
  const history = useHistory();
  const appUser = useAppUser();

  const { subjectId, lessonId, unitId } = useParams() as {
    subjectId: string;
    lessonId: string;
    unitId: string;
  };

  const lastSegment = decodeURI(history.location.pathname.split('/').pop() || '');

  const unitName = appUnits.units.find((u) => u.id === unitId)?.name;
  const lessonName = appLessons.lessons.find((l) => l.id === lessonId)?.name;

  const selectedLesson = appLessons.lessons.find((l) => l.id === lessonId);

  return (
    <aside className="student-content-snav">
      <header className="student-content-snav__header">
        <Backarrow
          onClick={() => {
            if (appUser.firestoreUser?.userRole?.isStudent) {
              if (selectedLesson?.isLessonContainsUnits) {
                history.push(`/my-subjects/subject/${subjectId}/Lessons/${lessonId}/My%20Units`);
              } else {
                history.push(`/my-subjects/subject/${subjectId}/Lessons`);
              }
            } else {
              history.push(appContent.previousPathToPreview);
            }
          }}
        />
        <h6>{unitName || lessonName}</h6>
      </header>
      <ul className="student-content-snav__items">
        {navItems.map((item, idx) => (
          <Fragment key={idx}>
            <li
              className={`student-content-snav__item  ${
                lastSegment === item && 'student-content-snav__item--active'
              } ${
                item === 'Content' &&
                lastSegment === 'Create' &&
                'student-content-snav__item--active'
              } ${
                history.location.pathname.includes(item) && 'student-content-snav__item--active'
              } `}
              onClick={() => {}}
              key={idx}
            >
              <div className="student-content-snav__text-content">
                {item}
                <FaChevronRight
                  size={15}
                  style={{ fontWeight: '100' }}
                  className={`student-content-snav__text-content__icon ${
                    displaysubNavItems && 'student-content-snav__text-content__icon--reveal'
                  }`}
                  onClick={() => {
                    setDisplaysubNavItems(!displaysubNavItems);
                  }}
                />
              </div>
            </li>

            <ul
              className={`student-content-snav__sublist  ${
                displaysubNavItems && 'student-content-snav__sublist--show'
              }`}
            >
              {subNavItems?.map((val, index) => {
                return (
                  <li
                    className={`student-content-snav__sublist-item  ${
                      val.droppableId === appContent.mainDroppableId
                        ? 'student-content-snav__sublist-item--active'
                        : ''
                    }`}
                    key={index}
                    onClick={() => {
                      if (!appContent.chapterMarkedAsReadLoading) {
                        appContent.setMainDroppableId(val.droppableId);
                      }
                      console.log('APPPPP ', appContent.loading);
                    }}
                  >
                    {appContent.chapterMarkedAsReadLoading === val.droppableId ? (
                      <div className="mr-1" style={{ lineHeight: 1 }}>
                        <ClipLoader speedMultiplier={0.6} size={16} color="#20D167" />
                      </div>
                    ) : (
                      <FaCheck
                        color="#20D167"
                        size={11}
                        className={`mr-2 d-none ${
                          val.chapterReadCheckUids?.includes(appUser.firestoreUser?.uid || '') &&
                          'd-block'
                        }`}
                      />
                    )}

                    <span className={`student-content-snav__sublist-sub-item `}>
                      {index + 1}
                      {'. ' + val.contentTitle.value}
                    </span>
                  </li>
                );
              })}
            </ul>
          </Fragment>
        ))}
      </ul>
    </aside>
  );
};

export default StudentContentSecondaryNav;
