import React, { FC } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import ContentMainPreviewHeader from '../../components/ContentMainPreviewHeader';
import ChapterPreviewHeader from '../../components/ChapterPreviewHeader';
import RoutingPaths from '../../routes/RoutingPaths';
import AddContentHeader from './AddContentHeader';
import './ContentHeader.scss';
import ContentListHeader from './ContentListHeader';
import UpdateContentHeader from './UpdateContentHeader';

const ContentHeader: FC = (props) => {
  const params = useParams() as { subjectId: string; lessonId: string; unitId: string };

  const PATHS = new RoutingPaths(params.subjectId, params.lessonId, params.unitId);

  return (
    <Switch>
      <Route
        path={[
          '/my-subjects/subject/:subjectId/lesson/:lessonId/unit/:unitId/Content/Preview/:id',
          '/my-subjects/subject/:subjectId/lesson/:lessonId/Content/Preview/:id',
        ]}
      >
        <ChapterPreviewHeader />
      </Route>
      <Route
        path={[
          '/my-subjects/subject/:subjectId/lesson/:lessonId/unit/:unitId/Content/Update/:droppableId',
          '/my-subjects/subject/:subjectId/lesson/:lessonId/Content/Update/:droppableId',
        ]}
      >
        <UpdateContentHeader
          subjectId={params.subjectId}
          lessonId={params.lessonId}
          unitId={params.unitId}
        />
      </Route>
      <Route
        path={[
          '/my-subjects/subject/:subjectId/lesson/:lessonId/unit/:unitId/Content/Create',
          '/my-subjects/subject/:subjectId/lesson/:lessonId/Content/Create',
        ]}
      >
        <AddContentHeader
          subjectId={params.subjectId}
          lessonId={params.lessonId}
          unitId={params.unitId}
        />
      </Route>

      <Route
        path={[
          '/my-subjects/subject/:subjectId/lesson/:lessonId/unit/:unitId/Content/MainPreview',
          '/my-subjects/subject/:subjectId/lesson/:lessonId/Content/MainPreview',
        ]}
      >
        <ContentMainPreviewHeader />
      </Route>

      <Route path={`${PATHS.baseURL}/Examinations`}>
        <div className="mt-5 py-1"></div>
      </Route>

      <Route path={PATHS.baseURL}>
        <ContentListHeader
          subId={params.subjectId}
          lessId={params.lessonId}
          unitId={params.unitId}
        />
      </Route>
    </Switch>
  );
};

export default ContentHeader;
