import React, {FC} from 'react';
import {Draggable} from 'react-beautiful-dnd';
import {FaEllipsisV} from 'react-icons/fa';
import './DropablePaper.scss';
import {Row} from 'react-bootstrap';
import {ImBin, IoArrowUndoSharp} from 'react-icons/all';
import {PaperTypes} from '../enums/paperTypes';

interface Props {
  task: any;
  index: number;
  isShowAction?: boolean;
  replacePaper?: (id: string, type: PaperTypes) => void;
  removePaper?: (id: string) => void;
}

const getPaperType = (type: string) => {
  switch (type) {
    case PaperTypes.MCQ:
      return 'MCQ';
    case PaperTypes.TRUEFALSE:
      return 'T/F';
    case PaperTypes.STRUCTURED:
      return 'STR';
    default:
      return 'ESY';
  }
};

const DropablePaper: FC<Props> = (props) => {
  return (
    <Draggable draggableId={props.task?.id} index={props.index}>
      {(provided) => (
        <div
          className="subject-section"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Row className="justify-content-between w-100 h-100 m-0 px-2 align-items-center paper-container">
            <div className="type-container d-flex align-items-center">
              <span>{getPaperType(props.task?.paperType)}</span>
            </div>
            <div
              className={[
                'title-container d-flex align-items-center',
                props.isShowAction ? 'right-line' : '',
              ].join(' ')}
            >
              <span>
                {props.task?.paperTitle.length > 25
                  ? `${props.task?.paperTitle.substring(0, 50)}…`
                  : props.task?.paperTitle}
              </span>
            </div>

            <div className="action-container">
              {props.isShowAction && (
                <div className="d-inline-block">
                  <div
                    className="undo-arrow"
                    onClick={() => {
                      props.replacePaper && props.replacePaper(props.task.id, props.task.paperType);
                    }}
                  >
                    <IoArrowUndoSharp size={20} />
                  </div>
                  <div
                    className="card-bin"
                    onClick={() => {
                      props.removePaper && props.removePaper(props.task.id);
                    }}
                  >
                    <ImBin size={20} />
                  </div>
                </div>
              )}
              <FaEllipsisV color="#5C5C5C" />
            </div>
          </Row>
        </div>
      )}
    </Draggable>
  );
};

export default DropablePaper;
