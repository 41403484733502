import { FC, useState } from 'react';
import { FaWindowClose } from 'react-icons/fa';
import Modal from 'react-modal';
import DropDown from './DropDown';
import LoadingIndicator from './LoadingIndicator';
import TextInput from './TextInput';
import { DISCOUNT_TYPES, GRADES, YEARS } from '../constants';
import { validateString, validateStringSpaces } from '../validation/Validation';
import './CreateCommonAccessCodeModal.scss';
import DatePickerComp from './DatePickerComp';

interface Props {
  closeHandler: (resetFileState: () => void) => void;
  show: boolean;
  grade: string;
  year: string;
  sub: string;
  package: string;
}

const CreateBulkAccessCodeModal: FC<Props> = (props) => {
  interface State {
    loading: boolean;
    accessCode: {
      value: string;
      error: string;
      validations: Function[];
      label: string;
      max: number;
    };
    activatedDate: Date;
    expireDate: Date;
    discountedAmount: number;
    discountType: string;
    noOfAccessCodes: number;
    grade: number;
    year: number;
    subject: string;
    package: string;
  }

  const initialState: State = {
    loading: false,
    discountedAmount: 0,
    discountType: '',
    noOfAccessCodes: 0,
    grade: 0,
    year: 0,
    subject: '',
    package: '',
    activatedDate: new Date(),
    expireDate: new Date(),
    accessCode: {
      value: '',
      error: '',
      validations: [validateString, validateStringSpaces],
      label: 'Access Code',
      max: 20,
    },
  };

  const [state, setState] = useState(initialState);
  //   const [active, setActive] = useState(false);

  const resetModalState = () => {
    setState(initialState);
  };

  const updateSubjectHandler = async () => {};

  //   const choiceHandler = async () => {
  //     if (active == false) {
  //       setActive(true);
  //     } else {
  //       setActive(false);
  //     }
  //   };

  return (
    <Modal
      isOpen={props.show}
      contentLabel="Example Modal"
      className="create-common-access-code-modal"
      overlayClassName="r-overlay"
      ariaHideApp={false}
      closeTimeoutMS={500}
    >
      <div className="create-common-access-code-modal__header">
        {'Create Bulk Acess Code'}
        <FaWindowClose
          color="#FF4C6C"
          size={20}
          style={!state.loading ? { cursor: 'pointer' } : { cursor: 'default' }}
          onClick={() => {
            if (!state.loading) {
              props.closeHandler(resetModalState);
            }
          }}
        />
      </div>
      {state.loading && <LoadingIndicator />}
      <div className="create-common-access-code-modal__main">
        <div className="create-common-access-code-modal-inputs">
          <div style={{ marginTop: 15 }}>
            <DatePickerComp
              name="Activated Date"
              className="account-margin-dob"
              stateName="dob"
              state={state}
              setState={setState}
              error=""
              stateValue={state.activatedDate}
            />
          </div>

          <div style={{ marginTop: 25 }}>
            <DatePickerComp
              name="Expire Date"
              className="account-margin-dob"
              stateName="dob"
              state={state}
              setState={setState}
              error=""
              stateValue={state.expireDate}
            />
          </div>

          <div className="d-flex code-discount" style={{ marginTop: 13 }}>
            <div>
              <TextInput
                stateName="discountedAmount"
                state={state}
                setState={setState}
                placeHolder="Discounted Amount"
                stateValue={''}
                error=""
              />
            </div>
            <div>
              <DropDown
                name="Discount Type"
                noValueText="Select discount type"
                stateName="discountType"
                state={state}
                setState={setState}
                optionsArray={DISCOUNT_TYPES}
                style={{ marginTop: 8 }}
                error=""
                stateValue={{
                  displayValue: '',
                  id: '',
                }}
              />
            </div>
          </div>

          <TextInput
            style={{ marginTop: 10 }}
            stateName="noOfAccessCodes"
            state={state}
            setState={setState}
            placeHolder="Number of Access Codes"
            stateValue={''}
            error=""
          />

          <div className="d-flex c-details" style={{ marginTop: 15 }}>
            <div>
              <DropDown
                name="Grade"
                noValueText="Select"
                stateName="grade"
                state={state}
                setState={setState}
                optionsArray={GRADES}
                error=""
                style={{ marginTop: 8 }}
                stateValue={{
                  displayValue: props.grade,
                  id: '',
                }}
                disabled={(props.grade) ? true : false}
              />
            </div>
            <div>
              <DropDown
                name="Year"
                noValueText="Select"
                stateName="year"
                state={state}
                setState={setState}
                optionsArray={YEARS}
                error=""
                style={{ marginTop: 8 }}
                stateValue={{
                  displayValue: props.year,
                  id:''
                }}
                disabled={(props.year) ? true : false}
              />
            </div>
            <div>
              <DropDown
                name="Sub"
                noValueText="Select"
                stateName="sub"
                state={state}
                setState={setState}
                optionsArray={DISCOUNT_TYPES}
                error=""
                style={{ marginTop: 8 }}
                stateValue={{
                  displayValue: props.sub,
                  id: '',
                }}
                disabled={(props.sub) ? true : false}
              />
            </div>
          </div>

          <DropDown
            name="Package"
            noValueText="Select the package"
            stateName="package"
            state={state}
            setState={setState}
            optionsArray={DISCOUNT_TYPES}
            error=""
            style={{ marginTop: 15 }}
            stateValue={{
              displayValue: props.package,
              id: '',
            }}
            disabled={(props.package) ? true : false}
          />
        </div>
      </div>
      <button
        className={`create-common-access-code-modal__btn ${
          state.loading ? 'btn-disable-add-sub' : ''
        }`}
        onClick={() => {
          if (!state.loading) {
            updateSubjectHandler();
          }
        }}
        style={{ width: '100%' }}
      >
        {'Generate Access Codes'}
      </button>
    </Modal>
  );
};

export default CreateBulkAccessCodeModal;
